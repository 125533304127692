import React from "react";
import ClientInfoAndMedicalHistory from "./clientInfoAndMedicalHistory/ClientInfoAndMedical";
import ClientNomineeDetails from "./nomineeDetails/ClientNomineeDetails";
import ClientDocumentFamilyAndBankDetails from "./documenFamilyAndBankDetails/ClientDocumentFamilyAndBankDetails";

const ClientProfile = ({ fetching }) => {
  return (
    <div className="flex flex-col gap-[16px]">
      <ClientInfoAndMedicalHistory fetching={fetching} />

      <ClientNomineeDetails fetching={fetching} />

      <ClientDocumentFamilyAndBankDetails fetching={fetching} />
    </div>
  );
};

export default ClientProfile;
