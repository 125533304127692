export const loginData = {
    header: "Welcome to A K Investment",
    subHeader: "Login to your Account",
    buttonText: "Login",
    footer: "Not signed up yet? ",
    subFooter: "Create an account",
    email: true,
    password: true,
    confirmPassword: false,
    forgotPassword: true,
    footerRoute: "/signup",
};

export const CreateAccountData = {
    header: "Sign Up",
    subHeader: "Sign up to start the communications with your mate",
    buttonText: "Continue",
    footer: "Already have an account?",
    subFooter: "Sign In",
    email: true,
    password: true,
    confirmPassword: true,
    forgotPassword: false,
    footerRoute: "/",
};

export const ForgotData = {
    header: "Forgot Your Password",
    subHeader: "We’ll send you a link to reset your password.",
    buttonText: "Send Reset Instructions",
    footer: "",
    subFooter: "Back",
    email: true,
    password: false,
    confirmPassword: false,
    forgotPassword: false,
    footerRoute: "/",
};

export const SetNewPasswordData = {
    header: "Create a New Password",
    subHeader: "Enter a password unique to this account.",
    buttonText: "Create Password",
    footer: "",
    subFooter: "",
    email: false,
    password: true,
    confirmPassword: true,
    forgotPassword: false,
    footerRoute: "/",
};
