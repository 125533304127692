import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import { uploadDocOnS3 } from "../aws/commonFunc";
const isValidURL = /^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i;
const cryptoSecret = process.env.REACT_APP_CRYPTOSECRET;

export async function delay(milliseconds) {
  await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const disableFutureDates = (current) => {
  return current && current > dayjs().endOf('day'); // Disable future dates
};

export const encryptSecretHex = (value) => {
  return CryptoJS.AES.encrypt(value, cryptoSecret).toString();
}

export const decryptSecretHex = (value) => {
  var bytes = CryptoJS.AES.decrypt(value, cryptoSecret);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const uploadDoc = async (payload, type) => {
  console.log(payload, type, 'payload, type');


  if (type === 'client' && payload?.length) {
    try {
      const uploadResults = await Promise.all(
        payload.map(async (record) => {
          if (!record.document || record.document.length === 0) {
            return {
              ...record,
              type: type,
              illness: record.illness,
              medicineName: record.medicineName,
              document: ''
            };
          }
          if (isValidURL?.test(record.document)) {
            return {
              ...record,
              type: type,
              illness: record.illness,
              medicineName: record.medicineName,
              document: record.document
            };
          }

          // Otherwise, upload each document
          const uploadDocs = await Promise.all(
            record.document.map(doc => uploadDocOnS3(doc.originFileObj))
          );

          return {
            ...record,
            type: type,
            illness: record.illness,
            medicineName: record.medicineName,
            document: uploadDocs[0] || ''
          };
        })
      );
      return uploadResults;
    } catch (error) {
      console.error("Error in client upload:", error);
      return { error: true, message: "Failed to upload client documents", details: error };
    }
  }

  else if (type === 'nominee' && payload?.length) {
    try {
      const nomineeResults = await Promise.all(
        payload.map(async (nominee) => {
          const medicalHistoryWithDocs = await Promise.all(
            nominee.medicalHistory.map(async (historyRecord) => {
              if (!historyRecord.document || historyRecord.document.length === 0) {
                return {
                  type: type,
                  illness: historyRecord.illness,
                  medicineName: historyRecord.medicineName,
                  document: ''
                };
              }

              if (isValidURL?.test(historyRecord.document)) {
                return {
                  type: type,
                  illness: historyRecord.illness,
                  medicineName: historyRecord.medicineName,
                  document: historyRecord.document
                };
              }


              const uploadDocs = await Promise.all(
                historyRecord.document.map((doc) => uploadDocOnS3(doc.originFileObj))
              );
              return {
                type: type,
                illness: historyRecord.illness,
                medicineName: historyRecord.medicineName,
                document: uploadDocs[0] || ''
              };
            })
          );

          return {
            name: nominee.name,
            dob: nominee.dob,
            relation: nominee.relation,
            medicalHistory: medicalHistoryWithDocs
          };
        })
      );

      console.log(nomineeResults, 'Nominee results with URLs');
      return nomineeResults;
    } catch (error) {
      console.error("Error in nominee upload:", error);
      return { error: true, message: "Failed to upload nominee documents", details: error };
    }
  }

  else {
    return { error: true, message: "Invalid type or empty payload" };
  }
};
