import React, { useState } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Button, Spin, message, } from "antd";
import { Avatar } from "antd";
import { AntDesignOutlined, DeleteOutlined, PlusOutlined, } from "@ant-design/icons";
import { AvatarEditIcon } from "../../../utils/SVG";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { resetAllClientInfo, resetOtherDetails, updateOtherDetails, } from "../../../store/slices/AddClientInfoSlice";
import { useNavigate } from "react-router-dom";
import { banksInIndia, relationshipOptions } from "../../../utils/stateCityData";
import { disableFutureDates, encryptSecretHex } from "../../../utils/commonFunction";
import moment from "moment";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../utils/errorMessage";
import { genderEnum } from "../../../utils/enums";
import { uploadDocOnS3 } from "../../../aws/commonFunc";
import { createClient } from "../../../service/Collections";

const { Option } = Select;

const OtherDetail = ({ onPrev }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const personalInfo = useSelector((state) => state?.addClientInfoSlice?.personalInfo);
  const nomineeDetails = useSelector((state) => state?.addClientInfoSlice?.nomineeDetails);
  const otherDetailData = useSelector((state) => state?.addClientInfoSlice?.otherDetails);
  const [avatarImage, setAvatarImage] = useState(otherDetailData?.avatarImage ?? null);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    aadhaar: otherDetailData?.aadhaar || "",
    pan: otherDetailData?.pan || "",
    bankAccount: otherDetailData?.bankAccount || "",
    bankName: otherDetailData?.bankName || null,
    ifsc: otherDetailData?.ifsc || "",
    familyMembers: (otherDetailData?.familyMembers || []).map((member) => ({
      ...member,
      dateOfBirth: member.dateOfBirth ? moment(member.dateOfBirth) : null,
    })) ?? [],
  };

  const onFinish = async (values) => {
    setLoading(true);

    let addhardHex = encryptSecretHex(values?.aadhaar);
    let panHex = encryptSecretHex(values?.pan);
    let bankAccountHex = encryptSecretHex(values?.bankAccount);
    let ifscHex = encryptSecretHex(values?.ifsc);

    const finalValues = {
      ...values,
      avatarImage,
      addhardHex,
      panHex,
      bankAccountHex,
      ifscHex,
    };

    dispatch(updateOtherDetails(finalValues));

    let updatedNominess = nomineeDetails?.nominees?.map((el) => ({ ...el, dateOfBirth: el?.dob }))


    let req = {
      // Personal Form Details
      firstName: personalInfo?.firstName,
      middleName: personalInfo?.middleName,
      lastName: personalInfo?.lastName,
      age: personalInfo?.age,
      dateOfBirth: personalInfo?.dob,
      gender: genderEnum[personalInfo?.gender],   // 1 = Male, 2 = Female,
      email: personalInfo?.email,
      phoneNumber: personalInfo?.phone,
      alternatePhoneNumber: personalInfo?.altPhone,
      permanentAddress: personalInfo?.permanentAddress,
      communicationAddress: personalInfo?.useSameAddress ? null : personalInfo?.communicationAddress,
      useSameAddress: personalInfo?.useSameAddress,
      motherName: personalInfo?.motherName,
      fatherName: personalInfo?.fatherName,
      qualification: personalInfo?.qualification,
      businessNature: personalInfo?.natureOfBusiness,
      annualIncome: personalInfo?.annualIncome,
      physicalDetails: {
        weight: personalInfo?.weight,
        height: personalInfo?.height
      },
      medicalHistory: personalInfo?.medicalHistory,
      //Noinee Form Details
      nomineeDetails: updatedNominess,
      //Other Form Details
      profile: avatarImage,
      documents: {
        aadhaarNumber: addhardHex,
        panNumber: panHex,
      },
      bankDetails: {
        accountNumber: bankAccountHex,
        bankName: values?.bankName,
        ifscCode: ifscHex,
      },
      familyDetails: values?.familyMembers,
    };


    let res = await createClient(req);
    if (res.status === 200) {
      message.success(res.message);
      dispatch(resetAllClientInfo());
      setLoading(false);
      navigate("/manage-clients");
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }

  };


  const onPrevBtn = () => {
    const values = form.getFieldsValue();
    const { familyMembers, ...otherValues } = values;
    const formattedFamilyMembers = familyMembers?.map((member) => {
      const {
        name,
        dateOfBirth,
        relation,
        height,
        weight,
        healthStatus,
        medicineName,
        illness,
        description,
      } = member ?? {};
      return {
        name,
        dateOfBirth,
        relation,
        height,
        weight,
        healthStatus,
        medicineName,
        illness,
        description,
      };
    });

    const formData = {
      ...otherValues,
      avatarImage,
      familyMembers: formattedFamilyMembers,
    };

    dispatch(updateOtherDetails(formData));
    onPrev();
  };

  const handleFileChange = async (file) => {
    setAvatarLoading(true);
    await uploadDocOnS3(file).then((location) => {
      setAvatarImage(location)
      setAvatarLoading(false);
    })
      .catch((error) => {
        console.error("Error uploading file:", error.message);
        setAvatarImage(null);
        setAvatarLoading(false);
        toast.error(error?.message || 'Error uploading file')
      });
  };

  const onResetBtn = () => {
    const defaultValues = {
      aadhaar: "",
      pan: "",
      bankAccount: "",
      bankName: null,
      ifsc: "",
      familyMembers: [
        {
          name: "",
          dateOfBirth: null,
          relation: "",
          height: "",
          weight: "",
          healthStatus: "",
          medicineName: "",
          illness: "",
          description: "",
        },
      ],
    };

    form.setFieldsValue(defaultValues);
    dispatch(resetOtherDetails());
  };

  return (
    <div className="bg-white p-4 rounded-[20px]">
      <h2 className="text-2xl font-semibold mb-[16px]">Other Details</h2>
      <Spin spinning={loading} >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <div className="mb-[24px]">
            <div className="mb-[24px] relative w-[fit-content]">
              <Spin spinning={avatarLoading} >
                <Avatar
                  size={100}
                  src={avatarImage}
                  icon={!avatarImage && <AntDesignOutlined />}
                />
                <div
                  className="absolute bottom-[13px] right-[-3px] cursor-pointer"
                  onClick={() => document.getElementById("avatarUpload").click()}
                >
                  <AvatarEditIcon />
                </div>
                <input
                  id="avatarUpload"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => handleFileChange(e.target.files[0])}
                />
              </Spin>
            </div>
            <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
              Documents
            </p>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Aadhaar No."
                  name="aadhaar"
                  rules={[
                    { required: true, message: "Please enter Aadhaar No." },
                    {
                      pattern: /^[0-9]{12}$/,
                      message: "Please enter a valid 12-digit Aadhaar number",
                    },
                  ]}
                >
                  <Input placeholder="Enter Aadhaar No." className="h-[48px]"
                    maxLength={12} // Limit input to 12 characters
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Pan No."
                  name="pan"
                  rules={[
                    { required: true, message: "Please enter Pan No." },
                    {
                      pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                      message:
                        "Please enter a valid PAN number (e.g., ASFRD4765Q)",
                    },
                  ]}
                >
                  <Input placeholder="Enter Pan No." className="h-[48px]" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.List name="familyMembers" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                <div className="mb-[24px]">
                  <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
                    Family Details
                  </p>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={key} className="bg-gray-100 p-[16px] mb-[10px]">
                      {/* {fields.length > 1 && ( */}
                      <div
                        className="flex justify-end cursor-pointer"
                        onClick={() => remove(name)}
                      >
                        <DeleteOutlined style={{ color: "red" }} />
                      </div>
                      {/* )}  */}
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            label="Name"
                            rules={[
                              { required: true, message: "Please enter name" },
                            ]}
                          >
                            <Input
                              placeholder="Enter Name"
                              className="h-12 rounded-md border-gray-300"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "dateOfBirth"]}
                            label="Date of Birth"
                            rules={[
                              { required: true, message: "Please enter DOB" },
                            ]}
                          >
                            <DatePicker
                              format="DD/MM/YYYY"
                              placeholder="DD/MM/YYYY"
                              className="h-12 w-full rounded-md border-gray-300"
                              disabledDate={disableFutureDates}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "relation"]}
                            label="Relation"
                            rules={[
                              {
                                required: true,
                                message: "Please select relation",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Relation"
                              className="h-[48px]"
                            >
                              {relationshipOptions?.map((relation) => (
                                <Option key={relation} value={relation}>
                                  {relation}
                                </Option>
                              ))}
                            </Select>

                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "height"]}
                            label="Height"
                            rules={[
                              { required: true, message: "Please enter height" },
                              {
                                pattern: /^(10|[1-9])('|'[0-9]{1,2})?$/,
                                message: "Please enter a valid height eg. 5'10",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Height"
                              suffix="In"
                              className="h-[48px]"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "weight"]}
                            label="Weight"
                            rules={[
                              { required: true, message: "Please enter weight" },
                              {
                                pattern: /^[0-9,]+$/,
                                message: "Please enter a valid number ",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Weight"
                              suffix="Kg"
                              className="h-[48px]"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "healthStatus"]}
                            label="Health Status"
                            rules={[
                              {
                                required: true,
                                message: "Please select health status",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Health Status"
                              className="h-[48px]"
                            >
                              <Option value="healthy">Healthy</Option>
                              <Option value="minor_issues">Minor Issues</Option>
                              <Option value="major_issues">Major Issues</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "medicineName"]}
                            label="Medicine Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter medicine name",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Medicine Name"
                              className="h-[48px]"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>


                          <Form.Item
                            {...restField}
                            name={[name, "illness"]}
                            label="Medical Illness"
                            rules={[
                              {
                                required: true,
                                message: "Please select medical illness",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter medical illness"
                              className="h-12 rounded-md border-gray-300"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                            label="Description"
                            rules={[
                              {
                                required: true,
                                message: "Please enter description",
                              },
                            ]}
                          >
                            <TextArea
                              maxLength={100}
                              placeholder="Enter Description"
                              style={{
                                height: 120,
                                resize: "none",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
                <Form.Item className="flex justify-end">
                  <Button
                    style={{
                      width: "fit-content",
                      color: "#688540",
                      border: "none",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Family Member
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className="mb-[24px]">
            <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
              Bank Details
            </p>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Bank Account No. (optional)"
                  name="bankAccount"
                  rules={[
                    {
                      required: false,
                      message: "Please enter bank account no.",
                    },
                    {
                      pattern: /^[0-9,]+$/,
                      message: "Please enter a valid number ",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Bank Account No."
                    className="h-[48px]"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Bank Name"
                  name="bankName"
                  rules={[
                    {
                      required: true,
                      message: "Please select bank name",
                    },
                  ]}
                >
                  <Select placeholder="Select Bank" className="h-[48px]">
                    {banksInIndia?.map((bank) => (
                      <Option key={bank} value={bank}>
                        {bank}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="IFSC Code (optional)"
                  name="ifsc"
                  rules={[
                    {
                      required: false,
                      message: "Please enter IFSC code",
                    },
                    // {
                    //   pattern: /^[A-Z]{4}[0-9]{7}$/,
                    //   message: "Please enter a valid IFSC code",
                    // }
                  ]}
                >
                  <Input placeholder="Enter IFSC Code" className="h-[48px]" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="flex justify-between">
            <div className="flex gap-2">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
                onClick={() => onPrevBtn()}
              >
                {"Previous"}
              </button>
              <button
                type="button"
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 w-[129px] h-[48px]"
                onClick={() => onResetBtn()}
              >
                Reset
              </button>

            </div>

            <button type={avatarLoading ? "button" : "submit"} className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
              {(avatarLoading || loading) ? "Loading..." : "Add Client"}
            </button>


          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default OtherDetail;
