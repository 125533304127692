import React from 'react';
import { Form, Input, Button, DatePicker, Select, Upload } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Dragger } = Upload;

const NomineeForm = () => {
  const onFinish = (values) => {
    console.log('Form values: ', values);
  };

  return (
    <Form onFinish={onFinish} layout="vertical">
      <Form.List name="nominees">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} style={{ border: '1px solid #ccc', padding: '20px', marginBottom: '20px' }}>
                <h4>Nominee {key + 1}</h4>
                <Form.Item
                  {...restField}
                  name={[name, 'nomineeName']}
                  label="Nominee Name"
                  rules={[{ required: true, message: 'Please enter the nominee name' }]}
                >
                  <Input placeholder="Nominee Name" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, 'nomineeDOB']}
                  label="Nominee Date of Birth"
                  rules={[{ required: true, message: 'Please select the date of birth' }]}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, 'nomineeRelation']}
                  label="Nominee Relation"
                  rules={[{ required: true, message: 'Please select the relation' }]}
                >
                  <Select placeholder="Select Relation">
                    <Option value="spouse">Spouse</Option>
                    <Option value="child">Child</Option>
                    <Option value="parent">Parent</Option>
                  </Select>
                </Form.Item>

                {/* Inner Field Array for Medical Details */}
                <Form.List name={[name, 'medicalDetails']}>
                  {(medicalFields, { add: addMedical, remove: removeMedical }) => (
                    <>
                      {medicalFields.map(({ key: medicalKey, name: medicalName, ...restMedicalField }) => (
                        <div key={medicalKey} style={{ border: '1px dashed #ccc', padding: '10px', marginBottom: '10px' }}>
                          <h5>Medical Detail {medicalKey + 1}</h5>

                          <Form.Item
                            {...restMedicalField}
                            name={[medicalName, 'illness']}
                            label="Medical Illness"
                            rules={[{ required: true, message: 'Please enter the medical illness' }]}
                          >
                            <Input placeholder="Medical Illness" />
                          </Form.Item>

                          <Form.Item
                            {...restMedicalField}
                            name={[medicalName, 'medicineName']}
                            label="Medicine Name"
                            rules={[{ required: true, message: 'Please enter the medicine name' }]}
                          >
                            <Input placeholder="Medicine Name" />
                          </Form.Item>

                          <Form.Item
                            {...restMedicalField}
                            name={[medicalName, 'document']}
                            label="Upload Document"
                            valuePropName="fileList"
                            getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList}
                            rules={[{ required: true, message: 'Please upload the document' }]}
                          >
                            <Dragger name="files" multiple>
                              <p className="ant-upload-drag-icon">
                                <PlusOutlined />
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => removeMedical(medicalName)} />
                        </div>
                      ))}

                      <Button type="dashed" onClick={() => addMedical()} block icon={<PlusOutlined />}>
                        Add Medical Detail
                      </Button>
                    </>
                  )}
                </Form.List>

                <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: '20px' }} />
              </div>
            ))}

            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Nominee
            </Button>
          </>
        )}
      </Form.List>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NomineeForm;

