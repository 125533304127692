import React, { useState } from "react";
import { Form, Input, Radio, Checkbox, Row, Col } from "antd";
import { createUserRole } from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { toast } from "react-toastify";
import { FormButton } from "../../components/ButtonComp";

const AddUser = ({ onClose, fetchListing }) => {
  const [userType, setUserType] = useState("Staff");
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    let req = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone_number: values.phone,
      role: values.role,
      permission_module: values.modulePermissions || [],
      permission_client: values.clientInfoPermissions || [],
    };

    let res = await createUserRole(req);
    if (res.status === 200) {
      setLoading(false);
      fetchListing();
      toast.info(res.message);
      onClose();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const modulePermissions = [
    "Dashboard",
    "Role & Management",
    "Feedback",
    "Manage Clients",
  ];

  const clientInfoPermissions = ["Add Policy", "Documents", "Edit Profile"];

  const inputFields = [
    {
      label: "First Name",
      name: "firstName",
      placeholder: "Enter first name",
      rules: [{ required: true, message: "Please enter first name" }],
    },
    {
      label: "Last Name",
      name: "lastName",
      placeholder: "Enter last name",
      rules: [{ required: true, message: "Please enter last name" }],
    },
    {
      label: "Phone No.",
      name: "phone",
      placeholder: "Enter Phone No.",
      rules: [
        { required: true, message: "Please enter phone number" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be 10 digits",
        },
      ],
    },
    {
      label: "Email Address",
      name: "email",
      placeholder: "Enter Email",
      rules: [{ required: true, message: "Please enter email address" }],
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="flex flex-col gap-[15px]">
        <p className="text-[32px] font-[700] text-center text-black font-roboto">
          Add User
        </p>
        <div>
          <p className="text-[24px] font-[400] text-center text-black font-roboto">
            Enter the required details of the new user below
          </p>
          <p className="text-[14px] font-[400] text-center text-gray-500 font-roboto">
            We'll send them an email inviting them to create their account.
          </p>
        </div>
      </div>

      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          className="flex justify-center mt-[20px]"
          name="role"
          initialValue={userType}
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Radio.Group onChange={handleUserTypeChange}>
            <Radio value="Staff">Staff</Radio>
            <Radio value="Manager">Manager</Radio>
            <Radio value="Admin">Admin</Radio>
          </Radio.Group>
        </Form.Item>

        <Row gutter={16}>
          {inputFields.map((field, index) => (
            <Col span={12} key={index}>
              <Form.Item
                label={field.label}
                name={field.name}
                rules={field.rules}
              >
                <Input placeholder={field.placeholder} className="h-[48px]" />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <h3 className="text-[18px] font-[700] text-start text-black font-roboto mb-[16px]">
              Permissions in Module
            </h3>
            <Form.Item name="modulePermissions" valuePropName="checked">
              <Checkbox.Group>
                <div className="flex flex-col gap-[12px]">
                  {modulePermissions.map((permission) => (
                    <Checkbox key={permission} value={permission}>
                      {permission}
                    </Checkbox>
                  ))}
                </div>
              </Checkbox.Group>
            </Form.Item>
          </Col>

          {userType !== "Staff" && (
            <Col span={12}>
              <h3 className="text-[18px] font-[700] text-start text-black font-roboto mb-[16px]">
                Permissions in Client Info
              </h3>
              <Form.Item name="clientInfoPermissions" valuePropName="checked">
                <Checkbox.Group>
                  <div className="flex flex-col gap-[12px]">
                    {clientInfoPermissions.map((permission) => (
                      <Checkbox key={permission} value={permission}>
                        {permission}
                      </Checkbox>
                    ))}
                    {userType === "Admin" && (
                      <Checkbox value="Delete Profile">Delete Profile</Checkbox>
                    )}
                  </div>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          )}
        </Row>

        <div className="flex justify-end">
          <FormButton title="Add User" loading={loading} />
        </div>
      </Form>
    </div>
  );
};

export default AddUser;
