import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { TableViewIcon } from "../../utils/SVG";
import ClientFeedbackModal from "./ViewFeedback";
import CustomModal from "../../components/CustomModal";
import { getAllFeedbackList } from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import CustomAvatar from "../../components/CustomAvatar";

let defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const AllFeedback = ({ search, selectedFilter }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const MAX_NOTE_LENGTH = 50;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);

  const truncateNote = (note) => {
    if (note?.length > MAX_NOTE_LENGTH) {
      return `${note.slice(0, MAX_NOTE_LENGTH)}...`;
    }
    return note;
  };

  const handleViewClick = (feedback) => {
    setSelectedFeedback(feedback);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      key: "key",
      render: (text) => <span>{(pagination?.current - 1) * pagination?.pageSize + text}</span>,
    },
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (record) => (
        <div className="flex items-center gap-1">
          <CustomAvatar
            firstName={record?.firstName}
            lastName={record?.lastName}
            profile={record?.profile}
          />
          {record?.firstName + " " + record?.lastName}
        </div>
      )
    },
    {
      title: "Client ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (note) => <span>{truncateNote(note)}</span>,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (rating) => (
        <span>
          {Array.from({ length: 5 }, (_, index) => (
            <span
              key={index}
              className={index < rating ? "text-yellow-500" : "text-gray-300"}
            >
              ★
            </span>
          ))}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex space-x-2">
          <div
            className="cursor-pointer"
            onClick={() => handleViewClick(record)}
          >
            <TableViewIcon />
          </div>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    fetchListing(pagination);
  };

  const mapFeedbackData = (data) => {
    return data.map((item, idx) => (
      {
        key: idx + 1,
        title: item?.title,
        note: item?.description,
        rating: item?.rating,
        status: item?.status,
        clientId: item?.userId,
        date: dayjs(item?.createdAt).format("YYYY-MM-DD"),
        ...item,
      }));
  };

  const fetchListing = async (pagination) => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("perPage", pagination?.pageSize);
    params.append("page", pagination?.current);
    params.append("search", search);
    if (selectedFilter !== null) {
      params.append("rating", selectedFilter);
    }
    let res = await getAllFeedbackList(params);
    if (res?.status === 200) {
      const feedbackList = mapFeedbackData(res?.data?.allFeedbackList);
      setTableData(feedbackList);
      setPagination((prev) => ({
        ...prev,
        total: res?.data?.totalFeedback,
      }));
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPagination({ ...pagination, current: 1 });
    fetchListing({ ...pagination, current: 1 });
  }, [search, selectedFilter]);

  return (
    <div>
      <CustomTable
        columns={columns}
        data={tableData}
        paginationProps={pagination}
        onChange={handleTableChange}
        loading={loading}
      ></CustomTable>
      <CustomModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {selectedFeedback && (
          <ClientFeedbackModal feedbackData={selectedFeedback} />
        )}
      </CustomModal>
    </div>
  );
};

export default AllFeedback;
export { defaultPagination };
