import React, { useEffect, useState } from "react";
import EmployeeInfo from "./EmployeeInfo";
import ClientRating from "./ClientRating";
import CountUp from "react-countup";
import {
  NumberClaimIcon,
  NumberClientIcon,
  NumberPolicyIcon,
  NumberQueryIcon,
} from "../../utils/SVG";
import { getDashboardStats } from "../../service/Collections";
const initialCardData = [
  {
    name: "Total Number of Policy",
    count: 0,
    color: "#FFDEE6",
    icon: <NumberPolicyIcon width="11" height="14" />,
    BigIcon: <NumberPolicyIcon />,
  },
  {
    name: "Total Number of Client",
    count: 0,
    color: "#DFFFDE",
    icon: <NumberClientIcon width="14" height="14" />,
    BigIcon: <NumberClientIcon />,
  },
  {
    name: "Total Number of Claim",
    count: 0,
    color: "#E1E7FF",
    icon: <NumberClaimIcon width="14" height="14" />,
    BigIcon: <NumberClaimIcon />,
  },
  {
    name: "Total Number of Query",
    count: 0,
    color: "#F6DCFF",
    icon: <NumberQueryIcon width="14" height="14" />,
    BigIcon: <NumberQueryIcon />,
  },
];

const Dashboard = () => {
  const [DashboardData, setDashboardData] = useState(initialCardData);

  const fetchDashboardStats = async () => {
    let res = await getDashboardStats();
    if (res?.status === 200) {
      const updatedData = [
        {
          ...initialCardData[0],
          count: res?.data?.policySummary?.totalActive || 0,
        },
        {
          ...initialCardData[1],
          count: res?.data?.totalClients || 0,
        },
        {
          ...initialCardData[2],
          count: res?.data?.policySummary?.totalRaisedClaim || 0,
        },
        {
          ...initialCardData[3],
          count:
            (res?.data?.userQuerySummary?.totalPending || 0) +
            (res?.data?.userQuerySummary?.totalResolved || 0),
        },
      ];

      setDashboardData(updatedData);
    } else {
      setDashboardData(initialCardData);
    }
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  return (
    <div className="flex gap-[16px] flex-col">
      {/* <div className="flex flex-col lg:flex-row gap-[12px]">
        <div className="w-full">
          <NumberOfPolicy />
        </div>
        <div className="w-[100%] flex flex-col gap-[10px]">
          <div className="flex gap-[12px]">
            <Revenu />
            <NumberOfClient />
          </div>
          <div className="flex gap-[12px]">
            <NumberOfClaim />
            <MonthlyGrow />
          </div>
        </div>
      </div> */}

      <div className="flex gap-[12px]">
        {DashboardData && DashboardData?.map((el) => <Card data={el} />)}
      </div>

      <div className="flex flex-col lg:flex-row gap-[16px] justify-between">
        <EmployeeInfo />
        <ClientRating />
      </div>
    </div>
  );
};

export default Dashboard;

const Card = ({ data }) => {
  return (
    <div className="relative w-full h-[152px] flex flex-col justify-between gap-0 rounded-[20px] bg-white border border-[#2F2F2F33] px-[25px] py-[21px]">
      <div className="flex gap-[5px] items-center ">
        <i
          className="w-[30px] h-[30px] flex justify-center items-center rounded-full"
          style={{ backgroundColor: data?.color }}
        >
          {" "}
          {data?.icon}{" "}
        </i>
        <p class="font-roboto text-[16px] font-semibold leading-[16.8px] text-black z-[1]">
          {" "}
          {data?.name}{" "}
        </p>
      </div>

      <h1 class="font-roboto text-[40px] font-semibold leading-[48px]">
        <CountUp start={0} end={data?.count} duration={2.5} separator="," />+
      </h1>

      <div
        className="absolute top-0 right-0 bottom-0 flex justify-center items-center w-[120px] rounded-tl-[60px] rounded-bl-[60px] opacity-30 z-0 p-6"
        style={{ backgroundColor: data?.color }}
      >
        {data?.BigIcon}
      </div>
    </div>
  );
};
