import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { TableDeleteIcon, TableEditIcon } from "../../utils/SVG";
import { PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import Search from "antd/es/transfer/search";
import CustomModal from "../../components/CustomModal";
import DeleteModal from "../../components/CustomDeleteModal";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils/errorMessage";
import { listingUserRole } from "../../service/Collections";
import CustomAvatar from "../../components/CustomAvatar";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { deleteRole } from "../../service/Collections";

let defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const RoleAndManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [roleValue, setRoleValue] = useState("");

  const handleAddRoleClick = () => {
    setIsModalVisible(true);
  };

  const handleDeleteClick = (record) => {
    setItemToDelete(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    console.log("Deleting item:", itemToDelete);
    setDeleteModalVisible(false);
    const res = await deleteRole(itemToDelete?._id);
    if (res?.status === 200) {
      fetchListing({ ...pagination, search: searchValue, role: roleValue });
      toast.info(res?.message);
      setDeleteModalVisible(false);
    } else {
      toast.error(res?.message);
      console.log(res?.message);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleEditClick = (record) => {
    setEditingUser(record);
    setIsEditModalVisible(true);
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      key: "key",
      render: (text) => (
        <span>{(pagination?.current - 1) * pagination?.pageSize + text}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div className="flex items-center gap-1">
          <CustomAvatar
            firstName={record?.firstName}
            lastName={record?.lastName}
          />
          {record?.firstName + " " + record?.lastName}
        </div>
      ),
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD") : "-";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex space-x-2">
          {record?.role?.includes('Super-Admin') ? null :
            <>
              <div
                className="cursor-pointer"
                onClick={() => handleEditClick(record)}
              >
                <TableEditIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => handleDeleteClick(record)}
              >
                <TableDeleteIcon />
              </div>
            </>
          }
        </div>
      ),
    },
  ];

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setPagination(defaultPagination);
    }, 1000),
    [roleValue]
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleRoleChange = (value) => {
    setPagination(defaultPagination);
    if (value === "All") {
      setRoleValue("");
    } else {
      setRoleValue(value);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const fetchListing = async () => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let params = new URLSearchParams();
    params.append("perPage", pageSize);
    params.append("page", current);

    if (searchValue) params.append("search", searchValue);
    if (roleValue) params.append("role", roleValue);

    let res = await listingUserRole(params);
    if (res?.status === 200) {
      let addingSno = res?.data?.admins?.map((u, idx) => ({
        key: idx + 1,
        ...u,
      }));
      setTableData(addingSno);
      setTotalPage(res?.data?.adminCount);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListing();
  }, [pagination, searchValue, roleValue]);

  return (
    <div>
      <CustomTable
        columns={columns}
        data={tableData}
        heading="Role & Management"
        paginationProps={{ ...pagination, total: totalPage }}
        onChange={handleTableChange}
        loading={loading}
      >
        <Search
          placeholder="Search..."
          onChange={handleSearch}
          size="large"
          prefixCls={"custom-search"}
        />
        {/* <Select defaultValue="Sort By" className="h-[48px] w-[100px]">
          <Option value="name">Name</Option>
          <Option value="role">Role</Option>
        </Select> */}
        <Select
          defaultValue={"All"}
          value={roleValue || undefined}
          onChange={handleRoleChange}
          className="h-[48px] w-[100px]"
          placeholder="Filter"
        >
          <Option value="All">All</Option>
          <Option value="Admin">Admin</Option>
          <Option value="Manager">Manager</Option>
          <Option value="Staff">Staff</Option>
        </Select>
        <button
          onClick={handleAddRoleClick}
          className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]"
        >
          <span>
            <PlusOutlined /> Add Role
          </span>
        </button>
      </CustomTable>

      <CustomModal
        width={883}
        height={500}
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <AddUser
          onClose={() => setIsModalVisible(false)}
          fetchListing={fetchListing}
        />
      </CustomModal>

      <CustomModal
        width={883}
        height={500}
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      >
        <EditUser
          user={editingUser}
          onClose={() => setIsEditModalVisible(false)}
          fetchListing={fetchListing}
          setSearchValue={setSearchValue}
          setRoleValue={setRoleValue}
        />
      </CustomModal>

      <DeleteModal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default RoleAndManagement;
