import Dashboard from "../modules/dashboard";
import FeedBack from "../modules/feedback";
import History from "../modules/history";
import ManageClients from "../modules/manageClients/ManageClients";
import AddClient from "../modules/manageClients/addClient";
import ViewClientDetail from "../modules/manageClients/viewClientDetail";
import ViewPolicy from "../modules/manageClients/viewClientDetail/Policy/ViewPolicy";
import RoleAndManagement from "../modules/roleAndManagement/RoleAndManagement";
import Setting from "../modules/setting";
import NomineeForm from "../modules/setting/Test";
export const PrivatePaths = [
    {
        path: "dashboard",
        component: <Dashboard />,
        moduleName: "Dashboard",
    },
    {
        path: "role-management",
        component: <RoleAndManagement />,
        moduleName: "Role & Management",
    },
    {
        path: "feedback",
        component: <FeedBack />,
        moduleName: "Feedback",
    },
    {
        path: "manage-clients",
        component: <ManageClients />,
        moduleName: "Manage Clients",
    },
    {
        path: "manage-clients/add-client",
        component: <AddClient />,
        moduleName: "Manage Clients",
    },
    {
        path: "manage-clients/edit",
        component: <ViewClientDetail />,
        moduleName: "Manage Clients",
    },
    {
        path: "manage-clients/edit/edit-policy",
        component: <ViewPolicy />,
        moduleName: "Manage Clients",
    },
    {
        path: "/history",
        component: <History />,
        moduleName: "History",
    },
    {
        path: "/setting",
        component: <Setting />,
        moduleName: "Setting",
    },
    {
        path: "/test",
        component: <NomineeForm />,
        moduleName: "Nominee Form",
    },
];
