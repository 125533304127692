import React from "react";
import { Modal } from "antd";
import { ModalCloseIcon } from "../utils/SVG";

const CustomModal = ({ isVisible, onClose, children, width = 600 , height}) => {
  return (
    <Modal
    prefixCls="custom-modal"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
      width={width}
      closable={false}
      height={height}
      bodyStyle={{ padding: 0, borderRadius: "8px", overflow: "hidden" }}
      title={
        <div className="relative">
          <button
            onClick={onClose}
            className="absolute top-[-50px] right-[-55px] p-2 text-gray-600 hover:text-gray-900"
          >
            <ModalCloseIcon />
          </button>
        </div>
      }
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
