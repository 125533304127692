import CreatePaassword from "../modules/auth/CreatePaassword";
import ForgetPassword from "../modules/auth/ForgetPassword";
import Login from "../modules/auth/Login";

export const PublicPaths = [
    {
        path: "create-password",
        component: <CreatePaassword />,
    },
    {
        path: "/",
        component: <Login />,
    },
    {
        path: "forgot-password",
        component: <ForgetPassword />
    },
];