import { createSlice } from "@reduxjs/toolkit";

export const selectedClientSlice = createSlice({
    name: "selectedClientSlice",
    initialState: {
        client: null,
    },
    reducers: {
        updateSelectedClient: (state, actions) => {
            if (actions?.payload) {
                state.client = actions.payload;
            }
        },
    },
});

export const { updateSelectedClient } = selectedClientSlice.actions;
export default selectedClientSlice.reducer;
