import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Avatar,
  Spin,
} from "antd";
import { editClientPersonalInfo } from "../../../../../service/Collections";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../../utils/errorMessage";
import {
  banksInIndia,
  natureOfBusiness,
  qualifications,
  stateCityData,
  states,
} from "../../../../../utils/stateCityData";
import { UserOutlined } from "@ant-design/icons";
import { AvatarEditIcon } from "../../../../../utils/SVG";
import {
  decryptSecretHex,
  encryptSecretHex,
} from "../../../../../utils/commonFunction";
import { useSelector } from "react-redux";
import { uploadDocOnS3 } from "../../../../../aws/commonFunc";

const { Option } = Select;

const EditClientPersonalInfo = ({ onChange, onClose, fetching }) => {
  const clientData = useSelector((state) => state?.selectedClient?.client?.[0]);
  const [avatarImage, setAvatarImage] = useState(null);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [useSameAddress, setUseSameAddress] = useState(
    clientData?.useSameAddress
  );
  const user = useSelector((state) => state?.loginSlice?.data);

  const initialValues = {
    firstName: clientData?.firstName,
    middleName: clientData?.middleName,
    lastName: clientData?.lastName,
    weight: clientData?.physicalDetails?.weight,
    height: clientData?.physicalDetails?.height,
    dateOfBirth: clientData?.dateOfBirth
      ? dayjs(clientData?.dateOfBirth)
      : null,
    age: clientData?.age,
    gender: clientData?.gender === 1 ? "male" : "female",
    email: clientData?.email,
    phoneNumber: clientData?.phoneNumber,
    alternatePhoneNumber: clientData?.alternatePhoneNumber,
    motherName: clientData?.motherName,
    fatherName: clientData?.fatherName,
    annualIncome: clientData?.annualIncome,
    qualification: clientData?.qualification,
    businessNature: clientData?.businessNature,
    streetAddress: clientData?.permanentAddress?.streetAddress,
    apartment: clientData?.permanentAddress?.apartment,
    landmark: clientData?.permanentAddress?.landmark,
    state: clientData?.permanentAddress?.state,
    city: clientData?.permanentAddress?.city,
    zip: clientData?.permanentAddress?.zip,
    useSameAddress: clientData?.useSameAddress,
    communicationStreetAddress: clientData?.useSameAddress
      ? clientData?.permanentAddress?.streetAddress
      : clientData?.communicationAddress?.streetAddress,
    communicationApartment: clientData?.useSameAddress
      ? clientData?.permanentAddress?.apartment
      : clientData?.communicationAddress?.apartment,
    communicationLandmark: clientData?.useSameAddress
      ? clientData?.permanentAddress?.landmark
      : clientData?.communicationAddress?.landmark,
    communicationState: clientData?.useSameAddress
      ? clientData?.permanentAddress?.state
      : clientData?.communicationAddress?.state,
    communicationCity: clientData?.useSameAddress
      ? clientData?.permanentAddress?.city
      : clientData?.communicationAddress?.city,
    communicationZip: clientData?.useSameAddress
      ? clientData?.permanentAddress?.zip
      : clientData?.communicationAddress?.zip,

    bankName: clientData?.bankDetails?.bankName,
    bankAccount: decryptSecretHex(clientData?.bankDetails?.accountNumber),
    ifsc: decryptSecretHex(clientData?.bankDetails?.ifscCode),
    pan: decryptSecretHex(clientData?.documents?.panNumber),
    aadhaar: decryptSecretHex(clientData?.documents?.aadhaarNumber),
  };

  const onFinish = async (values) => {
    const payload = {
      ...values,
      permanentAddress: {
        streetAddress: values?.streetAddress,
        aptUnitSuite: values?.aptUnitSuite,
        landmark: values?.landmark,
        state: values?.state,
        city: values?.city,
        zip: values?.zip,
      },
      communicationAddress: values?.useSameAddress
        ? {
          streetAddress: values?.streetAddress,
          aptUnitSuite: values?.aptUnitSuite,
          landmark: values?.landmark,
          state: values?.state,
          city: values?.city,
          zip: values?.zip,
        }
        : {
          streetAddress: values?.communicationStreetAddress,
          aptUnitSuite: values?.communicationApartment,
          landmark: values?.communicationLandmark,
          state: values?.communicationState,
          city: values?.communicationCity,
          zip: values?.communicationZip,
        },
      gender: values.gender === "male" ? 1 : 2,
      physicalDetails: {
        weight: Number(values?.weight),
        height: values?.height,
      },
      documents: {
        panNumber: encryptSecretHex(values?.pan),
        aadhaarNumber: encryptSecretHex(values?.aadhaar),
      },
      bankDetails: {
        bankName: values?.bankName,
        accountNumber: encryptSecretHex(values?.bankAccount),
        ifscCode: encryptSecretHex(values?.ifsc),
      },
      profile: avatarImage ? avatarImage : clientData?.profile,
    };

    delete payload.streetAddress;
    delete payload.aptUnitSuite;
    delete payload.landmark;
    delete payload.state;
    delete payload.city;
    delete payload.zip;
    delete payload.communicationStreetAddress;
    delete payload.communicationApartment;
    delete payload.communicationLandmark;
    delete payload.communicationState;
    delete payload.communicationCity;
    delete payload.communicationZip;
    delete payload.weight;
    delete payload.height;
    delete payload.pan;
    delete payload.aadhaar;
    delete payload.bankName;
    delete payload.bankAccount;
    delete payload.ifsc;
    delete payload.useSameAddress;

    console.log(payload, "payload");
    const res = await editClientPersonalInfo(clientData?._id, payload);
    if (res?.status === 200) {
      toast.info(res.message);
      onClose();
      fetching();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
    onChange(values);
  };

  const handleFileChange = async (file) => {
    setAvatarLoading(true);
    await uploadDocOnS3(file).then((location) => {
      setAvatarImage(location)
      setAvatarLoading(false);
    })
      .catch((error) => {
        console.error("Error uploading file:", error.message);
        setAvatarImage(null);
        setAvatarLoading(false);
        toast.error(error?.message || 'Error uploading file')
      });
  };

  return (
    <div className=" bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Edit Client Info
      </h2>
      <Form layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        <div className="mb-[24px] relative w-[fit-content]">
          <Spin spinning={avatarLoading} >
            <Avatar
              size={100}
              src={avatarImage || clientData?.profile || ""}
              icon={!clientData?.profile && <UserOutlined />}
            />
            <div
              className="absolute bottom-[13px] right-[-3px] cursor-pointer"
              onClick={() => document.getElementById("avatarUpload").click()}
            >
              <AvatarEditIcon />
            </div>
            <input
              id="avatarUpload"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => handleFileChange(e.target.files[0])}
            />
          </Spin>
        </div>

        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input placeholder="Enter first name" className="h-[48px]" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Middle Name"
                name="middleName"
                rules={[
                  { required: false, message: "Please enter middle name" },
                ]}
              >
                <Input placeholder="Enter middle name" className="h-[48px]" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input placeholder="Enter last name" className="h-[48px]" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Date of Birth"
                name="dateOfBirth"
                rules={[
                  { required: true, message: "Please enter date of birth" },
                ]}
              >
                <DatePicker
                  className="h-[48px] w-[100%]"
                  placeholder="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Age"
                name="age"
                className="text-[12px] font-[400] text-left font-roboto text-black"
                rules={[
                  { required: true, message: "Please enter age" },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Please enter number only",
                  },
                ]}
              >
                <Input placeholder="Enter Age" className="h-[48px]" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Gender"
                name="gender"
                rules={[{ required: true, message: "Please select gender" }]}
              >
                <Select placeholder="Select Gender" className="h-[48px]">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Email Address"
                name="email"
                rules={[
                  { required: true, message: "Please enter email" },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Email"
                  className="h-[48px]"
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Phone No."
                name="phoneNumber"
                rules={[
                  { required: true, message: "Please enter phone number" },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                ]}
              >
                <Input placeholder="Enter Phone No." className="h-[48px]" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Alternative Phone No. "
                name="alternatePhoneNumber"
                rules={[
                  { required: false, message: "Please enter   phone number" },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit  phone number",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Alternative Phone No."
                  className="h-[48px]"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Mother Name"
                name="motherName"
                rules={[
                  { required: true, message: "Please enter mother name" },
                ]}
              >
                <Input placeholder="Enter mother name" className="h-[48px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Father Name"
                name="fatherName"
                rules={[
                  { required: true, message: "Please enter father name" },
                ]}
              >
                <Input placeholder="Enter father name" className="h-[48px]" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Annual Income"
                name="annualIncome"
                rules={[
                  { required: true, message: "Please enter annual income" },
                  {
                    pattern: /^[0-9,]+$/,
                    message:
                      "Please enter a valid number (e.g., 100000 or 100,000)",
                  },
                ]}
              >
                <Input placeholder="Enter annual income" className="h-[48px]" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Qualification"
                name="qualification"
                rules={[
                  { required: true, message: "Please select qualification" },
                ]}
              >
                <Select placeholder="Select Qualification" className="h-[48px]">
                  {qualifications?.map((qualification) => (
                    <Option key={qualification} value={qualification}>
                      {qualification}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="text-[12px] font-[400] text-left font-roboto text-black"
                label="Nature of business"
                name="businessNature"
                rules={[
                  {
                    required: true,
                    message: "Please select nature of business",
                  },
                ]}
              >
                <Select
                  placeholder="Select Nature of Business"
                  className="h-[48px]"
                >
                  {natureOfBusiness?.map((business) => (
                    <Option key={business} value={business}>
                      {business}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {
            <>
              <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
                Permanent Address
              </p>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    label="Street Address"
                    name="streetAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please enter street address",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter street address"
                      className="h-[48px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    label="Apt., Unit, Suite (Optional)"
                    name="apartment"
                    rules={[{ required: false }]}
                  >
                    <Input
                      placeholder="Enter Apt., Unit, Suite"
                      className="h-[48px]"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    label="Landmark"
                    name="landmark"
                    rules={[
                      {
                        required: true,
                        message: "Please enter landmark",
                      },
                    ]}
                  >
                    <Input placeholder="Enter landmark" className="h-[48px]" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="State"
                    name="state"
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    rules={[{ required: true, message: "Please select state" }]}
                  >
                    <Select
                      placeholder="Select State"
                      className="h-[48px]"
                      showSearch
                      allowClear
                    >
                      {states.map((state) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="City"
                    name="city"
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    rules={[{ required: true, message: "Please select city" }]}
                  >
                    <Select
                      placeholder="Select City"
                      className="h-[48px]"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {Object.values(stateCityData)
                        .flat()
                        .map((city) => (
                          <Option key={city} value={city}>
                            {city}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="ZIP / postcode"
                    name="zip"
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    rules={[
                      { required: true, message: "Please enter zip code" },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid zip code",
                      },
                    ]}
                  >
                    <Input placeholder="Pin Code" className="h-[48px]" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          }

          <Form.Item
            name="useSameAddress"
            valuePropName="checked"
            className="text-[12px] font-[400] text-left font-roboto text-black"
          >
            <Checkbox
              checked={useSameAddress}
              onChange={(e) => setUseSameAddress(e.target.checked)}
              defaultChecked={true}
            >
              Use same as Communication Address
            </Checkbox>
          </Form.Item>

          {!useSameAddress && (
            <>
              <p className="text-[14px] font-[500] my-[16px] text-left font-roboto text-black">
                Communication Address
              </p>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Street Address"
                    name="communicationStreetAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please enter street address",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter street address"
                      className="h-[48px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Apt., Unit, Suite (Optional)"
                    name="communicationApartment"
                  >
                    <Input
                      placeholder="Enter Apt., Unit, Suite"
                      className="h-[48px]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    label="Landmark"
                    name="communicationLandmark"
                    rules={[
                      {
                        required: false,
                        message: "Please enter landmark",
                      },
                    ]}
                  >
                    <Input placeholder="Enter landmark" className="h-[48px]" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="State"
                    name="communicationState"
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    rules={[{ required: true, message: "Please select state" }]}
                  >
                    <Select
                      placeholder="Select State"
                      className="h-[48px]"
                      showSearch
                      allowClear
                    >
                      {states.map((state) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="City"
                    name="communicationCity"
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    rules={[{ required: true, message: "Please select city" }]}
                  >
                    <Select
                      placeholder="Select City"
                      className="h-[48px]"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {Object.values(stateCityData)
                        .flat()
                        .map((city) => (
                          <Option key={city} value={city}>
                            {city}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="ZIP / postcode"
                    name="communicationZip"
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    rules={[
                      { required: true, message: "Please enter zip code" },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid zip code",
                      },
                    ]}
                  >
                    <Input placeholder="Pin Code" className="h-[48px]" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Weight"
                name="weight"
                className="text-[12px] font-[400] text-left font-roboto text-black"
                rules={[
                  { required: true, message: "Please enter weight" },
                  {
                    pattern: /^[0-9,]+$/,
                    message: "Please enter a valid number ",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Weight"
                  className="h-[48px]"
                  suffix="Kg"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Height"
                name="height"
                className="text-[12px] font-[400] text-left font-roboto text-black"
                rules={[
                  { required: true, message: "Please enter height" },
                  {
                    pattern: /^(10|[1-9])('|'[0-9]{1,2})?$/,
                    message: "Please enter a valid height eg. 5'10",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Height"
                  className="h-[48px]"
                  suffix="In"
                />
              </Form.Item>
            </Col>
          </Row>

          {user?.permission_client?.includes("Documents") && (
            <div className="mb-[24px]">
              <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
                Documents
              </p>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    label="Aadhaar No."
                    name="aadhaar"
                    rules={[
                      { required: true, message: "Please enter Aadhaar No." },
                    ]}
                  >
                    <Input
                      placeholder="Enter Aadhaar No."
                      className="h-[48px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="text-[12px] font-[400] text-left font-roboto text-black"
                    label="Pan No."
                    name="pan"
                    rules={[
                      { required: true, message: "Please enter Pan No." },
                    ]}
                  >
                    <Input placeholder="Enter Pan No." className="h-[48px]" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <div className="mb-[24px]">
            <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
              Bank Details
            </p>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Bank Account No. (optional)"
                  name="bankAccount"
                >
                  <Input
                    placeholder="Enter Bank Account No."
                    className="h-[48px]"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Bank Name"
                  name="bankName"
                >
                  <Select placeholder="Select Bank" className="h-[48px]">
                    {banksInIndia?.map((bank) => (
                      <Option key={bank} value={bank}>
                        {bank}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="IFSC Code (optional)"
                  name="ifsc"
                >
                  <Input placeholder="Enter IFSC Code" className="h-[48px]" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex justify-end mt-[20px]">
          <button type={avatarLoading ? "button" : "submit"} className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
            {(avatarLoading) ? "Loading..." : "Update"}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditClientPersonalInfo;
