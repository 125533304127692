import React, { useState } from "react";
import AuthForm from "../../components/AuthForm";
import { ForgotData } from "../../utils/FormFieldData";
import { forgotPassword } from "../../service/Collections";
import { toast } from "react-toastify";

export default function ForgetPassword() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    const res = await forgotPassword(values);
    if (res?.status === 200) {
      toast.success(res?.message);
      setLoading(false);
    } else {
      toast.error(res?.message);
      console.log(res?.message);
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <AuthForm loading={loading} onFinish={handleSubmit} data={ForgotData} />
    </div>
  );
}
