import React, { useState } from 'react'
import AuthForm from '../../components/AuthForm'
import { SetNewPasswordData } from '../../utils/FormFieldData';

export default function CreatePaassword() {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => { };

    return (
        <div className='w-full h-full flex justify-center items-center' >
            <AuthForm loading={loading} onFinish={handleSubmit} data={SetNewPasswordData} />
        </div>
    )
}
