import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Button } from "antd";
import dayjs from "dayjs";
import {
  editPolicy,
  getFamilyMember,
  getNomineeMember,
} from "../../../../service/Collections";
import { getErrorMessage } from "../../../../utils/errorMessage";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import CustomUpload from "../../../../components/CustomUpload";
import { uploadDocOnS3 } from "../../../../aws/commonFunc";
const { Option } = Select;

const EditPolicy = ({ onClose, editingUser, fetchListing }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [policyOwner, setPolicyOwner] = useState("");
  const [familyMembers, setFamilyMembers] = useState([]);
  const clienId = location?.state?.id;
  const [nomineeMembers, setNomineeMembers] = useState([]);

  useEffect(() => {
    if (editingUser) {
      const data = editingUser;
      form.setFieldsValue({
        policyNo: data?.policyNumber,
        year: dayjs(data?.year.toString(), "YYYY"),
        policyOwner: data?.policyOwner,
        familyMemberOwner: data?.familyMemberOwner,
        type: data?.type,
        premiumAmt: data?.premiumAmount,
        proposer: data?.proposer,
        sumInsured: data?.sumInsured,
        premiumFrequency: data?.premiumFrequency,
        planName: data?.planName,
        pt: data?.pt,
        ppt: data?.ppt,
        policyStart: dayjs(data?.policyStart),
        endDate: dayjs(data?.maturityEndDate),
        nominee: data?.nomineeId,
        relation: data?.nominee?.relation,
        companyName: data?.companyName || "",
        email: data?.email || "",
        phoneNumber: data?.phoneNumber || "",
        // document: data?.document,
        document: data?.document
          ? [{ url: data.document, name: `Document` }]
          : [],
      });
      setPolicyOwner(data?.policyOwner);
    }
  }, [editingUser, form]);

  const handleFileUpload = async (fileList) => {
    try {
      const uploadedFiles = await Promise.all(
        fileList.map(async (file, index) => {
          if (file.response?.url || file.url) {
            return {
              uid: file.uid || index.toString(),
              name: file.name,
              url: file.response?.url || file.url,
              status: "done",
            };
          }
          const uploadedUrl = await uploadDocOnS3(file.originFileObj);
          if (uploadedUrl) {
            return {
              uid: index.toString(),
              name: file.name,
              url: uploadedUrl,
              status: "done",
            };
          } else {
            throw new Error("File upload failed");
          }
        })
      );
      form.setFieldsValue({ document: uploadedFiles });
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Failed to upload documents.");
    }
  };

  const onFinish = async (values) => {
    const policyId = editingUser?._id;
    const payload = {
      year: values?.year.year(),
      policyOwner: values?.policyOwner,
      type: values?.type,
      premiumAmount: values?.premiumAmt,
      familyMemberOwner: values?.familyMemberOwner,
      proposer: values?.proposer,
      sumInsured: values?.sumInsured,
      premiumFrequency: values?.premiumFrequency,
      planName: values?.planName,
      pt: values?.pt,
      ppt: values?.ppt,
      policyStart: values?.policyStart.format("YYYY-MM-DD"),
      maturityEndDate: values?.endDate.format("YYYY-MM-DD"),
      _id: policyId,
      document: values?.document?.map((doc) => doc.url).join(", ") || "",
      companyName: values?.companyName || "",
      email: values?.email || "",
      phoneNumber: values?.phoneNumber || "",
      nomineeId: values?.nominee || "",
      relation: values?.relation || "",
      ...(values?.policyOwner === "Family" && {
        familyMemberOwner: values?.member,
      }),
    };

    const res = await editPolicy(policyId, payload);
    if (res?.status === 200) {
      if (location.pathname !== "/manage-clients/edit/edit-policy") {
        fetchListing({
          current: 1,
          pageSize: 20,
          total: 0,
        });
      }
      toast.info(res.message);
      onClose();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
  };
  const onValuesChange = (changedValues) => {
    if (changedValues.policyOwner) {
      setPolicyOwner(changedValues.policyOwner);
    }
    if (changedValues?.nominee) {
      let selectedNominee = nomineeMembers?.find(
        (el) => el?._id === changedValues?.nominee
      );
      form.setFieldsValue({ relation: selectedNominee?.relation });
    }
  };
  const fetchFamilyMembers = async (id) => {
    const res = await getFamilyMember(id);
    if (res?.status === 200) {
      setFamilyMembers(res?.data);
    } else {
      setFamilyMembers([]);
      // const message = getErrorMessage(res, "Failed to fetch client details");
      // toast.error(message);
    }
  };

  const fetchNomineeMembers = async () => {
    const res = await getNomineeMember(clienId);
    if (res?.status === 200) {
      setNomineeMembers(res?.data);
    } else {
      setNomineeMembers([]);
    }
  };

  const formFields = [
    {
      label: "Policy No.",
      name: "policyNo",
      type: "input",
      placeholder: "Enter Policy No.",
      colSpan: 12,
      rules: [{ required: true, message: "Please enter policy number" }],
      readOnly: true,
    },
    {
      label: "Year",
      name: "year",
      type: "datePicker",
      placeholder: "Select Year",
      colSpan: 12,
      rules: [{ required: true, message: "Please select year" }],
      picker: "year",
      isDisabled: true,
    },
    {
      label: "Company Name",
      name: "companyName",
      type: "input",
      placeholder: "Enter Company Name",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter company name" }],
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      placeholder: "Enter Email",
      colSpan: 8,
      rules: [
        { required: true, message: "Please enter email" },
        {
          type: "email",
          message: "Please enter a valid email address",
        },
      ],
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "input",
      placeholder: "Enter Phone Number",
      colSpan: 8,
      rules: [
        { required: true, message: "Please enter phone number" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Please enter a valid 10-digit phone number",
        },
      ],
    },
    {
      label: "Policy Owner/Insured",
      name: "policyOwner",
      type: "select",
      placeholder: "Select",
      colSpan: 8,
      rules: [{ required: true, message: "Please select policy owner" }],
      options: ["self", "family"],
    },
    ...(policyOwner === "family"
      ? [
        {
          label: "Select Member",
          name: "familyMemberOwner",
          type: "select",
          placeholder: "Select",
          colSpan: 8,
          rules: [
            { required: true, message: "Please select a family member" },
          ],
          options: familyMembers?.map((member) => ({
            value: member?._id,
            label: member?.name,
            key: member?._id,
          })),
        },
      ]
      : []),
    {
      label: "Type",
      name: "type",
      type: "select",
      placeholder: "Select",
      colSpan: 8,
      rules: [{ required: true, message: "Please select type" }],
      options: [
        "Life",
        "Health",
        "Motor",
        "ULIP",
        "Personal Accident",
        "Travel",
        "Fire",
        "Marine",
        "Other",
      ],
      isDisabled: true,
    },
    {
      label: "Premium Amount",
      name: "premiumAmt",
      type: "input",
      placeholder: "Amount",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter premium amount" }],
    },
    {
      label: "Proposer",
      name: "proposer",
      type: "input",
      placeholder: "Enter Proposer Name",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter proposer name" }],
    },
    {
      label: "Sum Insured",
      name: "sumInsured",
      type: "input",
      placeholder: "Amount",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter sum insured" }],
    },
    {
      label: "Premium Frequency",
      name: "premiumFrequency",
      type: "select",
      placeholder: "Select",
      colSpan: 8,
      rules: [{ required: true, message: "Please select premium frequency" }],
      options: ["monthly", "quarterly", "annual"],
      initialValue: "Annual",
      isDisabled: true,
    },
    {
      label: "Plan Name",
      name: "planName",
      type: "input",
      placeholder: "Enter Plan Name",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter plan name" }],
    },
    {
      label: "PT",
      name: "pt",
      type: "input",
      placeholder: "Enter PT",
      colSpan: 8,
      rules: [
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error("This field is required"));
            }
            if (!/^[0-9]+$/.test(value)) {
              return Promise.reject(new Error("Please enter number only"));
            }
            if (value < 0 || value > 50) {
              return Promise.reject(
                new Error("Value must be between 0 and 50")
              );
            }
            return Promise.resolve();
          },
        },
      ],
      isDisabled: true,
    },
    {
      label: "PPT",
      name: "ppt",
      type: "input",
      placeholder: "Enter PPT",
      colSpan: 8,
      rules: [
        {
          validator: (_, value, callback) => {
            const ptValue = form.getFieldValue("pt");
            if (!value) {
              return Promise.reject(new Error("This field is required"));
            }
            if (!/^[0-9]+$/.test(value)) {
              return Promise.reject(new Error("Please enter number only"));
            }
            if (value < 0 || value > 50) {
              return Promise.reject(
                new Error("Value must be between 0 and 50")
              );
            }
            if (ptValue && Number(value) > Number(ptValue)) {
              return Promise.reject(new Error("PPT cannot be greater than PT"));
            }
            return Promise.resolve();
          },
        },
      ],
      isDisabled: true,
    },
    {
      label: "Nominee",
      name: "nominee",
      type: "select",
      placeholder: "Select the nominee",
      colSpan: policyOwner === "Family" ? 8 : 12,
      rules: [{ required: true, message: "Please select nominee" }],
      options: nomineeMembers?.map((member) => ({
        value: member?._id,
        label: member?.name,
        key: member?._id,
      })),
    },

    {
      label: "Nominee Relation",
      name: "relation",
      type: "input",
      placeholder: "Enter Relation",
      colSpan: policyOwner === "Family" ? 8 : 12,
      isDisabled: true,
    },
    {
      label: "Policy Start",
      name: "policyStart",
      type: "datePicker",
      placeholder: "Select Date",
      colSpan: 12,
      rules: [{ required: true, message: "Please select policy start date" }],
      isDisabled: true,
    },
    {
      label: "Maturity/End Date",
      name: "endDate",
      type: "datePicker",
      placeholder: "Select Date",
      colSpan: 12,
      rules: [{ required: true, message: "Please select end date" }],
      isDisabled: true,
    },
  ];

  const CustomUploadCallback = useCallback(() => {
    return (
      <CustomUpload
        fileListProp={form.getFieldValue("document") || []}
        onFileChange={handleFileUpload}
      />

    )
  }, [form.getFieldValue("document")]);

  useEffect(() => {
    if (clienId) {
      fetchFamilyMembers(clienId);
      fetchNomineeMembers(clienId);
    }
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="flex flex-col gap-[15px]">
        <p className="text-[32px] font-[700] text-center text-black font-roboto">
          Edit Policy
        </p>
        <p className="text-[24px] font-[400] text-center text-black font-roboto">
          Enter the client policy details to add the new policy
        </p>
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        className="mt-6"
      // initialValues={{ document: ['kjshbvdg'] }}
      >
        <Row gutter={16}>
          {formFields.map((field, index) => (
            <Col span={field.colSpan} key={index}>
              <Form.Item
                label={field.label}
                name={field.name}
                rules={field.rules}
                className="text-[12px] font-[400] text-left font-roboto text-black"
                initialValue={field.initialValue || ""}
              >
                {field.type === "input" && (
                  <Input
                    placeholder={field.placeholder}
                    className="h-[48px]"
                    readOnly={field.readOnly || false}
                    disabled={field.isDisabled || false}
                  />
                )}
                {field.type === "select" && (
                  <Select className="h-[48px]" placeholder={field.placeholder}>
                    {field?.options.map((option) =>
                      typeof option === "object" ? (
                        <Option key={option.value} value={option.value}>
                          {option.label.charAt(0).toUpperCase() +
                            option.label.slice(1)}
                        </Option>
                      ) : (
                        <Option key={option} value={option}>
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </Option>
                      )
                    )}
                  </Select>
                )}
                {field.type === "datePicker" && (
                  <DatePicker
                    className="h-[48px] w-full"
                    placeholder={field.placeholder}
                    picker={field.picker || "date"}
                    format={field.picker === "year" ? "YYYY" : undefined}
                    disabled={field.isDisabled || false}
                  />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row gutter={16}>
          {console.log(form.getFieldValue(), "123456780")}
          <Col span={24}>
            <Form.Item
              label="Upload Document"
              name="document"
              valuePropName="fileList"
            >
              <CustomUploadCallback />

            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end mt-6">
          <Button
            type="primary"
            htmlType="submit"
            className="bg-green-theme text-white h-[48px] w-[214px] rounded-md"
          >
            Edit Policy
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditPolicy;
