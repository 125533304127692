import React, { useState } from "react";
import { TableEditIcon } from "../../../../../utils/SVG";
import { Avatar, Col, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import EditClientPersonalInfo from "./EditClientPersonalInfo";
import CustomModal from "../../../../../components/CustomModal";
import dayjs from "dayjs";
import EditClientMedicalHistory from "./EditmMedicalHistory";
import { useSelector } from "react-redux";

const ClientInfoAndMedicalHistory = ({ fetching }) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [
    isEditMedicalHistoryModalVisible,
    setIsEditMedicalHistoryModalVisible,
  ] = useState(false);
  const clientData = useSelector((state) => state?.selectedClient?.client);
  const user = useSelector((state) => state?.loginSlice?.data);
  const handleEditClick = () => {
    setIsEditModalVisible(true);
  };

  const handleMedicalHistoryEditClick = () => {
    setIsEditMedicalHistoryModalVisible(true);
  };

  if (!clientData || Object.keys(clientData)?.length === 0) {
    return <p>No client data available</p>;
  }

  const permanentAddress = clientData[0]?.permanentAddress;
  const fullAddress = [
    permanentAddress?.streetAddress || "NA",
    permanentAddress?.landmark || "NA",
    permanentAddress?.city || "NA",
    permanentAddress?.state || "NA",
    permanentAddress?.zip || "NA",
  ].join(", ");

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex justify-between gap-[20px]">
        <div className="bg-white rounded-[20px] flex flex-col gap-[24px] justify-center w-[35%]">
          <div className="flex flex-col justify-center items-center">
            <Avatar
              size={200}
              src={clientData[0]?.profile || ""}
              icon={!clientData[0]?.profile && <UserOutlined />}
              className="mb-[12px]"
            />
            <p className="text-[24px] font-[400] font-roboto text-black">
              {clientData[0]?.firstName || ""} {clientData[0]?.middleName || ""}{" "}
              {clientData[0]?.lastName || ""}
            </p>
            <p className="text-[14px] font-[400] font-roboto text-black">
              {clientData[0]?.permanentAddress?.state || ""}
            </p>
          </div>
          <div className="flex gap-[40px] justify-center items-center">
            <div>
              <p className="text-[17px] font-[600] font-roboto text-black text-center">
                {clientData[0]?.policyDetail?.totalPolicies || 0}
              </p>
              <p className="text-[14px] font-[400] font-roboto text-black">
                No. of Policy
              </p>
            </div>
            <div>
              <p className="text-[17px] font-[600] font-roboto text-black text-center">
                {clientData[0]?.policyDetail?.totalPremiumAmount?.toLocaleString() ||
                  0}
              </p>
              <p className="text-[14px] font-[400] font-roboto text-black">
                Total Premium
              </p>
            </div>
            <div>
              <p className="text-[17px] font-[600] font-roboto text-black text-center">
                {clientData[0]?.policyDetail?.totalRaisedClaims || 0}{" "}
              </p>
              <p className="text-[14px] font-[400] font-roboto text-black">
                Total Claim
              </p>
            </div>
          </div>
        </div>
        <div className={`p-6 bg-white rounded-lg shadow-lg w-[65%]`}>
          <div className="flex justify-between">
            <h2 className="text-2xl font-semibold mb-[10px]">Client Info</h2>
            {user?.permission_client?.includes("Edit Profile") && (
              <div className="cursor-pointer" onClick={handleEditClick}>
                <TableEditIcon />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[10px]">
            <Row gutter={16}>
              <Col span={24}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Name
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.firstName || ""}{" "}
                  {clientData[0]?.middleName || ""}{" "}
                  {clientData[0]?.lastName || ""}
                </p>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Date of Birth
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {dayjs(clientData[0]?.dateOfBirth).format("DD/MM/YYYY")}
                </p>
              </Col>
              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Age
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.age || "NA"}
                </p>
              </Col>

              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Gender
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.gender === 1 ? "Male" : "Female"}
                </p>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Phone No.
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.phoneNumber || "NA"}
                </p>
              </Col>
              <Col span={12}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Alternative Phone No.
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.alternatePhoneNumber || "NA"}
                </p>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Email
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.email || "NA"}
                </p>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Permanent Address
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {fullAddress}
                </p>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Mother Name
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.motherName || "NA"}
                </p>
              </Col>
              <Col span={12}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Father Name
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.fatherName || "NA"}
                </p>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Annual Income
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.annualIncome || "NA"}
                </p>
              </Col>
              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Qualification
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.qualification || "NA"}
                </p>
              </Col>

              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Nature of business
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {clientData[0]?.businessNature || "NA"}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className={`p-6 bg-white rounded-lg shadow-lg`}>
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-[10px]">
            Client Medical History
          </h2>
          {user?.permission_client?.includes("Edit Profile") && (
            <div
              className="cursor-pointer"
              onClick={handleMedicalHistoryEditClick}
            >
              <TableEditIcon />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-[10px] p-[20px] border border-gray-400 rounded-[6px]">
          <p className="text-[16px] font-[500] text-left font-roboto text-black">
            Medical History
          </p>
          {clientData[0]?.medicalHistory.map(
            (item, index) =>
              item?.type === "client" && (
                <div
                  className="flex justify-between items-center"
                  key={item?._id}
                >
                  <div>
                    <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                      Medical illness
                    </span>
                    <p className="text-[16px] font-[500] text-left font-roboto text-black">
                      {item?.illness || "NA"}
                    </p>
                  </div>
                  <div>
                    <p className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                      Medicine Name
                    </p>
                    <p className="text-[16px] font-[500] text-left font-roboto text-black">
                      {item?.medicineName || "NA"}
                    </p>
                  </div>

                  <div>
                    {item?.document?.trim() ? (
                      <a
                        href={item.document}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[14px] font-[500] text-center font-inter text-green-theme underline"
                      >
                        View Document
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      <CustomModal
        width={883}
        height={500}
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      >
        <EditClientPersonalInfo
          onChange={(updatedData) =>
            console.log(
              updatedData,
              "updatedDataupdatedDataupdatedDataupdatedDataupdatedData"
            )
          }
          onClose={() => setIsEditModalVisible(false)}
          fetching={fetching}
        />
      </CustomModal>

      <CustomModal
        width={883}
        height={500}
        isVisible={isEditMedicalHistoryModalVisible}
        onClose={() => setIsEditMedicalHistoryModalVisible(false)}
      >
        <EditClientMedicalHistory
          onChange={(updatedData) => console.log(updatedData)}
          onClose={() => setIsEditMedicalHistoryModalVisible(false)}
          fetching={fetching}
        />
      </CustomModal>
    </div>
  );
};

export default ClientInfoAndMedicalHistory;
