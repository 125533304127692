import { createSlice } from "@reduxjs/toolkit";

export const addClientInfoSlice = createSlice({
  name: "addClientInfoSlice",
  initialState: {
    currentStep: 0,
    personalInfo: null,
    nomineeDetails: null,
    otherDetails: null,
  },
  reducers: {
    updatePersonalInfo: (state, action) => {
      if (action?.payload) {
        state.personalInfo = action?.payload;
        state.currentStep = 1;
      }
    },
    updateNomineeDetails: (state, action) => {
      if (action?.payload) {
        state.nomineeDetails = action?.payload;
        state.currentStep = 2;
      }
    },
    updateOtherDetails: (state, action) => {
      if (action?.payload) {
        state.otherDetails = action?.payload;

      }
    },
    resetAllClientInfo: (state) => {
      state.currentStep = 0;
      state.personalInfo = null;
      state.nomineeDetails = null;
      state.otherDetails = null;
    },
    resetPersonalInfo: (state) => {
      state.currentStep = 0;
      state.personalInfo = null;
    },
    resetNomineeDetails: (state) => {
      state.nomineeDetails = null;
    },
    resetOtherDetails: (state) => {
      state.otherDetails = null;
    },
  },
});

export const {
  updatePersonalInfo,
  updateNomineeDetails,
  updateOtherDetails,
  resetAllClientInfo,
  resetPersonalInfo,
  resetNomineeDetails,
  resetOtherDetails,
} = addClientInfoSlice.actions;

export default addClientInfoSlice.reducer;
