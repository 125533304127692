import React from "react";
import { Outlet } from "react-router-dom";
import { authImg } from "../utils/ImagesPaths";

export default function PublicLayout() {
  return (
    <div className="flex w-full h-dvh bg-white flex-col md:flex-row">
      <div className="relative w-full md:w-[74%] hidden md:block bg-blue-300">
        <img src={authImg} alt="logo" className="w-full h-full object-cover" />
      </div>

      <div className="w-full md:w-3/10 bg-white h-full overflow-y-auto px-6 py-8 
                    md:px-10 rounded-tl-[30px] md:rounded-bl-[30px] absolute md:static">
        <Outlet />
      </div>
    </div>
  );
}
