import React from "react";

const ViewPolicyQuery = ({ feedbackData }) => {
  return (
    <div className="flex flex-col gap-[16px]">
      <p className="font-roboto text-2xl font-bold leading-7 text-center">
        Query
      </p>

      <div className="flex flex-col gap-[24px]">
          <div>
            <p className="font-roboto text-[14px] font-bold leading-7">
              Client Query
            </p>
            <p className="font-roboto text-[14px] font-[400] leading-7">
              {feedbackData?.description ? feedbackData?.description : "No Query"}
            </p>
          </div>
          <div>
            <p className="font-roboto text-[14px] font-bold leading-7">
              Employee Note
            </p>
            <p className="font-roboto text-[14px] font-[400]] leading-7">
              {feedbackData?.note ? feedbackData?.note : "No Notes"}
            </p>
          </div>
      </div>
    </div>
  );
};

export default ViewPolicyQuery;
