import { Col, Divider, Row } from "antd";
import React, { useState } from "react";
import { TableEditIcon, TableViewIcon } from "../../../../../utils/SVG";
import CustomModal from "../../../../../components/CustomModal";
import { decryptSecretHex } from "../../../../../utils/commonFunction";
import EditFamilyDetail from "./EditFamilyDetails";
import EditClientPersonalInfo from "../clientInfoAndMedicalHistory/EditClientPersonalInfo";
import { useSelector } from "react-redux";
import AddFamilyDetail from "../AddFamilyForm";
const ClientDocumentFamilyAndBankDetails = ({ fetching }) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editFamilyDetailModal, setEditFamilyDetailModal] = useState(false);
  const [isAadharVisible, setIsAadharVisible] = useState(false);
  const [isPanVisible, setIsPanVisible] = useState(false);
  const clientD = useSelector((state) => state?.selectedClient?.client);
  const [clientData, setClientData] = useState(clientD || []);
  const user = useSelector((state) => state?.loginSlice?.data);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const toggleAadharVisibility = () => {
    setIsAadharVisible((prev) => !prev);
  };

  const togglePanVisibility = () => {
    setIsPanVisible((prev) => !prev);
  };

  const handleEditClick = () => {
    setIsEditModalVisible(true);
  };

  const handleAddClick = () => {
    setIsAddModalVisible(true);
  };

  const handleEditFamilyModalClick = () => {
    setEditFamilyDetailModal(true);
  };

  if (!clientData || Object.keys(clientData)?.length === 0) {
    return <p>No client data available</p>;
  }

  const handleUpdateClientData = (updatedData) => {
    setClientData((prevClientData) => {
      const updatedClient = { ...prevClientData[0], ...updatedData };
      return [updatedClient];
    });
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <div className={`p-6 bg-white rounded-lg shadow-lg`}>
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-[10px]">Document</h2>
        </div>

        <div className="w-[100%]">
          <div className="flex justify-between items-center">
            <div className="flex">
              <p
                className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
              >
                Aadhaar No.
              </p>
              <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                {isAadharVisible
                  ? decryptSecretHex(clientData[0]?.documents?.aadhaarNumber)
                  : "xxxxxxxxxxxx"}
              </p>
            </div>

            {user?.permission_client?.includes("Documents") && (
              <div className="cursor-pointer flex gap-[3px]">
                <div onClick={toggleAadharVisibility}>
                  <TableViewIcon />
                </div>
                <div onClick={handleEditClick}>
                  <TableEditIcon />
                </div>
              </div>
            )}
          </div>

          <Divider
            style={{
              borderColor: "#696969",
              margin: "0px",
              opacity: 0.3,
            }}
          />
          <div className="flex justify-between items-center">
            <div className="flex">
              <p
                className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
              >
                Pan No.
              </p>
              <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                {isPanVisible
                  ? decryptSecretHex(clientData[0]?.documents?.panNumber)
                  : "xxxxxxxxxxxx"}
              </p>
            </div>
            {user?.permission_client?.includes("Documents") && (
              <div className="cursor-pointer flex gap-[3px]">
                <div onClick={togglePanVisibility}>
                  <TableViewIcon />
                </div>
                <div onClick={handleEditClick}>
                  <TableEditIcon />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={`p-6 bg-white rounded-lg shadow-lg`}>
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-[10px]">Family Details</h2>
          <div className="flex gap-[10px]">
            <div
              className="cursor-pointer bg-green-theme w-[24px] h-[25px] rounded-full flex items-center justify-center text-white"
              onClick={handleAddClick}
            >
              +
            </div>
            {clientData[0]?.familyDetails?.length > 0 &&
              user?.permission_client?.includes("Edit Profile") && (
                <div
                  className="cursor-pointer"
                  onClick={handleEditFamilyModalClick}
                >
                  <TableEditIcon />
                </div>
              )}
          </div>
        </div>
        {clientData[0]?.familyDetails?.length > 0 ? (
          clientData[0]?.familyDetails.map((item, index) => (
            <div>
              <p className="text-[14px] font-[500] text-left font-roboto">
                Member{index + 1}
              </p>

              <div className="w-[100%]">
                <div className="flex">
                  <p
                    className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
                  >
                    Name
                  </p>
                  <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                    {item?.name || "NA"}
                  </p>
                </div>
                <Divider
                  style={{
                    borderColor: "#696969",
                    margin: "0px",
                    opacity: 0.3,
                  }}
                />
                <div className="flex">
                  <p
                    className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
                  >
                    Height
                  </p>
                  <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                    {item?.height || "NA"}
                  </p>
                </div>
                <Divider
                  style={{
                    borderColor: "#696969",
                    margin: "0px",
                    opacity: 0.3,
                  }}
                />
                <div className="flex">
                  <p
                    className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
                  >
                    Weight
                  </p>
                  <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                    {item?.weight || "NA"}
                  </p>
                </div>
                <Divider
                  style={{
                    borderColor: "#696969",
                    margin: "0px",
                    opacity: 0.3,
                  }}
                />
                <div className="flex">
                  <p
                    className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
                  >
                    Health Status
                  </p>
                  <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                    {item?.healthStatus
                      ? item?.healthStatus.charAt(0).toUpperCase() +
                        item?.healthStatus.slice(1)
                      : "NA"}
                  </p>
                </div>
                <Divider
                  style={{
                    borderColor: "#696969",
                    margin: "0px",
                    opacity: 0.3,
                  }}
                />
                <div className="flex">
                  <p
                    className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
                  >
                    Medical illness
                  </p>
                  <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                    {item?.illness || "NA"}
                  </p>
                </div>
                <Divider
                  style={{
                    borderColor: "#696969",
                    margin: "0px",
                    opacity: 0.3,
                  }}
                />
                <div className="flex">
                  <p
                    className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px] opacity-[40%] w-[200px]`}
                  >
                    Description
                  </p>
                  <p className="flex items-center text-[14px] font-[400] text-left font-roboto">
                    {item?.description || "NA"}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-[14px] font-[400] text-center mt-[10px] font-roboto">
            No family details available.
          </p>
        )}
      </div>

      <div className={`p-6 bg-white rounded-lg shadow-lg`}>
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-[10px]">Bank Details</h2>
          {user?.permission_client?.includes("Edit Profile") && (
            <div className="cursor-pointer" onClick={handleEditClick}>
              <TableEditIcon />
            </div>
          )}
        </div>

        <Row gutter={16}>
          <Col span={8}>
            <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
              Bank Account
            </span>
            <p className="text-[16px] font-[500] text-left font-roboto text-black">
              {decryptSecretHex(
                clientData[0]?.bankDetails?.accountNumber || "NA"
              )}
            </p>
          </Col>
          <Col span={8}>
            <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
              Bank Name
            </span>
            <p className="text-[16px] font-[500] text-left font-roboto text-black">
              {clientData[0]?.bankDetails?.bankName || "NA"}
            </p>
          </Col>

          <Col span={8}>
            <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
              IFSC Code.
            </span>
            <p className="text-[16px] font-[500] text-left font-roboto text-black">
              {decryptSecretHex(clientData[0]?.bankDetails?.ifscCode || "NA")}
            </p>
          </Col>
        </Row>
      </div>

      <CustomModal
        width={883}
        height={500}
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      >
        <EditClientPersonalInfo
          onChange={handleUpdateClientData}
          onClose={() => setIsEditModalVisible(false)}
          fetching={fetching}
        />
      </CustomModal>

      <CustomModal
        width={883}
        height={500}
        isVisible={editFamilyDetailModal}
        onClose={() => setEditFamilyDetailModal(false)}
      >
        <EditFamilyDetail
          onChange={(updatedData) => console.log(updatedData)}
          onClose={() => setEditFamilyDetailModal(false)}
          fetching={fetching}
        />
      </CustomModal>

      <CustomModal
        width={883}
        height={500}
        isVisible={isAddModalVisible}
        onClose={() => setIsAddModalVisible(false)}
      >
        <AddFamilyDetail
          onClose={() => setIsAddModalVisible(false)}
          fetching={fetching}
        />
      </CustomModal>
    </div>
  );
};

export default ClientDocumentFamilyAndBankDetails;
