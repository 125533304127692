import React, { useState } from "react";
import { Drawer } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { AKLogo } from "../utils/ImagesPaths";
import { useSelector } from "react-redux";

const HeaderDrawer = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path) =>
    location.pathname.includes(path) || location.pathname === path;
  const userData = useSelector((state) => state?.loginSlice?.data);

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const userTabs = [
    { display: "Dashboard", path: "/dashboard" },
    { display: "Role & Management", path: "/role-management" },
    { display: "Feedback", path: "/feedback" },
    { display: "Manage Clients", path: "/manage-clients" },
    { display: "History", path: "/history" },
  ].filter((tab) => userData?.permission_module?.includes(tab?.display));

  const finalTabs = [...userTabs, { display: "History", path: "/history" }];

  return (
    <div className="lg:hidden">
      <MenuOutlined
        onClick={showDrawer}
        className="text-[24px] cursor-pointer ml-[20px]"
      />

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <img src={AKLogo} alt="logo" className="w-[157.66px] h-[40px]" />
            <CloseOutlined onClick={onClose} className="cursor-pointer" />
          </div>
        }
        placement="left"
        onClose={onClose}
        visible={visible}
        width={250}
        closable={false}
      >
        <ul className="flex flex-col gap-[12px]">
          {finalTabs.map((tab) => (
            <li
              key={tab?.path}
              className={`px-[25px] py-[7px] rounded-[20px] text-[14px] font-[400] leading-[21px] cursor-pointer ${
                isActive(tab?.path) ? "bg-green-theme text-white" : ""
              }`}
              onClick={() => {
                navigate(tab?.path);
                onClose();
              }}
            >
              {tab?.display}
            </li>
          ))}
        </ul>
      </Drawer>
    </div>
  );
};

export default HeaderDrawer;
