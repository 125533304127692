import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { TableEditIcon, TableViewIcon } from "../../utils/SVG";
import CustomModal from "../../components/CustomModal";
import ViewPolicyQuery from "./ViewPolicyQuery";
import EditPolicyQuery from "./EditPolicyQuery";
import { getUserQueryList } from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Tooltip } from "antd";
import CustomAvatar from "../../components/CustomAvatar";

let defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};


const PolicyQuerys = ({ search, selectedFilter }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const MAX_NOTE_LENGTH = 30;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);

  const truncateNote = (note) => {
    if (note?.length > MAX_NOTE_LENGTH) {
      return `${note.slice(0, MAX_NOTE_LENGTH)}...`;
    }
    return note;
  };

  const handleViewClick = (feedback) => {
    setSelectedFeedback(feedback);
    setIsModalVisible(true);
  };

  const handleEditClick = (feedback) => {
    setSelectedFeedback(feedback);
    setIsEditModalVisible(true);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    fetchListing(pagination);
  };

  const fetchListing = async (pagination) => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("perPage", pagination?.pageSize);
    params.append("page", pagination?.current);
    params.append("search", search);
    if (selectedFilter !== null) {
      params.append("status", selectedFilter);
    }
    let res = await getUserQueryList(params);
    if (res?.status === 200) {
      const feedbackList = res?.data?.userQueries;
      setTableData(feedbackList?.map((el, index) => ({ ...el, key: index + 1 })));
      setPagination((prev) => ({
        ...prev,
        total: res?.data?.totalQueries,
      }));
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      key: "key",
      render: (text) => {
        return <span>{(pagination?.current - 1) * pagination?.pageSize + text}</span>;
      },
    },
    {
      title: "Client Name",
      dataIndex: "user",
      key: "user",
      render: (user) => (
        <div className="flex items-center">
          {user?.profile ? (
            <img
              src={user?.profile}
              alt={user?.firstName}
              className="w-8 h-8 rounded-full"
            />
          ) : (
            user?.firstName && (
              <CustomAvatar
                firstName={user?.firstName}
                lastName={user?.lastName}
              />
            )
          )}
          <span className="ml-2">
            {user?.firstName} {user?.lastName}
          </span>
        </div>
      ),
    },
    {
      title: "Employee",
      dataIndex: "admin",
      key: "admin",
      width: 200,
      render: (admin) =>
        admin && (
          <div className="flex items-center bg-[#F2F2F2] rounded-[100px] p-[10px] gap-[10px]">
            <CustomAvatar
              firstName={admin?.firstName}
              lastName={admin?.lastName}
            />
            <div>
              <p className="text-black text-[12px] font-medium w-[120px] truncate">
                {`${admin?.firstName || ""} ${admin?.lastName || ""}`}
              </p>
              <p className="text-[#696969] text-[10px] font-medium	">
                {admin?.role}
              </p>
            </div>
          </div>
        ),
    },

    {
      title: "Query",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: (description) => {
        const truncatedNote = truncateNote(description);
        return description?.length > MAX_NOTE_LENGTH ? (
          <Tooltip title={description} placement="bottom">
            <span>{truncatedNote}</span>
          </Tooltip>
        ) : (
          <span>{truncatedNote}</span>
        );
      },
    },
    {
      title: "Employee Note",
      dataIndex: "note",
      key: "note",
      render: (note) => {
        const truncatedNote = truncateNote(note);
        return note?.length > MAX_NOTE_LENGTH ? (
          <Tooltip title={note} placement="bottom">
            <span>{truncatedNote}</span>
          </Tooltip>
        ) : (
          <span>{truncatedNote}</span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => <span>{dayjs(date).format("M/D/YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div
          className={`text-center text-[12px] font-semibold py-[3px] px-[12px] rounded-[100px] ${status === "Resolved"
            ? "bg-[#DFFFDE] text-[#5EB85C]"
            : "bg-[#FFDEE6] text-[#FB4A49]"
            }`}
        >
          {status}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex space-x-2">
          <div
            className="cursor-pointer"
            onClick={() => handleEditClick(record)}
          >
            <TableEditIcon />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => handleViewClick(record)}
          >
            <TableViewIcon />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchListing({ ...pagination, current: 1 });
  }, [search, selectedFilter]);



  return (
    <div>
      <CustomTable
        columns={columns}
        data={tableData}
        loading={loading}
        paginationProps={pagination}
        onChange={handleTableChange}
      ></CustomTable>
      <CustomModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {selectedFeedback && (
          <ViewPolicyQuery feedbackData={selectedFeedback} />
        )}
      </CustomModal>

      <CustomModal
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      >
        {selectedFeedback && (
          <EditPolicyQuery
            fetchListing={fetchListing}
            feedbackData={selectedFeedback}
            pagination={pagination}
            onClose={() => setIsEditModalVisible(false)}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default PolicyQuerys;
