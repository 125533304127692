import React, { useState } from "react";
import Profile from "./Profile";
import UploadMedia from "./UploadMedia";

const Setting = () => {
  const [selectedButton, setSelectedButton] = useState("profile");

  const handleClick = (button) => {
    setSelectedButton(button);
  };

  return (
    <div>
      <div className="flex justify-between">
        <p className="text-[24px] font-[600] leading-[28.8px] text-left font-roboto">
          Setting
        </p>
        <div className="w-fit flex p-[4px] bg-white rounded-[6px]">
          <button
            className={`w-[120px] py-2 px-4 ${selectedButton === "profile"
              ? "bg-green-theme text-white"
              : "bg-white"
              } rounded`}
            onClick={() => handleClick("profile")}
          >
            Profile
          </button>
          {/* <button
            className={` w-[120px]py-2 px-4 ${
              selectedButton === "upload"
                ? "bg-green-theme text-white"
                : "bg-white"
            } rounded`}
            onClick={() => handleClick("upload")}
          >
            Upload Image
          </button> */}
        </div>
      </div>
      {selectedButton === "profile" ? <Profile /> : <UploadMedia />}
    </div>
  );
};

export default Setting;
