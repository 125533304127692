import React, { useMemo } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

export default function CustomAvatar({ firstName, lastName, profile, style={} }) {
    const getInitials = (firstName = "", lastName = "") => {
        const firstInitial = firstName.charAt(0).toUpperCase() || "";
        const lastInitial = lastName.charAt(0).toUpperCase() || "";
        return lastInitial ? `${firstInitial}${lastInitial}` : firstInitial;
    };

    const generateRandomColor = () => {
        const randomHex = () =>
            Math.floor(Math.random() * 256)
                .toString(16)
                .padStart(2, "0");
        return `#${randomHex()}${randomHex()}${randomHex()}`;
    };

    const isLightColor = (hex) => {
        // Extract RGB values from hex
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        // Calculate luminance
        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
        return luminance > 186;
    };

    const backgroundColor = useMemo(() => generateRandomColor(), []);
    const color = useMemo(
        () => (isLightColor(backgroundColor) ? "#000000" : "#FFFFFF"),
        [backgroundColor]
    );
    const initials = getInitials(firstName, lastName);

    return (
        <>
            {profile ? (
                <Avatar src={profile} style={style }/>
            ) : (
                <Avatar
                    style={{ ...style, backgroundColor, color }}
                    icon={!initials && <UserOutlined />}
                >
                    {initials}
                </Avatar>
            )}
        </>
    );
}
