import React, { useState } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Spin } from "antd";
import CustomUpload from "../../../components/CustomUpload";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNomineeDetails,
  updateNomineeDetails,
} from "../../../store/slices/AddClientInfoSlice";
import { relationshipOptions } from "../../../utils/stateCityData";
import dayjs from "dayjs";
import { uploadDoc } from "../../../utils/commonFunction";
import { toast } from "react-toastify";

const { Option } = Select;

const NomineeDetails = ({ onNext, onPrev, onChange }) => {
  const dispatch = useDispatch();
  const nomineeDetailData = useSelector((state) => state?.addClientInfoSlice?.nomineeDetails);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const initialValues = nomineeDetailData
    ? {
      nominees: nomineeDetailData?.nominees.map((nominee) => ({
        ...nominee,
        dob: nominee?.dob ? dayjs(nominee?.dob) : null,
        medicalHistory: nominee?.medicalHistory.map((history) => ({
          ...history,
        })),
      })),
    }
    : {
      nominees: [
        {
          medicalHistory: [
            {
              illness: "",
              medicineName: "",
              document: ""
            },
          ],
          name: "",
          dob: null,
          relation: "",
        },
      ],
    };



  const onFinish = async (values) => {
    setLoading(true);
    const formattedValues = {
      ...values,
      nominees: values?.nominees.map((nominee) => ({
        ...nominee,
        dob: nominee?.dob ?? null,
        medicalHistory: nominee?.medicalHistory.map((history) => ({
          ...history,
        })),
      })),
    };

    let nomineeDetails = await uploadDoc(values?.nominees, 'nominee');


    if (nomineeDetails?.error && nomineeDetails?.message === 'Invalid type or empty payload') {
      setLoading(false);
      toast.error(nomineeDetails?.message);
      return;
    }

    if (nomineeDetails?.error && nomineeDetails?.message === 'Failed to upload nominee documents') {
      setLoading(false);
      toast.error(nomineeDetails?.message);
      return;
    }

    dispatch(updateNomineeDetails({ 'nominees': nomineeDetails }));
    setLoading(false);
    onNext();
  };

  const onPrevBtn = () => {
    const values = form.getFieldsValue();
    const formattedValues = {
      ...values,
      nominees: values?.nominees.map((nominee) => ({
        ...nominee,
        dob: nominee?.dob ? dayjs(nominee?.dob) : null,
        medicalHistory: nominee?.medicalHistory.map((history) => ({
          ...history,
        })),
      })),
    };
    dispatch(updateNomineeDetails(formattedValues));
    onPrev();
  };


  const onResetBtn = () => {
    const defaultValues = {
      nominees: [
        {
          medicalHistory: [
            {
              illness: "",
              medicineName: "",
              document: ""
            },
          ],
          name: "",
          dob: null,
          relation: "",
        },
      ],
    };

    form.setFieldsValue(defaultValues);
    dispatch(resetNomineeDetails());
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6">Nominee Details</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Form.List name="nominees">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, nomineeIndex) => (
                  <div key={key} className="bg-gray-100 p-4 mb-4">
                    {fields.length > 1 && (
                      <div className="flex justify-end">
                        <DeleteOutlined
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => remove(name)}
                        />
                      </div>
                    )}
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Nominee Name"
                          name={[name, "name"]}
                          rules={[
                            { required: true, message: "Enter nominee name" },
                          ]}
                        >
                          <Input
                            placeholder="Enter Name"
                            className="h-12 rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Date of Birth"
                          name={[name, "dob"]}
                          rules={[
                            { required: true, message: "Select date of birth" },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="h-12 w-full rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          label="Relation"
                          name={[name, "relation"]}
                          rules={[{ required: true, message: "Select relation" }]}
                        >
                          <Select
                            placeholder="Select"
                            className="h-12 rounded-md border-gray-300"
                          >
                            {relationshipOptions?.map((relation) => (
                              <Option key={relation} value={relation}>
                                {relation}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.List name={[name, "medicalHistory"]}>
                      {(
                        historyFields,
                        { add: addHistory, remove: removeHistory }
                      ) => (
                        <>
                          {historyFields.map(
                            ({
                              key: historyKey,
                              name: historyName,
                              ...restHistoryField
                            }) => (
                              <div key={historyKey} className="bg-white p-4 mb-4">
                                {historyFields.length > 1 && (
                                  <div className="flex justify-end">
                                    <DeleteOutlined
                                      style={{ color: "red", cursor: "pointer" }}
                                      onClick={() => removeHistory(historyName)}
                                    />
                                  </div>
                                )}
                                <Row gutter={16}>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restHistoryField}
                                      label="Medical Illness"
                                      name={[historyName, "illness"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Enter medical illness",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter medical illness"
                                        className="h-12 rounded-md border-gray-300"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restHistoryField}
                                      label="Medicine Name"
                                      name={[historyName, "medicineName"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Enter medicine name",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter medicine name"
                                        className="h-12 rounded-md border-gray-300"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={24}>
                                    <CustomUpload
                                      fileListProp={form.getFieldValue(["nominees", name, "medicalHistory", historyName, "document"])}
                                      onFileChange={(files) => {
                                        form.setFieldsValue({
                                          nominees: form
                                            .getFieldValue("nominees")
                                            .map((nominee, index) =>
                                              index === name
                                                ? {
                                                  ...nominee,
                                                  medicalHistory: nominee.medicalHistory.map((history, hIndex) =>
                                                    hIndex === historyName ? { ...history, document: files } : history
                                                  ),
                                                }
                                                : nominee
                                            ),
                                        });
                                      }}
                                      label="Upload Document"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                          <div className="flex justify-end mt-2">
                            <p
                              className="text-green-theme cursor-pointer text-sm font-medium"
                              onClick={() => addHistory()}
                            >
                              + Add Medical History
                            </p>
                          </div>
                        </>
                      )}
                    </Form.List>
                  </div>
                ))}
                <div className="flex justify-end mt-2">
                  <p
                    className="text-green-theme cursor-pointer text-sm font-medium mb-[10px]"
                    onClick={() => add({ medicalHistory: [{}] })}
                  >
                    + Add Nominee
                  </p>
                </div>
              </>
            )}
          </Form.List>

          <div className="flex justify-between">
            <div className="flex gap-2">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
                onClick={() => onPrevBtn()}
              >
                {"Previous"}
              </button>

              <button
                type="button"
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 w-[129px] h-[48px]"
                onClick={() => onResetBtn()}
              >
                Reset
              </button>
            </div>

            <button
              type="submit"
              className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]"
            >
              {loading ? "Loading..." : "Next"}
            </button>


          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default NomineeDetails;