import React from "react";
import { Divider } from "antd";
import { ArrowIcon } from "../../utils/SVG";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const formatAddress = (address) => {
  if (!address) return "N/A";
  const { streetAddress, city, state, zip, pinCode } = address;
  return `${streetAddress || ""}, ${city || ""}, ${state || ""}, ${zip || pinCode || ""
    }`
    .replace(/,\s+/g, ", ")
    .trim();
};

const formatMedicalHistory = (history) => {
  if (!history || !Array.isArray(history)) return "N/A";
  return history
    .map((item) => {
      const { illness, medicineName, document } = item;
      return `${illness || "N/A"} - ${medicineName || "N/A"}`.trim();
    })
    .join(", ");
};

const formatBankDetails = (details) => {
  if (!details) return "N/A";
  const { accountNumber, ifscCode } = details;
  return `A/C: ${accountNumber || ""}, IFSC: ${ifscCode || ""}`.trim();
};

const formatDocuments = (documents) => {
  if (!documents) return "N/A";
  const { aadhaarNumber, panNumber } = documents;
  return `Aadhaar: ${aadhaarNumber || ""}, PAN: ${panNumber || ""}`.trim();
};

const ActionPerformed = ({ selectedRecord }) => {

  console.log(selectedRecord, 'selectedRecord')

  const changes = selectedRecord?.changes || {};
  const actionPerformed = selectedRecord?.entity || {};
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleViewProfile = () => {
    const clientId = selectedRecord?.clientId;
    if (
      actionPerformed === "Nominee" ||
      actionPerformed === "FamilyMember" ||
      actionPerformed === "Client" ||
      actionPerformed === "MedicalHistory"
    ) {
      navigate("/manage-clients/edit", { state: { id: clientId } });
    } else if (actionPerformed === "Policy") {
      navigate("/manage-clients/edit", {
        state: { id: clientId, selectedButton: "policy" },
      });
    } else if (actionPerformed === "UserQuery") {
      navigate("/feedback", { state: { selectedButton: "policyQuery" } });
    } else {
      return;
    }
  };

  return (
    <div>
      <p className="text-[24px] font-[700] text-center font-roboto mb-[16px]">
        Action performed
      </p>
      <p className="text-[14px] font-[700] font-roboto mb-[20px]">
        Action: Client details edit
      </p>

      {selectedRecord?.changes === "Nothing updated" ? (
        <p className="text-[24px] py-4 font-[400] text-[#969696] font-roboto text-center">
          Nothing Updated, No change found!
        </p>
      ) : (
        <div className="flex justify-between  gap-[25px]">
          {/* Left: Old Data */}
          <div className="w-full">
            {Object.keys(changes).map((key) => {
              const old = changes[key]?.old || changes[key]?.previous;
              let formattedOld = old;
              console.log(formattedOld, 'formattedOld', changes)
              if (key.includes("Address")) formattedOld = formatAddress(old);
              if (key === "bankDetails") formattedOld = formatBankDetails(old);
              if (key === "documents") formattedOld = formatDocuments(old);
              if (key === "policyStart") formattedOld = formatDate(old);
              if (key === "maturityEndDate") formattedOld = formatDate(old);
              if (key === "medicalHistory") formattedOld = formatMedicalHistory(old);

              return (
                <div key={key}>
                  <div className="flex justify-between gap-[20px]">
                    <p className="text-[14px] font-[400] text-[#969696] font-roboto max-w-[130px]">
                      {key}
                    </p>
                    <p className="text-[14px] font-[400] font-roboto text-end">
                      {typeof formattedOld === "object"
                        ? JSON.stringify(formattedOld)
                        : formattedOld || "N/A"}
                    </p>
                  </div>
                  <Divider
                    style={{
                      margin: "16px 0px 16px 0px",
                      padding: 0,
                      width: "100%",
                      border: "1px solid #D9D9D9",
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className="flex justify-center items-center">
            <ArrowIcon />
          </div>

          {/* Right: New Data */}
          <div className="w-full">
            {Object.keys(changes).map((key) => {
              const newValue = changes[key]?.new || changes[key]?.updated;

              let formattedNew = newValue;
              if (key.includes("Address"))
                formattedNew = formatAddress(newValue);
              if (key === "bankDetails")
                formattedNew = formatBankDetails(newValue);
              if (key === "documents") formattedNew = formatDocuments(newValue);
              if (key === "policyStart") formattedNew = formatDate(newValue);
              if (key === "maturityEndDate")
                formattedNew = formatDate(newValue);
              if (key === "medicalHistory")
                formattedNew = formatMedicalHistory(newValue);
              if (key === "dob") formattedNew = dayjs(newValue).format('DD-MM-YYYY');
              return (
                <div key={key}>
                  <div className="flex justify-between gap-[20px]">
                    <p className="text-[14px] font-[400] text-[#969696] font-roboto max-w-[130px]">
                      {key}
                    </p>
                    <p className="text-[14px] font-[400] font-roboto text-end">
                      {typeof formattedNew === "object"
                        ? JSON.stringify(formattedNew)
                        : formattedNew || "N/A"}
                    </p>
                  </div>
                  <Divider
                    style={{
                      margin: "16px 0px 16px 0px",
                      padding: 0,
                      width: "100%",
                      border: "1px solid #D9D9D9",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <button
          className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark"
          onClick={() => handleViewProfile()}
        >
          View profile
        </button>
      </div>
    </div>
  );
};

export default ActionPerformed;
