import React, { useMemo, useState } from "react";
import { Form, Input, Row, Col, Spin } from "antd";
import { AvatarEditIcon, TableEditIcon } from "../../utils/SVG";
import { useDispatch, useSelector } from "react-redux";
import { editRole, changePassword } from "../../service/Collections";
import { toast } from "react-toastify";
import { loginStep } from "../../store/slices/loginSlice";
import CustomAvatar from "../../components/CustomAvatar";
import { uploadDocOnS3 } from "../../aws/commonFunc";

const Profile = () => {
  const user = useSelector((state) => state?.loginSlice?.data);
  const token = useSelector((state) => state?.loginSlice?.token);
  const [avatarImage, setAvatarImage] = useState(null);
  const [isProfileDetailsDisabled, setIsProfileDetailsDisabled] =
    useState(true);
  const [isPasswordDetailsDisabled, setIsPasswordDetailsDisabled] =
    useState(true);
  const dispatch = useDispatch();
  const [avatarLoading, setAvatarLoading] = useState(false);
  const profileFieldsInitialValues = useMemo(() => {
    if (user) {
      return {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phoneNumber: user?.phone_number,
      };
    } else {
      return {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      };
    }
  }, [user]);

  const onFinish = async (values) => {
    const payload = {
      currentPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    };
    console.log(payload);
    const res = await changePassword(payload);
    if (res?.status === 200) {
      toast.info(res?.message);
      handleEditPasswordClick();
    } else {
      toast.error(res?.message);
      console.log(res?.message);
    }
  };

  const handleFileChange = async (file) => {
    setAvatarLoading(true);
    await uploadDocOnS3(file)
      .then(async(location) => {
        setAvatarImage(location);
        const updatedData = {
          ...user, 
          profile: location, 
        };
        const res = await editRole(user?._id, updatedData);
        if (res?.status === 200) {
          dispatch(loginStep({ data: updatedData, token: token }));
          handleEditProfileClick();
        } else {
          toast.error(res?.message || "Failed to update profile");
        }
        setAvatarLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading file:", error.message);
        setAvatarImage(null);
        setAvatarLoading(false);
        toast.error(error?.message || "Error uploading file");
      });
  };

  const handleEditProfileClick = () => {
    setIsProfileDetailsDisabled((prevState) => !prevState);
  };

  const handleEditPasswordClick = () => {
    setIsPasswordDetailsDisabled((prevState) => !prevState);
  };

  const updateProfileDetails = async (values) => {
    const updatedData = {
      ...user,
      firstName: values.firstName,
      lastName: values.lastName,
      phone_number: values.phoneNumber,
      email: values.email,
    };
    const res = await editRole(user?._id, updatedData);
    if (res?.status === 200) {
      toast.info(res?.message);
      handleEditProfileClick();
      dispatch(loginStep({ data: updatedData, token: token }));
    } else {
      toast.error(res?.message);
    }
  };

  const profileFields = [
    {
      label: "First Name",
      name: "firstName",
      placeholder: "First Name",
      rules: [{ required: true, message: "Please enter your first name" }],
    },
    {
      label: "Last Name",
      name: "lastName",
      placeholder: "Last Name",
      rules: [{ required: true, message: "Please enter your last name" }],
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      placeholder: "Phone Number",
      rules: [{ required: true, message: "Please enter your phone number" }],
    },
    {
      label: "Email Address",
      name: "email",
      placeholder: "Email Address",
      readOnly: true,
      rules: [{ required: true, message: "Please enter your email" }],
    },
  ];

  const passwordFields = [
    {
      label: "Old Password",
      name: "oldPassword",
      placeholder: "Enter old password",
      rules: [{ required: true, message: "Please enter your old password" }],
    },
    {
      label: "New Password",
      name: "newPassword",
      placeholder: "Enter new password",
      rules: [
        { required: true, message: "Please enter your new password" },
        {
          min: 6,
          message: "Password must be at least 6 characters",
        },
        {
          pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
          message:
            "Password must contain at least one uppercase letter, one number, and one special character",
        },
      ],
    },
    {
      label: "Confirm New Password",
      name: "confirmNewPassword",
      placeholder: "Enter confirm new password",
      rules: [
        { required: true, message: "Please confirm your new password" },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("newPassword") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Confirm Password does not match the New Password")
            );
          },
        }),
      ],
    },
  ];

  return (
    <div className="p-4">
      <div className="bg-white rounded-[20px] p-[16px] mb-8">
        {/* Profile Details Section */}
        <div className="flex justify-between">
          <h2 className="text-[24px] font-[500] mb-[16px] text-left font-roboto text-black">
            Profile Details
          </h2>
          <div className="cursor-pointer" onClick={handleEditProfileClick}>
            <TableEditIcon />
          </div>
        </div>
        <Form
          layout="vertical"
          onFinish={updateProfileDetails}
          initialValues={profileFieldsInitialValues}
        >
          <div className="mb-[24px] relative w-[fit-content]">
            <Spin spinning={avatarLoading}>
              <CustomAvatar
                style={{ width: "150px", height: "150px", fontSize: "42px" }}
                firstName={user?.firstName}
                lastName={user?.lastName}
                profile={avatarImage ?? user?.profile}
              />
              {!isProfileDetailsDisabled && (
                <div
                  className="absolute bottom-[13px] right-[-3px] cursor-pointer"
                  onClick={() =>
                    document.getElementById("avatarUpload").click()
                  }
                >
                  <AvatarEditIcon />
                </div>
              )}
              <input
                id="avatarUpload"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => handleFileChange(e.target.files[0])}
                disabled={isProfileDetailsDisabled}
              />
            </Spin>
          </div>
          <Row gutter={16}>
            {profileFields.map((field, index) => (
              <Col span={12} key={index}>
                <Form.Item
                  label={field.label}
                  name={field.name}
                  rules={field.rules}
                >
                  <Input
                    placeholder={field.placeholder}
                    className="h-[48px]"
                    disabled={field?.readOnly || isProfileDetailsDisabled}
                  />
                </Form.Item>
              </Col>
            ))}

            <Row className="w-full flex justify-end">
              {!isProfileDetailsDisabled && (
                <div className="float-right">
                  <button className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
                    Update
                  </button>
                </div>
              )}
            </Row>
          </Row>
        </Form>
      </div>

      {/* Change Password Section */}
      <div className="bg-white rounded-[20px] p-[16px]">
        <div className="flex justify-between">
          <h2 className="text-[24px] font-[500] mb-[16px] text-left font-roboto text-black">
            Change Password
          </h2>
          <div className="cursor-pointer" onClick={handleEditPasswordClick}>
            <TableEditIcon />
          </div>
        </div>
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            {passwordFields.map((field, index) => (
              <Col span={12} key={index}>
                <Form.Item
                  label={field.label}
                  name={field.name}
                  rules={field.rules}
                >
                  <Input.Password
                    placeholder={field.placeholder}
                    className="h-[48px]"
                    disabled={isPasswordDetailsDisabled}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Row className="w-full flex justify-end">
            {!isPasswordDetailsDisabled && (
              <div className="float-right">
                <button className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
                  Update
                </button>
              </div>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
