import React from "react";
import { Modal } from "antd";

const DeleteModal = ({ isVisible, onClose, onDelete, isLogout }) => {
  return (
    <Modal
      prefixCls="custom-modal"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
      width={400}
      closable={false}
      bodyStyle={{ padding: 0, borderRadius: "8px", overflow: "hidden" }}
      title={null}
    >
      <div className="text-center">
        <h2 className="text-[24px] font-[700] text-black">
          {isLogout ? "Logout Account" : "Are you sure?"}
        </h2>
        <p className="text-[16px] text-gray-600 mt-[8px]">
          {isLogout
            ? "Are you sure do you want to logout?"
            : "Do you want to delete this item?"}
        </p>

        <div className="flex justify-center gap-[16px] mt-[24px]">
          <button
            className="bg-gray-200 text-black py-[8px] px-[24px] rounded-md hover:bg-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white py-[8px] px-[24px] rounded-md hover:bg-red-600"
            onClick={onDelete}
          >
            {isLogout ? "Logout" : "Delete"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
