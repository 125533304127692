import React from "react";
import Header from "../components/Header";
import HeaderDrawer from "../components/Sidebar"; 
import { Divider } from "antd";
import { Outlet } from "react-router-dom";

const PrivateLayout = () => {
  return (
    <div className="flex flex-col gap-[32px] h-[100vh] py-[20px] bg-private-layout-theme">
      <div className="flex-shrink-0">
        {/* This div applies flex and justify-between on screens less than 1200px */}
        <div className="flex justify-between lg:hidden items-center">
          <HeaderDrawer />
          <Header />
        </div>
        
        {/* This div will only be visible on larger screens */}
        <div className="lg:block hidden">
          <Header />
        </div>

        <div className="px-[20px]">
          <Divider
            style={{
              borderColor: "#696969",
              margin: "0px",
            }}
          />
        </div>
      </div>

      <div className="flex-grow overflow-auto px-[20px] py-[20px] bg-private-layout-theme">
        <Outlet />
      </div>
    </div>
  );
};

export default PrivateLayout;
