import React, { useEffect, useState } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Button } from "antd";
import {
  createPolicy,
  getFamilyMember,
  getNomineeMember,
} from "../../../../service/Collections";
import { getErrorMessage } from "../../../../utils/errorMessage";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CustomUpload from "../../../../components/CustomUpload";
import { uploadDocOnS3 } from "../../../../aws/commonFunc";

const { Option } = Select;

const AddPolicy = ({ onClose, fetchListing }) => {
  const [form] = Form.useForm();
  const [policyOwner, setPolicyOwner] = useState("Self");
  const clienId = useSelector((state) => state?.selectedClient?.client?.[0]);
  const [isPolicyStartEnabled, setIsPolicyStartEnabled] = useState(false);
  const [isPptEnabled, setIsPptEnabled] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [nomineeMembers, setNomineeMembers] = useState([]);

  const resetState = () => {
    setIsPolicyStartEnabled(false);
    setIsPptEnabled(false);
    setPolicyOwner("Self");
  };
  const handleFileUpload = async (fileList) => {
    try {
      const uploadedFiles = await Promise.all(
        fileList.map(async (file) => {
          if (file.response?.url || file.url) {
            return { name: file.name, url: file.response?.url || file.url };
          }
          const uploadedUrl = await uploadDocOnS3(file.originFileObj);
          if (uploadedUrl) {
            return { name: file.name, url: uploadedUrl };
          } else {
            throw new Error("File upload failed");
          }
        })
      );
      form.setFieldsValue({ document: uploadedFiles });
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Failed to upload documents.");
    }
  };
  const onFinish = async (values) => {
    const payload = {
      policyNumber: values?.policyNo,
      year: values?.year?.year(),
      policyOwner: values?.policyOwner.toLowerCase(),
      type: values?.type.toLowerCase(),
      premiumAmount: values?.premiumAmt,
      proposer: values?.proposer,
      sumInsured: values?.sumInsured,
      premiumFrequency: values?.premiumFrequency
        .replace("_", " ")
        .toLowerCase(),
      planName: values?.planName,
      pt: values?.pt,
      ppt: values?.ppt,
      policyStart: dayjs(values?.policyStart).format("YYYY-MM-DD"),
      maturityEndDate: dayjs(values?.endDate).format("YYYY-MM-DD"),
      clientId: clienId,
      document: values?.document?.map((doc) => doc.url).join(", ") || "",
      companyName: values?.companyName || "", 
      email: values?.email || "",
      phoneNumber: values?.phoneNumber || "",
      nomineeId: values?.nominee || "",
      relation: values?.relation || "",
      ...(values?.policyOwner === "Family" && {
        familyMemberOwner: values?.member,
      }),
    };
    console.log(payload, values, "payload");
    let res = await createPolicy(payload);
    if (res.status === 200) {
      fetchListing({
        current: 1,
        pageSize: 20,
        total: 0,
      });
      toast.info(res.message);
      form.resetFields();
      resetState();
      onClose();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    console.log(changedValues, allValues, "onValuesChange");
    if (changedValues.policyOwner) {
      setPolicyOwner(changedValues.policyOwner);
    }
    if (changedValues.year) {
      setIsPolicyStartEnabled(true);
      form.setFieldsValue({
        policyStart: dayjs(`${changedValues.year?.$y}-01-01`),
      });
    }
    if (allValues.pt) {
      setIsPptEnabled(true);
    }
    if (!allValues.pt) {
      setIsPptEnabled(false);
    }

    if (changedValues?.nominee) {
      let selectedNominee = nomineeMembers?.find(
        (el) => el?._id === changedValues?.nominee
      );
      form.setFieldsValue({ relation: selectedNominee?.relation });
    }

    const { policyStart, pt } = allValues;
    if (policyStart && pt) {
      const startDate = dayjs(policyStart);
      const endDate = startDate.add(Number(pt), "year");
      form.setFieldsValue({ endDate });
    }
  };

  const fetchFamilyMembers = async () => {
    const res = await getFamilyMember(clienId?._id);
    if (res?.status === 200) {
      setFamilyMembers(res?.data);
    } else {
      setFamilyMembers([]);
    }
  };

  const fetchNomineeMembers = async () => {
    const res = await getNomineeMember(clienId?._id);
    if (res?.status === 200) {
      setNomineeMembers(res?.data);
    } else {
      setNomineeMembers([]);
    }
  };

  const formFields = [
    {
      label: "Policy No.",
      name: "policyNo",
      type: "input",
      placeholder: "Enter Policy No.",
      colSpan: 12,
      rules: [{ required: true, message: "Please enter policy number" }],
    },
    {
      label: "Year",
      name: "year",
      type: "datePicker",
      placeholder: "Select Year",
      colSpan: 12,
      rules: [{ required: true, message: "Please select year" }],
      picker: "year",
    },
    {
      label: "Company Name",
      name: "companyName",
      type: "input",
      placeholder: "Enter Company Name",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter company name" }],
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      placeholder: "Enter Email",
      colSpan: 8,
      rules: [
        { required: true, message: "Please enter email" },
        {
          type: "email",
          message: "Please enter a valid email address",
        },
      ],
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "input",
      placeholder: "Enter Phone Number",
      colSpan: 8,
      rules: [
        { required: true, message: "Please enter phone number" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Please enter a valid 10-digit phone number",
        },
      ],
    },
    {
      label: "Policy Owner/Insured",
      name: "policyOwner",
      type: "select",
      placeholder: "Select",
      colSpan: 8,
      rules: [{ required: true, message: "Please select policy owner" }],
      options: ["Self", "Family"],
      initialValue: "Self",
    },
    ...(policyOwner === "Family"
      ? [
          {
            label: "Select Member",
            name: "member",
            type: "select",
            placeholder: "Select the family member",
            colSpan: 8,
            rules: [
              { required: true, message: "Please select a family member" },
            ],
            options: familyMembers?.map((member) => ({
              value: member?._id,
              label: member?.name,
              key: member?._id,
            })),
          },
        ]
      : []),
    {
      label: "Type",
      name: "type",
      type: "select",
      placeholder: "Select type",
      colSpan: 8,
      rules: [{ required: true, message: "Please select type" }],
      options: [
        "Life",
        "Health",
        "Motor",
        "ULIP",
        "Personal Accident",
        "Travel",
        "Fire",
        "Marine",
        "Other",
      ],
    },
    {
      label: "Premium Amount",
      name: "premiumAmt",
      type: "input",
      placeholder: "Amount",
      colSpan: 8,
      rules: [
        { required: true, message: "Please enter premium amount" },
        {
          pattern: /^[0-9]+$/,
          message: "Please enter number only",
        },
      ],
    },
    {
      label: "Proposer",
      name: "proposer",
      type: "input",
      placeholder: "Enter Proposer Name",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter proposer name" }],
    },
    {
      label: "Sum Insured",
      name: "sumInsured",
      type: "input",
      placeholder: "Amount",
      colSpan: 8,
      rules: [
        { required: true, message: "Please enter sum insured" },
        {
          pattern: /^[0-9]+$/,
          message: "Please enter number only",
        },
      ],
    },
    {
      label: "Premium Frequency",
      name: "premiumFrequency",
      type: "select",
      placeholder: "Select",
      colSpan: 8,
      rules: [{ required: true, message: "Please select premium frequency" }],
      options: ["Monthly", "Quarterly", "Annual"],
      initialValue: "Annual",
    },
    {
      label: "Plan Name",
      name: "planName",
      type: "input",
      placeholder: "Enter Plan Name",
      colSpan: 8,
      rules: [{ required: true, message: "Please enter plan name" }],
    },
    {
      label: "PT",
      name: "pt",
      type: "input",
      placeholder: "Enter PT",
      colSpan: 8,
      rules: [
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error("This field is required"));
            }
            if (!/^[0-9]+$/.test(value)) {
              return Promise.reject(new Error("Please enter number only"));
            }
            if (value < 0 || value > 50) {
              return Promise.reject(
                new Error("Value must be between 0 and 50")
              );
            }
            return Promise.resolve();
          },
        },
      ],
    },
    {
      label: "PPT",
      name: "ppt",
      type: "input",
      placeholder: "Enter PPT",
      colSpan: 8,
      disabled: !isPptEnabled,
      rules: [
        {
          validator: (_, value, callback) => {
            const ptValue = form.getFieldValue("pt");
            if (!value) {
              return Promise.reject(new Error("This field is required"));
            }
            if (!/^[0-9]+$/.test(value)) {
              return Promise.reject(new Error("Please enter number only"));
            }
            if (value < 0 || value > 50) {
              return Promise.reject(
                new Error("Value must be between 0 and 50")
              );
            }
            if (ptValue && Number(value) > Number(ptValue)) {
              return Promise.reject(new Error("PPT cannot be greater than PT"));
            }
            return Promise.resolve();
          },
        },
      ],
    },

    {
      label: "Nominee",
      name: "nominee",
      type: "select",
      placeholder: "Select the nominee",
      colSpan: policyOwner === "Family" ? 8 : 12,
      rules: [{ required: true, message: "Please select nominee" }],
      options: nomineeMembers?.map((member) => ({
        value: member?._id,
        label: member?.name,
        key: member?._id,
      })),
    },

    {
      label: "Nominee Relation",
      name: "relation",
      type: "input",
      placeholder: "Enter Relation",
      colSpan: policyOwner === "Family" ? 8 : 12,
      disabled: true,
    },

    {
      label: "Policy Start",
      name: "policyStart",
      type: "datePicker",
      placeholder: "Select Date",
      colSpan: 12,
      disabled: !isPolicyStartEnabled,
      rules: [{ required: true, message: "Please select policy start date" }],
      disabledDate: (current) => {
        const selectedYear = form.getFieldValue("year")?.year();
        return selectedYear ? current.year() !== selectedYear : false; // Disable dates not in the selected year
      },
    },
    {
      label: "Maturity/End Date",
      name: "endDate",
      type: "datePicker",
      placeholder: "Select Date",
      colSpan: 12,
      disabled: true,
      rules: [{ required: true, message: "Please select end date" }],
    },
  ];

  useEffect(() => {
    if (clienId) {
      fetchFamilyMembers();
      fetchNomineeMembers();
    }
  }, [clienId]);

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="flex flex-col gap-[15px]">
        <p className="text-[32px] font-[700] text-center text-black font-roboto">
          Add Policy
        </p>
        <p className="text-[24px] font-[400] text-center text-black font-roboto">
          Enter the client policy details to add the new policy
        </p>
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        className="mt-6"
        initialValues={{ nominee: null, type: null, member: null }}
      >
        <Row gutter={16}>
          {formFields.map((field, index) => (
            <Col span={field.colSpan} key={index}>
              <Form.Item
                label={field.label}
                name={field.name}
                rules={field.rules}
                className="text-[12px] font-[400] text-left font-roboto text-black"
                initialValue={field.initialValue || ""}
              >
                {field?.type === "input" && (
                  <Input
                    placeholder={field.placeholder}
                    className="h-[48px]"
                    disabled={field.disabled || false}
                  />
                )}
                {field?.type === "select" && (
                  <Select className="h-[48px]" placeholder={field?.placeholder}>
                    {field?.options.map((option) =>
                      typeof option === "object" ? (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ) : (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      )
                    )}
                  </Select>
                )}
                {field?.type === "datePicker" && (
                  <DatePicker
                    className="h-[48px] w-full"
                    placeholder={field.placeholder}
                    disabled={field.disabled || false}
                    picker={field.picker || "date"}
                    format={field.picker === "year" ? "YYYY" : undefined}
                    disabledDate={field?.disabledDate}
                  />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Upload Document"
              name="document"
              valuePropName="fileList"
            >
              <CustomUpload
                multiple
                maxCount={5}
                onFileChange={handleFileUpload}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end mt-6">
          <Button
            type="primary"
            htmlType="submit"
            className="bg-green-theme text-white h-[48px] w-[214px] rounded-md"
          >
            Add Policy
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddPolicy;
