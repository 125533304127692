import React from "react";
import { Avatar, Rate } from "antd";

const ClientFeedbackModal = ({ feedbackData }) => {
  console.log(feedbackData,'feedbackDatafeedbackDatafeedbackData')
  return (
    <div className="flex min-h-[308px] flex-col gap-[16px]">
      <p className="font-roboto text-2xl font-bold leading-7 text-center">
        Client Feedback
      </p>

      <div className="flex gap-[24px] flex-col ">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-[6px]">
            <p class="font-roboto text-[12px] text-[#696969] font-normal leading-4 text-left">
              Name
            </p>
            <div className="flex gap-[4px] items-center">
              <Avatar src={feedbackData?.user?.profile} />
              <p className="font-roboto text-[12px] font-[400] leading-5 text-left text-black">
                {`${feedbackData?.user?.firstName} ${feedbackData?.user?.lastName}`}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-[6px]">
            <p class="font-roboto text-xs font-normal leading-4 text-left text-[#696969]">
              Client ID
            </p>
            <p className="font-roboto text-base font-semibold leading-5 text-left">
              {feedbackData?.userId}
            </p>
          </div>

          <div className="flex flex-col gap-[6px]">
            <p class="font-roboto text-xs font-normal leading-4 text-left text-[#696969]">
              Date
            </p>
            <p className="font-roboto text-base font-semibold leading-5 text-left">
              {feedbackData?.date}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-[12px]">
          <p className="text-[20px] font-[600] leading-[16.8px] text-left text-black font-roboto">
            Request a Feature
          </p>
          <Rate value={feedbackData?.rating} disabled />
        </div>

        <p className="text-[14px] font-[400] leading-[21px] text-left text-black font-roboto">
          {feedbackData?.note}
        </p>
      </div>
    </div>
  );
};

export default ClientFeedbackModal;
