import React, { useState } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { relationshipOptions } from "../../../../utils/stateCityData";
import dayjs from "dayjs";
import { uploadDoc } from "../../../../utils/commonFunction";
import { toast } from "react-toastify";
import CustomUpload from "../../../../components/CustomUpload";
import { addNominee } from "../../../../service/Collections";
import { useSelector } from "react-redux";
import { uploadDocOnS3 } from "../../../../aws/commonFunc";

const { Option } = Select;

const AddNominee = ({ onClose, fetching }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const clientData = useSelector((state) => state?.selectedClient?.client?.[0]);

  const handleFileUpload = async (fileList) => {
    try {
      const uploadedFiles = [];
      for (const file of fileList) {
        const url =
          file.response?.url ||
          file.url ||
          (await uploadDocOnS3(file.originFileObj));
        if (url) {
          uploadedFiles.push({ name: file.name, url });
        } else {
          throw new Error("File upload failed");
        }
      }

      const medicalHistory =
        form.getFieldValue("nominees")?.[0]?.medicalHistory || [];
      form.setFieldsValue({
        nominees: [
          {
            ...form.getFieldValue("nominees")?.[0],
            medicalHistory: medicalHistory.map((history) => ({
              ...history,
              document: uploadedFiles,
            })),
          },
        ],
      });
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Failed to upload documents.");
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const nominee = values?.nominees?.[0]
        ? {
          ...values?.nominees?.[0],
          dateOfBirth: values?.nominees?.[0]?.dateOfBirth
            ? dayjs(values?.nominees?.[0]?.dateOfBirth).toISOString()
            : null,
        }
        : {};

      const nomineeDetails = await uploadDoc([nominee], "nominee");
      if (nomineeDetails?.error) {
        setLoading(false);
        toast.error(nomineeDetails?.message);
        return;
      }

      const medicalHistory =
        values?.nominees?.[0]?.medicalHistory?.map((history) => ({
          illness: history?.illness,
          medicineName: history?.medicineName,
          document: history?.document?.map((doc) => doc?.url).join(", ") || "",
        })) || [];

      const formattedValues = {
        ...nominee,
        clientId: clientData?._id,
        medicalHistory,
      };

      const res = await addNominee(formattedValues);
      if (res?.status === 200) {
        toast.info(res.message);
        form.resetFields();
        onClose();
        fetching();
      } else {
        toast.error(res?.message || "Failed to add nominee.");
      }
    } catch (error) {
      console.error("Error in onFinish:", error);
      toast.error(error?.message || "Failed to add nominee.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Add Nominee Details
      </h2>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.List name="nominees" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, nomineeIndex) => (
                  <div key={key} className="bg-gray-100 p-4 mb-4">
                    {fields.length > 1 && (
                      <div className="flex justify-end">
                        <DeleteOutlined
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => remove(name)}
                        />
                      </div>
                    )}
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Nominee Name"
                          name={[name, "name"]}
                          rules={[
                            { required: true, message: "Enter nominee name" },
                          ]}
                        >
                          <Input
                            placeholder="Enter Name"
                            className="h-12 rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Date of Birth"
                          name={[name, "dateOfBirth"]}
                          rules={[
                            { required: true, message: "Select date of birth" },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="h-12 w-full rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          label="Relation"
                          name={[name, "relation"]}
                          rules={[
                            { required: true, message: "Select relation" },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            className="h-12 rounded-md border-gray-300"
                          >
                            {relationshipOptions?.map((relation) => (
                              <Option key={relation} value={relation}>
                                {relation}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.List
                      name={[name, "medicalHistory"]}
                      initialValue={[{}]}
                    >
                      {(
                        historyFields,
                        { add: addHistory, remove: removeHistory }
                      ) => (
                        <>
                          {historyFields.map(
                            ({
                              key: historyKey,
                              name: historyName,
                              ...restHistoryField
                            }) => (
                              <div
                                key={historyKey}
                                className="bg-white p-4 mb-4"
                              >
                                {historyFields.length > 1 && (
                                  <div className="flex justify-end">
                                    <DeleteOutlined
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => removeHistory(historyName)}
                                    />
                                  </div>
                                )}
                                <Row gutter={16}>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restHistoryField}
                                      label="Medical Illness"
                                      name={[historyName, "illness"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Enter medical illness",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter medical illness"
                                        className="h-12 rounded-md border-gray-300"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restHistoryField}
                                      label="Medicine Name"
                                      name={[historyName, "medicineName"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Enter medicine name",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter medicine name"
                                        className="h-12 rounded-md border-gray-300"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col span={24}>
                                    <CustomUpload
                                      multiple
                                      maxCount={5}
                                      onFileChange={handleFileUpload}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                          {/* <div className="flex justify-end mt-2">
                            <p
                              className="text-green-theme cursor-pointer text-sm font-medium"
                              onClick={() => addHistory()}
                            >
                              + Add Medical History
                            </p>
                          </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                ))}
                {/* <div className="flex justify-end mt-2">
                  <p
                    className="text-green-theme cursor-pointer text-sm font-medium mb-[10px]"
                    onClick={() => add({ medicalHistory: [{}] })}
                  >
                    + Add Nominee
                  </p>
                </div> */}
              </>
            )}
          </Form.List>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]"
            >
              {loading ? "Loading..." : "Add Nominee"}
            </button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default AddNominee;
