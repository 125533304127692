import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { Option } from "antd/es/mentions";
import { Select } from "antd";
import Search from "antd/es/transfer/search";
import { listingHistory } from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { toast } from "react-toastify";
import CustomAvatar from "../../components/CustomAvatar";
import dayjs from "dayjs";
import { debounce } from "lodash";
import CustomModal from "../../components/CustomModal";
import ActionPerformed from "./ActionPerformed";
import { useNavigate } from "react-router-dom";

let defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const History = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [roleValue, setRoleValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
const navigate = useNavigate();
  const handleModalClick = (data) => {
    setOpenModal(true);
    setSelectedRecord(data);
  };
  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      key: "key",
      render: (text) => (
        <span>{(pagination?.current - 1) * pagination?.pageSize + text}</span>
      ),
    },
    {
      title: "Employee",
      dataIndex: "adminData",
      key: "adminData",
      width: 200,
      render: (adminData) => (
        <div className="flex items-center bg-[#F2F2F2] rounded-[100px] p-[10px] gap-[10px]">
          <CustomAvatar
            firstName={adminData?.firstName}
            lastName={adminData?.lastName}
          />
          <div>
            <p className="text-black text-[12px] font-medium w-[120px] truncate">
              {`${adminData?.firstName || ""} ${adminData?.lastName || ""}`}
            </p>
            <p className="text-[#696969] text-[10px] font-medium	">
              {adminData?.role}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "clientData",
      key: "clientData",
      render: (clientData) => (
        <div className="flex items-center">
          {clientData?.profile ? (
            <img
              src={clientData?.profile}
              alt={clientData?.firstName}
              className="w-8 h-8 rounded-full"
            />
          ) : (
            clientData?.firstName && (
              <CustomAvatar
                firstName={clientData?.firstName}
                lastName={clientData?.lastName}
              />
            )
          )}
          <span className="ml-2">
            {clientData?.firstName} {clientData?.lastName}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action) => <span className="capitalize">{action}</span>,
    },
    {
      title: "Action performed",
      dataIndex: "entity",
      key: "entity",
      align: "center",
      render: (entity) => <span className="capitalize">{entity}</span>,
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (date) => <span>{dayjs(date).format("M/D/YYYY")}</span>,
    },
    {
      title: "Link",
      dataIndex: "action",
      key: "action",
      render: (action, data) => (
        <div className="flex space-x-2">
          {action === "create" ? (
            <p
              className="cursor-pointer text-green-theme"
              onClick={() => handleLinkClicked(data)}
            >
              Link
            </p>
          ) : (
            // Open the modal of view, Go with the new desgin which in figma!
            <p
              className="cursor-pointer text-green-theme"
              onClick={() => handleModalClick(data)}
            >
              View
            </p>
          )}
        </div>
      ),
    },
  ];
  const handleLinkClicked = (data) => {
    // alert(JSON.stringify(payload));
    const clientId = data?.clientId;
    if (
      data?.entity === "Nominee" ||
      data?.entity === "FamilyMember" ||
      data?.entity === "Client" ||
      data?.entity === "MedicalHistory"
    ) {
      navigate("/manage-clients/edit", { state: { id: clientId } });
    } else if (data?.entity === "Policy") {
      navigate("/manage-clients/edit", {
        state: { id: clientId, selectedButton: "policy" },
      });
    } else if (data?.entity === "UserQuery") {
      navigate("/feedback", { state: { selectedButton: "policyQuery" } });
    } else {
      return;
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setPagination(defaultPagination);
    }, 1000),
    [roleValue]
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleRoleChange = (value) => {
    setPagination(defaultPagination);
    if (value === "All") {
      setRoleValue("");
    } else {
      setRoleValue(value);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const fetchListing = async () => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let params = new URLSearchParams();
    params.append("perPage", pageSize);
    params.append("page", current);

    if (searchValue) params.append("search", searchValue);
    if (roleValue) params.append("type", roleValue);

    let res = await listingHistory(params);
    if (res?.status === 200) {
      let addingSno = res?.data?.historyRecords?.map((u, idx) => ({
        key: idx + 1,
        ...u,
      }));
      setTableData(addingSno);
      setTotalPage(res?.data?.totalPages);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
    setLoading(false);
  };
  console.log(searchValue, "searchValue");
  useEffect(() => {
    fetchListing();
  }, [pagination, searchValue, roleValue]);

  return (
    <div>
      <CustomTable
        columns={columns}
        data={tableData}
        heading="History"
        paginationProps={{
          ...pagination,
          total: totalPage * defaultPagination?.pageSize,
        }}
        onChange={handleTableChange}
        loading={loading}
      >
        <Search
          placeholder="Search..."
          onChange={handleSearch}
          size="large"
          prefixCls="custom-search"
        />
        <Select
          defaultValue={"All"}
          value={roleValue || undefined}
          onChange={handleRoleChange}
          className="h-[48px] w-[150px]"
          placeholder="Filter"
        >
          <Option value="All">All</Option>
          <Option value="Nominee">Nominee</Option>
          <Option value="FamilyMember">Family Member</Option>
          <Option value="Client">Client</Option>
          <Option value="UserQuery">User Query</Option>
          <Option value="MedicalHistory">Medical History</Option>
          <Option value="Policy">Policy</Option>
        </Select>
      </CustomTable>

      <CustomModal
        width={789}
        height={344}
        isVisible={openModal}
        onClose={() => setOpenModal(false)}
      >
        <ActionPerformed selectedRecord={selectedRecord} />
      </CustomModal>
    </div>
  );
};

export default History;
