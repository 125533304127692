import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../components/CustomTable";
import {
  FilterDropdownIcon,
  TableDeleteIcon,
  TableEditIcon,
} from "../../../../utils/SVG";
import { PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import Search from "antd/es/transfer/search";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "../../../../components/CustomDeleteModal";
import CustomModal from "../../../../components/CustomModal";
import AddPolicy from "./AddPolicy";
import EditPolicy from "./EditPolicy";
import {
  deletePolicy,
  getPolicyList,
  updatePolicyStatus,
} from "../../../../service/Collections";
import { getErrorMessage } from "../../../../utils/errorMessage";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const { Option } = Select;

let defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const ClientPolicy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.loginSlice?.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const fetchListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    const { current, pageSize } = pagination;
    params.append("limit", pageSize);
    params.append("page", current);
    if (searchValue) params.append("search", searchValue);
    let res = await getPolicyList(location?.state?.id, params);
    if (res?.status === 200) {
      const mappedData = res?.data?.policies?.map((policy, index) => ({
        ...policy,
        key: (pagination.current - 1) * pagination.pageSize + index + 1,
      }));
      setTableData(mappedData);
      setTotalPage(res?.data?.totalPages);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };
  const handleAddPolicyClick = () => {
    setIsModalVisible(true);
  };

  const handleEditClick = (record) => {
    setEditingUser(record);
    setIsEditModalVisible(true);
  };

  const handleStatusChange = async (value, status) => {
    console.log(value, "handleStatusChange");
    setLoading(true);

    let params = new URLSearchParams();
    params.append("policyId", value?._id);
    params.append("status", status);

    let res = await updatePolicyStatus(params?.toString());
    if (res?.status === 200) {
      toast.success(res?.message || "Successfully");
      fetchListing(pagination);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
      setLoading(false);
    }
  };

  const getStatusClass = (status) => {
    if (status === "active") {
      return "active-status-selector";
    } else if (status === "inactive") {
      return "inactive-status-selector";
    } else {
      return "end-soon-status-selector";
    }
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete?._id);
    setDeleteModalVisible(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      const res = await deletePolicy(itemToDelete);
      if (res?.status === 200) {
        toast.success("Policy deleted successfully");
        setDeleteModalVisible(false);
        fetchListing(pagination);
      } else {
        toast.error("Failed to delete policy.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the policy.");
      console.error("Error deleting policy:", error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      key: "key",
      render: (text) => (
        <span>{(pagination?.current - 1) * pagination?.pageSize + text}</span>
      ),
    },
    {
      title: "Policy ID",
      dataIndex: "policyId",
      key: "policyId",
      align: "center",
      render: (text) => (
        <span className="uppercase">{text || "N/A"}</span>
      ),
    },
    {
      title: "Policy No.",
      dataIndex: "policyNumber",
      key: "policyNo",
      render: (policyNumber, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {policyNumber || "N/A"}
        </span>
      ),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (year, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {year}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {type}
        </span>
      ),
    },
    {
      title: "Policy Owner",
      dataIndex: "policyOwner",
      key: "policyOwner",
      render: (policyOwner, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {policyOwner}
        </span>
      ),
    },
    {
      title: "Premium Amt.",
      dataIndex: "premiumAmount",
      key: "premiumAmount",
      render: (premiumAmount, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {premiumAmount}
        </span>
      ),
    },
    {
      title: "Premium Freq.",
      dataIndex: "premiumFrequency",
      key: "premiumFrequency",
      render: (premiumFrequency, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {premiumFrequency}
        </span>
      ),
    },
    {
      title: "Maturity/End Date",
      dataIndex: "maturityEndDate",
      key: "maturityEndDate",
      render: (maturityEndDate, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {dayjs(maturityEndDate).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: "Next Due Date",
      dataIndex: "policyStart",
      key: "policyStart",
      render: (policyStart, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {dayjs(policyStart).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: "Claim",
      dataIndex: "raiseClaim",
      key: "raiseClaim",
      render: (raiseClaim, record) => (
        <span
          className="cursor-pointer"
          onClick={() =>
            navigate(`/manage-clients/edit/edit-policy`, {
              state: { clientData: record },
            })
          }
        >
          {raiseClaim ? "Yes" : "No"}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Select
          value={status}
          onChange={(value) => handleStatusChange(record, value)}
          style={{ width: 120 }}
          className={getStatusClass(status)}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex space-x-2">
          <div
            className="cursor-pointer"
            onClick={() => handleEditClick(record)}
          >
            <TableEditIcon />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => handleDeleteClick(record)}
          >
            <TableDeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  const sortByOptions = [
    { value: "policyNo", label: "Policy No." },
    { value: "year", label: "Year" },
    { value: "premiumAmt", label: "Premium Amt." },
    { value: "nextDueDate", label: "Next Due Date" },
    { value: "status", label: "Status" },
  ];

  const filterOptions = [
    { value: "policyType", label: "Policy Type" },
    { value: "policyOwner", label: "Policy Owner" },
    { value: "status", label: "Status" },
  ];

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setPagination(defaultPagination);
    }, 1000),
    [pagination]
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    fetchListing();
  }, [searchValue, pagination]);

  return (
    <div>
      <CustomTable
        columns={columns}
        data={tableData}
        heading="Policy"
        paginationProps={{ ...pagination, total: totalPage }}
        onChange={handleTableChange}
        loading={loading}
      >
        <Search
          placeholder="Search..."
          allowClear
          onChange={handleSearch}
          size="large"
          prefixCls="custom-search"
        />
        {/* <Select
          defaultValue="Sort By"
          className="h-[48px] w-[100px]"
          popupMatchSelectWidth={false}
        >
          {sortByOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select> */}

        {/* <Select
          defaultValue="Filter"
          popupMatchSelectWidth={false}
          className="h-[48px] w-[100px]"
          suffixIcon={<FilterDropdownIcon />}
        >
          {filterOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select> */}
        {user?.permission_client?.includes("Add Policy") && (
          <button
            className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]"
            onClick={handleAddPolicyClick}
          >
            <span>
              <PlusOutlined /> Add Policy
            </span>
          </button>
        )}
      </CustomTable>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
      />
      <CustomModal
        width={883}
        height={500}
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <AddPolicy
          onClose={() => setIsModalVisible(false)}
          fetchListing={fetchListing}
        />
      </CustomModal>

      <CustomModal
        width={883}
        height={500}
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      >
        <EditPolicy
          editingUser={editingUser}
          onClose={() => setIsEditModalVisible(false)}
          fetchListing={fetchListing}
        />
      </CustomModal>
    </div>
  );
};

export default ClientPolicy;
