import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: "loginSlice",
    initialState: {
        data: null,
        token: null,
    },
    reducers: {
        loginStep: (state, actions) => {
            if (actions?.payload) {
                state.data = actions.payload?.data;
                state.token = actions.payload?.token;
            }
        },
        logout: (state, actions) => {
            if (actions) {
                state.data = null;
                state.token = null;
            }
        },
    },
});

export const { loginStep, logout } = loginSlice.actions;
export default loginSlice.reducer;
