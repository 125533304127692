import React from "react";
import { Form, Input } from "antd";
import { ButtonComp } from "./ButtonComp";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { AKLogo } from "../utils/ImagesPaths";

const AuthForm = ({ loading, onFinish, data }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      className={`w-[372px] flex flex-col justify-center items-center gap-10 max-md:w-full`}
    >
      <img src={AKLogo} alt="" />
      <div>
        <div>
          <h2 className="font-roboto text-[23px] font-bold leading-[28.8px] text-center text-[#1A1A1A] my-3">
            {data?.header}
          </h2>

          <p className="font-inter text-[14px] font-normal leading-[21px] text-center text-[#696969] mb-3">
            {data?.subHeader}
          </p>
        </div>
        <div>
          {data?.email && (
            <div>
              <label className="font-roboto text-[12px] font-normal leading-[14.4px] text-left text-[#333333]">
                Email Address
              </label>

              <Form.Item
                name="email"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please email address",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your email address"
                  className="w-full h-[52px] bg-[#f2f2f2] rounded-lg py-[14px] px-4 text-base border-none custom-shadow mt-2"
                />
              </Form.Item>
            </div>
          )}
          {data?.password && (
            <div>
              <label className="font-roboto text-[12px] font-normal leading-[14.4px] text-left text-[#333333]">
                {location?.pathname?.includes("set-new-password")
                  ? "New Password"
                  : "Password"}
              </label>
              <Form.Item
                name="password"
                className="mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  className="w-full h-[52px] bg-[#f2f2f2] rounded-lg py-[14px] px-4 text-base border-none custom-shadow mt-2"
                  autocomplete="off"
                  onChange={(e) => {
                    form.setFieldValue("password", e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          )}
          {data?.confirmPassword && (
            <>
              <div className="mt-6">
              <label className="font-roboto text-[12px] font-normal leading-[14.4px] text-left text-[#333333]">
                  {location?.pathname?.includes("set-new-password")
                    ? "Confirm New Password"
                    : "Confirm Password"}
                </label>
                <Form.Item
                  className="mb-2"
                  name="confirm"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Enter confirm password"
                    className="w-full h-[52px] bg-[#f2f2f2] rounded-lg py-[14px] px-4 text-base border-none custom-shadow mt-2"
                  />
                </Form.Item>
              </div>
            </>
          )}
          {data?.forgotPassword && (
            <div
              className="w-full text-[#007AFF] text-[12px] font-normal leading-[20px] tracking-[0.3px] text-right cursor-pointer"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password
            </div>
          )}
        </div>
        <div>
          <ButtonComp
           margin="30px 0 0 0"
            textcard={data?.buttonText}
            isLoading={loading}
            background="#688540"
          />
        </div>
        
        {/* {(data?.footer || data?.subFooter) && (
          <footer className="text-center text-base font-bold mb-4">
            {data?.footer}
            {data?.subFooter && (
              <span
                className={`text-primary-theme font-bold cursor-pointer ${
                  data?.subFooter != "Back" && "underline"
                }`}
                onClick={() => navigate(data?.footerRoute)}
              >
                {data?.subFooter}
              </span>
            )}
          </footer>
        )} */}
      </div>
    </Form>
  );
};

export default AuthForm;

export const PasswordRequirement = styled.div`
  width: 100%;
  p {
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 16px 0;
    color: #000;
  }
  div {
    display: flex;
    justify-content: space-between;
    .password-validations {
      list-style: none;
      font-family: Lato, sans-serif;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.01em;
      background: transparent;

      li {
        display: flex;
        align-items: center;
        gap: 5px;
        > svg {
          margin: 0 !important;
          filter: none;
        }
      }
    }
    .active {
      color: #22b85f !important;
    }

    @media (max-width: 1245px) {
      flex-direction: column !important;
    }
  }
`;
