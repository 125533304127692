import React, { useCallback } from "react";
import { Form, Input, Select, DatePicker, Row, Col } from "antd";
import CustomUpload from "../../../../../components/CustomUpload";
import { DeleteOutlined } from "@ant-design/icons";
import { relationshipOptions } from "../../../../../utils/stateCityData";
import dayjs from "dayjs";
import {
  editNomineeDetails,
  editNomineeMedicalDetails,
} from "../../../../../service/Collections";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { uploadDocOnS3 } from "../../../../../aws/commonFunc";

const { Option } = Select;

const EditNomineeDetails = ({ onChange, onClose, fetching }) => {
  const clientData = useSelector((state) => state?.selectedClient?.client?.[0]);
  const [form] = Form.useForm();

  const handleFileUpload = async (fileList, nomineeIndex, historyIndex) => {
    try {
      const uploadedFiles = await Promise.all(
        fileList.map(async (file, index) => {
          if (file.response?.url || file.url) {
            return {
              uid: file.uid || index.toString(),
              name: file.name,
              url: file.response?.url || file.url,
              status: "done",
            };
          }
          const uploadedUrl = await uploadDocOnS3(file.originFileObj);
          if (uploadedUrl) {
            return {
              uid: index.toString(),
              name: file.name,
              url: uploadedUrl,
              status: "done",
            };
          } else {
            throw new Error("File upload failed");
          }
        })
      );
      const nominees = form.getFieldValue("nominees");
      nominees[nomineeIndex].medicalHistory[historyIndex].document =
        uploadedFiles;
      form.setFieldsValue({ nominees });
      console.log(
        form.getFieldValue("nominees"),
        "Updated nominees with document field"
      );
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Failed to upload documents.");
    }
  };

  const onFinish = async (values) => {
    const { nominees } = values;

    try {
      await Promise.all(
        nominees.map(async (nominee) => {
          const { _id, medicalHistory, ...payload } = nominee;
          if (medicalHistory?.length > 0) {
            nominee.medicalHistory = medicalHistory.map((history) => ({
              ...history,
              document: history.document?.[0]?.url || null,
            }));
          }
          const resNomineeDetails = await editNomineeDetails(
            clientData?._id,
            _id,
            payload
          );
          if (resNomineeDetails?.status !== 200) {
            throw new Error(
              resNomineeDetails?.message || "Failed to update nominee details"
            );
          }

          if (nominee.medicalHistory?.length > 0) {
            await Promise.all(
              nominee.medicalHistory.map(async (history) => {
                const { _id, ...medicalPayload } = history;

                const resMedicalDetails = await editNomineeMedicalDetails(
                  _id,
                  medicalPayload
                );
                if (resMedicalDetails?.status !== 200) {
                  throw new Error(
                    resMedicalDetails?.message ||
                      "Failed to update medical history"
                  );
                }
              })
            );
          }
        })
      );
      toast.success("Nominee details updated successfully");
      onClose();
      fetching();
    } catch (error) {
      toast.error(error.message || "An error occurred while updating nominees");
    }

    onChange(values);
  };

  return (
    <div className=" bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Edit Nominee Details
      </h2>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          nominees: clientData.nomineeDetails?.map((nominee) => ({
            name: nominee?.name,
            dob: dayjs(nominee?.dateOfBirth),
            relation: nominee?.relation,
            _id: nominee?._id,
            medicalHistory: clientData?.nomineeMedicalHistory
              ?.filter((el) => el.referenceId === nominee?._id)
              ?.map((el) => ({
                _id: el?._id,
                illness: el?.illness,
                medicineName: el?.medicineName,
                document: el?.document,
              })),
          })),
        }}
      >
        <Form.List name="nominees">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className="bg-gray-100 p-4 mb-4">
                  {fields.length > 1 && (
                    <div className="flex justify-end">
                      <DeleteOutlined
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  )}
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label="Nominee Name"
                        name={[name, "name"]}
                        rules={[
                          { required: true, message: "Enter nominee name" },
                        ]}
                      >
                        <Input
                          placeholder="Enter Name"
                          className="h-12 rounded-md border-gray-300"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label="Date of Birth"
                        name={[name, "dob"]}
                        rules={[
                          { required: true, message: "Select date of birth" },
                        ]}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          placeholder="DD/MM/YYYY"
                          className="h-12 w-full rounded-md border-gray-300"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        label="Relation"
                        name={[name, "relation"]}
                        rules={[{ required: true, message: "Select relation" }]}
                      >
                        <Select
                          placeholder="Select"
                          className="h-12 rounded-md border-gray-300"
                        >
                          {relationshipOptions?.map((relation) => (
                            <Option key={relation} value={relation}>
                              {relation}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.List name={[name, "medicalHistory"]}>
                    {(
                      historyFields,
                      { add: addHistory, remove: removeHistory }
                    ) => (
                      <>
                        {historyFields.map(
                          ({
                            key: historyKey,
                            name: historyName,
                            ...restHistoryField
                          }) => (
                            <div key={historyKey} className="bg-white p-4 mb-4">
                              {historyFields.length > 1 && (
                                <div className="flex justify-end">
                                  <DeleteOutlined
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => removeHistory(historyName)}
                                  />
                                </div>
                              )}
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    {...restHistoryField}
                                    label="Medical Illness"
                                    name={[historyName, "illness"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Enter medical illness",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter medical illness"
                                      className="h-12 rounded-md border-gray-300"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    {...restHistoryField}
                                    label="Medicine Name"
                                    name={[historyName, "medicineName"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Enter medicine name",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter medicine name"
                                      className="h-12 rounded-md border-gray-300"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={24}>
                                  <Row gutter={16}>
                                    <Col span={24}>
                                      <Form.Item
                                        label="Upload Document"
                                        name={[historyName, "document"]}
                                        valuePropName="fileList"
                                      >
                                        <CustomUpload
                                          fileListProp={
                                            form.getFieldValue([
                                              "nominees",
                                              name,
                                              "medicalHistory",
                                              historyName,
                                              "document",
                                            ]) || []
                                          }
                                          onFileChange={(fileList) =>
                                            handleFileUpload(
                                              fileList,
                                              name,
                                              historyName
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                        {/* <div className="flex justify-end mt-2">
                          <p
                            className="text-green-theme cursor-pointer text-sm font-medium"
                            onClick={() => addHistory()}
                          >
                            + Add Medical History
                          </p>
                        </div> */}
                      </>
                    )}
                  </Form.List>
                </div>
              ))}
              {/* <div className="flex justify-end mt-2">
                <p
                  className="text-green-theme cursor-pointer text-sm font-medium mb-[10px]"
                  onClick={() => add({ medicalHistory: [{}] })}
                >
                  + Add Nominee
                </p>
              </div> */}
            </>
          )}
        </Form.List>

        <div className="flex justify-end mt-[20px]">
          <button className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
            Update
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditNomineeDetails;
