import React, { useState } from "react";
import { AKLogo, authImg } from "../utils/ImagesPaths";
import { HeaderLogOutIcon, HeaderSettingIcon } from "../utils/SVG";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/slices/loginSlice";
import DeleteModal from "./CustomDeleteModal";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import CustomAvatar from "./CustomAvatar";

const tabs = [
  {
    display: "Dashboard",
    path: "/dashboard",
  },
  {
    display: "Role & Management",
    path: "/role-management",
  },
  {
    display: "Feedback",
    path: "/feedback",
  },
  {
    display: "Manage Clients",
    path: "/manage-clients",
  },
  {
    display: "History",
    path: "/history",
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);
  const userData = useSelector((state) => state?.loginSlice?.data);
  console.log(userData, "userData");
  const isActive = (path) =>
    location.pathname.includes(path) || location.pathname === path;
  const handleLogoutClick = () => {
    setLogoutModalVisible(true);
  };

  const handleLogoutConfirm = () => {
    dispatch(logout());
    setLogoutModalVisible(false);
    navigate("/");
  };

  const handleLogoutCancel = () => {
    setLogoutModalVisible(false);
  };

 const userTabs = tabs.filter((tab) =>
    userData?.permission_module?.includes(tab?.display)
  );

  const finalTabs = [...userTabs, { display: "History", path: "/history" }];

  return (
    <div className="h-[60px] flex justify-between items-center px-[20px]">
      <img
        src={AKLogo}
        alt="logo"
        className="w-[157.66px] h-[40px] hidden lg:flex"
      />

      <ul className="hidden lg:flex gap-[12px]">
        {finalTabs &&
          finalTabs?.map((t) => (
            <li
              className={`px-[25px] py-[7px] rounded-[20px] text-[14px] font-[400] leading-[21px] text-left font-roboto cursor-pointer ${isActive(t?.path) ? "bg-green-theme text-white" : ""
                }`}
              onClick={() => navigate(t?.path)}
            >
              {t?.display}
            </li>
          ))}
      </ul>

      <div className="flex items-center gap-2">
        <div className="flex items-center gap-2">
          <CustomAvatar
            firstName={userData?.firstName}
            lastName={userData?.lastName}
          />
          <div className="flex flex-col">
            <p className="text-[14px] font-[700] leading-[16.8px] text-left text-[#111111] font-roboto">
              {userData?.firstName + " " + userData?.lastName || ""}
            </p>
            <p className="text-[12px] font-[400] leading-[14.4px] text-left font-roboto text-[#696969]">
              {userData?.role?.[0] || ""}
            </p>
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/setting")}>
          <HeaderSettingIcon />
        </div>
        <div className="cursor-pointer" onClick={() => handleLogoutClick()}>
          <HeaderLogOutIcon />
        </div>
      </div>

      <DeleteModal
        isVisible={isLogoutModalVisible}
        onClose={handleLogoutCancel}
        onDelete={handleLogoutConfirm}
        isLogout={true}
      />
    </div>
  );
};

export default Header;
