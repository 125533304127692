import { CaretLeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClientProfile from "./Profile/ClientProfile";
import ClientPolicy from "./Policy/ClientPolicy";
import { getClientDetails } from "../../../service/Collections";
import { getErrorMessage } from "../../../utils/errorMessage";
import { toast } from "react-toastify";
import { updateSelectedClient } from "../../../store/slices/SelectedClientSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateClientStatus } from "../../../service/Collections";
import StatusChangeModal from "../../../components/ChangeStatusModal";

const ViewClientDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const initialButton = location.state?.selectedButton || "profile";
  const [selectedButton, setSelectedButton] = useState(initialButton);
  const [loading, setLoading] = useState(false);
  const clientId = location?.state?.id;
  const [clientData, setClientData] = useState([]);
  const [isStatusModalVisible, setStatusModalVisible] = useState(false);
  const client = useSelector((state) => state?.selectedClient?.client?.[0]);
  console.log(client?.status, "client");

  const handleClick = (button) => {
    setSelectedButton(button);
  };

  const handleLogoutClick = () => {
    setStatusModalVisible(true);
  };

  const handleStatusConfirm = () => {
    setStatusModalVisible(false);
    const newStatus = client?.status === "inactive" ? "active" : "inactive";
    handleStatusChange(newStatus);
  };

  const handleStatusCancel = () => {
    setStatusModalVisible(false);
  };

  const handleStatusChange = async (newStatus) => {
    const res = await updateClientStatus(clientId, newStatus);
    if (res.status === 200) {
      toast.success(res?.message);
      navigate("/manage-clients");
    } else {
      toast.error(res?.message);
      console.log(res?.message);
    }
  };
  const fetchingDeatils = async () => {
    setLoading(true);
    const res = await getClientDetails(clientId);
    if (res.status === 200) {
      setClientData(res?.data);
      dispatch(updateSelectedClient(res?.data));
      // navigate("/manage-clients/edit", { state: { clientData: res?.data } });
    } else {
      updateSelectedClient(null);
      const message = getErrorMessage(res, "Failed to fetch client details");
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchingDeatils();
  }, []);

  useEffect(() => {
    setSelectedButton(initialButton);
  }, [initialButton]);

  if (loading) {
    return <div>Loading!</div>;
  }

  return (
    <div>
      <div className="mb-[20px]">
        <CaretLeftOutlined
          className="mr-[10px] cursor-pointer"
          onClick={() => navigate("/manage-clients")}
        />
        <span className="text-[14px] font-[500] text-left font-roboto text-black">
          Manage Clients / {clientData?.firstName || ""}{" "}
          {clientData?.middleName || ""} {clientData?.lastName || ""}
        </span>
      </div>

      <div className="flex justify-between mb-[20px]">
        <div className="w-fit flex p-[4px] bg-white rounded-[6px]">
          <button
            className={`w-[120px] py-2 px-4 ${
              selectedButton === "profile"
                ? "bg-green-theme text-white"
                : "bg-white"
            } rounded`}
            onClick={() => handleClick("profile")}
          >
            Profile
          </button>
          <button
            className={`w-[120px] py-2 px-4 ${
              selectedButton === "policy"
                ? "bg-green-theme text-white"
                : "bg-white"
            } rounded`}
            onClick={() => handleClick("policy")}
          >
            Policy
          </button>
        </div>

        {selectedButton === "profile" && (
          <div className="flex justify-end">
            {client?.status === "inactive" ? (
              <button
                onClick={handleLogoutClick}
                className="bg-red-500 text-white px-4 py-2 rounded-[10px] hover:bg-red-600 w-[116px] h-[45px]"
              >
                Deactivate
              </button>
            ) : (
              <button
                onClick={handleLogoutClick}
                className="bg-green-500 text-white px-4 py-2 rounded-[10px] hover:bg-green-600 w-[116px] h-[45px]"
              >
                Activate
              </button>
            )}
          </div>
        )}
      </div>

      {selectedButton === "profile" ? (
        <ClientProfile fetching={fetchingDeatils} />
      ) : (
        <ClientPolicy />
      )}

      <StatusChangeModal
        isVisible={isStatusModalVisible}
        onClose={handleStatusCancel}
        onDelete={handleStatusConfirm}
      />
    </div>
  );
};

export default ViewClientDetail;
