import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginSlice from "./slices/loginSlice";
import addClientInfoReducer from "./slices/AddClientInfoSlice";
import selectedClientSlice from "./slices/SelectedClientSlice";

const reducers = combineReducers({
    loginSlice: loginSlice,
    addClientInfoSlice: addClientInfoReducer,
    selectedClient: selectedClientSlice
});

const persistConfig = {
    key: "Version-0.4",
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export const persistor = persistStore(store);