import React, { useEffect, useState } from "react";
import { Divider, Form } from "antd";
import { CaretLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../../../../components/CustomModal";
import DeleteModal from "../../../../components/CustomDeleteModal";
import EditPolicy from "./EditPolicy";
import dayjs from "dayjs";
import {
  deletePolicy,
  getNomineeMember,
} from "../../../../service/Collections";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../utils/errorMessage";
import { useSelector } from "react-redux";

const ViewPolicy = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const location = useLocation();
  const clienData = location?.state?.clientData || {};
  const navigate = useNavigate();
  const [nomineeMembers, setNomineeMembers] = useState([]);
  const clienId = useSelector((state) => state?.selectedClient?.client?.[0]);

  const handleEditClick = () => {
    setIsModalVisible(true);
  };
  const handleDeleteClick = () => {
    setDeleteModalVisible(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      const res = await deletePolicy(clienData?._id);
      if (res?.status === 200) {
        navigate("/manage-clients");
        toast.success("Policy deleted successfully");
        setDeleteModalVisible(false);
      } else {
        toast.error("Failed to delete policy.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the policy.");
      console.error("Error deleting policy:", error);
    }
  };

  const fetchNomineeMembers = async () => {
    const res = await getNomineeMember(clienId?._id);
    if (res?.status === 200) {
      setNomineeMembers(res?.data);
    } else {
      setNomineeMembers([]);
      const message = getErrorMessage(res, "Failed to fetch client details");
      toast.error(message);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  useEffect(() => {
    fetchNomineeMembers();
  }, [clienId]);

  return (
    <>
      <div className="flex justify-between mb-[20px]">
        <div>
          <CaretLeftOutlined
            className="mr-[10px] cursor-pointer"
            onClick={() => navigate("/manage-clients")}
          />
          <span className="text-[14px] font-[500] text-left font-roboto text-black">
            Manage Clients / {"client name"}/{clienData?._id}
          </span>
        </div>
        <p className="bg-[#DFFFDE] text-[#5EB85C] rounded-[100px] px-[12px] py-[5px] text-center text-[12px] font-[600] font-roboto">
          {clienData?.status}
        </p>
      </div>
      <div className="p-6 bg-white rounded-[12px]  border border-green-theme">
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-6">Policy Details</h2>
          {/* <div className="flex gap-[4px]">
            <div className="cursor-pointer" onClick={() => handleEditClick(clienData?.clientId)}>
              <TableEditIcon />
            </div>
            <div className="cursor-pointer" onClick={handleDeleteClick}>
              <TableDeleteIcon />
            </div>
          </div> */}
        </div>
        <div className="flex justify-between gap-[30px]">
          <div className="w-[100%]">
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Policy No."
              name="policyNo"
              labelCol={{ span: 8 }}
              prefixCls="edit-policy-form-item"
              rules={[
                { required: false, message: "Please enter policy number" },
              ]}
            >
              <p>{clienData?.policyNumber || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Year"
              name="year"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please enter year" }]}
            >
              <p>{clienData?.year || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Policy Owner"
              name="policyOwner"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter policy owner" },
              ]}
            >
              <p>
                {clienData?.policyOwner.charAt(0).toUpperCase() +
                  clienData?.policyNumber.slice(1) || "NA"}
              </p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Premium Amount"
              name="premiumAmount"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter premium amount" },
              ]}
            >
              <p>₹{clienData?.premiumAmount || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Sum Insured"
              name="sumInsured"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please enter sum insured" }]}
            >
              <p>₹{clienData?.sumInsured || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Proposer"
              name="proposer"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please enter proposer" }]}
            >
              <p>
                {clienData?.proposer.charAt(0).toUpperCase() +
                  clienData?.proposer.slice(1) || "NA"}
              </p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Premium Freq."
              name="premiumFreq"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter premium freq" },
              ]}
            >
              <p>
                {clienData?.premiumFrequency.charAt(0).toUpperCase() +
                  clienData?.premiumFrequency.slice(1) || "NA"}
              </p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Company Name"
              name="companyName"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter premium freq" },
              ]}
            >
              <p>
                {clienData?.companyName.charAt(0).toUpperCase() +
                  clienData?.companyName.slice(1) || "NA"}
              </p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />{" "}
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Email"
              name="email"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter premium freq" },
              ]}
            >
              <p>{clienData?.email || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />{" "}
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Phone Number"
              name="phoneNumber"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter premium freq" },
              ]}
            >
              <p>{clienData?.phoneNumber || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
          </div>

          <div className="w-[100%]">
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Plan Name"
              name="planName"
              labelCol={{ span: 8 }}
              prefixCls="edit-policy-form-item"
              rules={[{ required: false, message: "Please enter plain name" }]}
            >
              <p>
                {clienData?.planName.charAt(0).toUpperCase() +
                  clienData?.planName.slice(1) || "NA"}
              </p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="PT"
              name="pt"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please enter pt" }]}
            >
              <p>{clienData?.pt || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="PPT"
              name="ppt"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please enter ppt" }]}
            >
              <p>{clienData?.ppt || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Policy Start"
              name="policyStart"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter policy start" },
              ]}
            >
              <p>
                {dayjs(clienData?.policyStartDate).format("DD/MM/YYYY") || "NA"}
              </p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Next Due Date"
              name="nextDueDate"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                { required: false, message: "Please enter next due date" },
              ]}
            >
              <p></p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Maturity/End Date"
              name="maturityEndDate"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: false,
                  message: "Please enter maturity end date",
                },
              ]}
            >
              <p>
                {dayjs(clienData?.maturityEndDate).format("DD/MM/YYYY") || "NA"}
              </p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Raise Claim"
              name="raiseClaim"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please enter raise claim" }]}
            >
              <p>{clienData?.raiseClaim ? "Yes" : "No" || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Nominee"
              name="nominee"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please select a nominee" }]}
            >
              {nomineeMembers.length > 0 ? (
                <p className="text-[12px] font-[400] font-roboto">
                  {nomineeMembers.find(
                    (member) => member._id === clienData?.nomineeId
                  )?.name
                    ? nomineeMembers
                        .find((member) => member._id === clienData?.nomineeId)
                        .name.replace(/^./, (char) => char.toUpperCase())
                    : "NA"}
                </p>
              ) : (
                <p>NA</p>
              )}
            </Form.Item>

            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
            <Form.Item
              className={`text-[12px] font-[400] text-left font-roboto text-red mb-[0px] py-[10px]`}
              label="Nominee Relation"
              name="nomineeRelation"
              prefixCls="edit-policy-form-item"
              labelCol={{ span: 8 }}
              rules={[{ required: false, message: "Please enter raise claim" }]}
            >
              <p>{clienData?.nominee?.relation || "NA"}</p>
            </Form.Item>
            <Divider
              style={{
                borderColor: "#696969",
                margin: "0px",
                opacity: 0.3,
              }}
            />
          </div>
        </div>
        <CustomModal
          width={883}
          height={500}
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        >
          <EditPolicy
            editingUser={clienData}
            onClose={() => setIsModalVisible(false)}
          />
        </CustomModal>
      </div>

      <DeleteModal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
      />
    </>
  );
};

export default ViewPolicy;
