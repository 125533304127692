export const stateCityData = {
    andhraPradesh: ["Visakhapatnam", "Vijayawada", "Guntur", "Nellore", "Kurnool", "Tirupati", "Rajahmundry"],
    arunachalPradesh: ["Itanagar", "Naharlagun", "Pasighat", "Tawang", "Ziro"],
    assam: ["Guwahati", "Dibrugarh", "Silchar", "Jorhat", "Tezpur"],
    bihar: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur", "Darbhanga"],
    chhattisgarh: ["Raipur", "Bilaspur", "Durg", "Korba", "Raigarh"],
    goa: ["Panaji", "Margao", "Vasco da Gama", "Mapusa", "Ponda"],
    gujarat: ["Ahmedabad", "Surat", "Vadodara", "Rajkot", "Bhavnagar", "Jamnagar"],
    haryana: ["Gurugram", "Faridabad", "Panipat", "Ambala", "Hisar"],
    himachalPradesh: ["Shimla", "Dharamshala", "Manali", "Solan", "Mandi"],
    jharkhand: ["Ranchi", "Jamshedpur", "Dhanbad", "Bokaro", "Deoghar"],
    karnataka: ["Bengaluru", "Mysuru", "Mangalore", "Hubli", "Belgaum"],
    kerala: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Thrissur", "Alappuzha"],
    madhyaPradesh: ["Bhopal", "Indore", "Gwalior", "Jabalpur", "Ujjain"],
    maharashtra: ["Mumbai", "Pune", "Nagpur", "Nashik", "Aurangabad", "Solapur"],
    manipur: ["Imphal", "Thoubal", "Bishnupur", "Churachandpur", "Ukhrul"],
    meghalaya: ["Shillong", "Tura", "Nongpoh", "Jowai", "Baghmara"],
    mizoram: ["Aizawl", "Lunglei", "Saiha", "Champhai", "Kolasib"],
    nagaland: ["Kohima", "Dimapur", "Mokokchung", "Tuensang", "Wokha"],
    odisha: ["Bhubaneswar", "Cuttack", "Rourkela", "Sambalpur", "Puri"],
    punjab: ["Ludhiana", "Amritsar", "Jalandhar", "Patiala"],
    rajasthan: ["Jaipur", "Jodhpur", "Udaipur", "Bikaner", "Ajmer"],
    sikkim: ["Gangtok", "Gyalshing", "Mangan", "Namchi", "Rangpo"],
    tamilNadu: ["Chennai", "Coimbatore", "Madurai", "Tiruchirappalli", "Salem"],
    telangana: ["Hyderabad", "Warangal", "Nizamabad", "Karimnagar", "Khammam"],
    tripura: ["Agartala", "Udaipur", "Dharmanagar", "Kailasahar", "Belonia"],
    uttarPradesh: ["Lucknow", "Kanpur", "Ghaziabad", "Agra", "Varanasi"],
    uttarakhand: ["Dehradun", "Haridwar", "Roorkee", "Haldwani", "Nainital"],
    westBengal: ["Kolkata", "Howrah", "Durgapur", "Siliguri", "Asansol"],
    andamanNicobar: ["Port Blair"],
    chandigarh: ["Chandigarh"],
    dadraNagarHaveli: ["Silvassa", "Daman"],
    delhi: ["New Delhi", "Delhi"],
    lakshadweep: ["Kavaratti"],
    puducherry: ["Puducherry", "Karaikal", "Yanam", "Mahe"]
};

export const states = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
    "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands",
    "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Delhi",
    "Lakshadweep", "Puducherry"
];

export const qualifications = [
    "High School Diploma", "Associate's Degree", "Bachelor's Degree",
    "Master's Degree", "Doctorate (Ph.D.)", "Post Graduate Diploma",
    "Certification/Diploma Courses", "Professional Degree", "Other"
];

export const natureOfBusiness = [
    "Manufacturing", "Retail", "Wholesale", "Information Technology (IT)",
    "Finance/Banking", "Healthcare", "Education", "Consulting",
    "Real Estate", "Hospitality and Tourism", "Transportation and Logistics",
    "Agriculture", "Construction", "Media and Entertainment",
    "Non-Profit Organization", "Public Sector/Government",
    "Telecommunication", "Food and Beverage", "Legal Services", "Other"
];

export const relationshipOptions = [
    "Father",
    "Mother",
    "Child",
    "Spouse",
    "Sibling",
    "Grandparent",
    "Uncle",
    "Aunt",
    "Cousin",
    "Friend",
    "Other"
];

export const banksInIndia = [
    "State Bank of India",
    "Punjab National Bank",
    "Bank of Baroda",
    "Canara Bank",
    "Union Bank of India",
    "Bank of India",
    "Indian Bank",
    "Central Bank of India",
    "Indian Overseas Bank",
    "UCO Bank",
    "Bank of Maharashtra",
    "Punjab & Sind Bank",
    "HDFC Bank",
    "ICICI Bank",
    "Axis Bank",
    "Kotak Mahindra Bank",
    "IndusInd Bank",
    "Yes Bank",
    "IDFC First Bank",
    "Federal Bank",
    "South Indian Bank",
    "RBL Bank",
    "Jammu & Kashmir Bank",
    "Bandhan Bank",
    "City Union Bank",
    "Dhanlaxmi Bank",
    "Karur Vysya Bank",
    "Andhra Pradesh Grameena Vikas Bank",
    "Baroda UP Bank",
    "Maharashtra Gramin Bank",
    "Karnataka Gramin Bank",
    "Chhattisgarh Rajya Gramin Bank",
    "Kerala Gramin Bank",
    "Madhya Pradesh Gramin Bank",
    "Telangana Grameena Bank",
    "Utkal Grameen Bank",
    "Paschim Banga Gramin Bank"
];
