import React, { useEffect } from "react";
import { Form, Input, Select, DatePicker, Row, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { disableFutureDates } from "../../../../../utils/commonFunction";
import { relationshipOptions } from "../../../../../utils/stateCityData";
import { editFamilyMember } from "../../../../../service/Collections";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const { Option } = Select;

const EditFamilyDetail = ({ onChange, onClose, fetching }) => {
  const clientData = useSelector((state) => state?.selectedClient?.client?.[0]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (clientData?.familyDetails) {
      const formattedFamilyDetails = clientData?.familyDetails.map(
        (member) => ({
          ...member,
          dateOfBirth: dayjs(member?.dateOfBirth),
        })
      );
      form.setFieldsValue({ familyMembers: formattedFamilyDetails });
    }
  }, [clientData, form]);

  const onFinish = async (values) => {
    const { familyMembers } = values;
    for (const member of familyMembers) {
      const { _id, ...payload } = member;
      const res = await editFamilyMember(clientData?._id, _id, payload);
      if (res?.status === 200) {
        onClose();
        fetching();
        toast.success(res?.message);
      } else {
        toast.error(res?.message || "Failed to update family member details");
      }
      onChange(values);
    }
  };

  return (
    <div className="bg-whiterounded-[20px]">
      <h2 className="text-2xl font-semibold mb-[16px] text-center">
        Edit Family Details
      </h2>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={{ familyMembers: [{}] }}
      >
        <Form.List name="familyMembers">
          {(fields, { add, remove }) => (
            <>
              <div className="mb-[24px]">
                <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
                  Family Details
                </p>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} className="bg-gray-100 p-[16px] mb-[10px]">
                    {fields.length > 1 && (
                      <div
                        className="flex justify-end cursor-pointer"
                        onClick={() => remove(name)}
                      >
                        <DeleteOutlined style={{ color: "red" }} />
                      </div>
                    )}
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          label="Name"
                          rules={[
                            { required: true, message: "Please enter name" },
                          ]}
                        >
                          <Input
                            placeholder="Enter Name"
                            className="h-12 rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "dateOfBirth"]}
                          label="Date of Birth"
                          rules={[
                            { required: true, message: "Please enter DOB" },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="h-12 w-full rounded-md border-gray-300"
                            disabledDate={disableFutureDates}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "relation"]}
                          label="Relation"
                          rules={[
                            {
                              required: true,
                              message: "Please select relation",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Relation"
                            className="h-[48px]"
                          >
                            {relationshipOptions?.map((relation) => (
                              <Option key={relation} value={relation}>
                                {relation}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "height"]}
                          label="Height"
                          rules={[
                            { required: true, message: "Please enter height" },
                            {
                              pattern: /^(10|[1-9])('|'[0-9]{1,2})?$/,
                              message: "Please enter a valid height eg. 5'10",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Height"
                            suffix="In"
                            className="h-[48px]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "weight"]}
                          label="Weight"
                          rules={[
                            { required: true, message: "Please enter weight" },
                            {
                              pattern: /^[0-9,]+$/,
                              message: "Please enter a valid number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Weight"
                            suffix="Kg"
                            className="h-[48px]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "healthStatus"]}
                          label="Health Status"
                          rules={[
                            {
                              required: true,
                              message: "Please select health status",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Health Status"
                            className="h-[48px]"
                          >
                            <Option value="healthy">Healthy</Option>
                            <Option value="minor_issues">Minor Issues</Option>
                            <Option value="major_issues">Major Issues</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, "medicineName"]}
                          label="Medicine Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter medicine name",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Medicine Name"
                            className="h-[48px]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, "illness"]}
                          label="Medical Illness"
                          rules={[
                            {
                              required: true,
                              message: "Please enter medical illness",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter medical illness"
                            className="h-12 rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          label="Description"
                          rules={[
                            {
                              required: true,
                              message: "Please enter description",
                            },
                          ]}
                        >
                          <TextArea
                            maxLength={100}
                            placeholder="Enter Description"
                            style={{
                              height: 120,
                              resize: "none",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </>
          )}
        </Form.List>
        <div className="flex justify-end mt-[20px]">
          <button className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
            Update
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditFamilyDetail;
