import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { PublicPaths } from "./PublicPaths";
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";
import { PrivatePaths } from "./PrivatePaths";
import { useSelector } from "react-redux";
import AccessDenied from "../modules/auth/AccessDenied";

// This function is used for Authentication when user's credentials will be valid.
function PublicRoute({ isAuthenticated, permissionModule }) {
  console.log(isAuthenticated, 'isAuthenticated')
  if (isAuthenticated !== null) {
    if (Array?.isArray(permissionModule) && permissionModule?.length > 0) {
      return <Navigate to={`${permissionModule?.[0]?.path}`} replace />;
    }
    return <Navigate to="/access-denied" replace />;
  }
  return <Outlet />;
}

// This function is used for Authentication when user's credentials will be invalid.
function PrivateRoute({ isAuthenticated }) {
  console.log(isAuthenticated, 'isAuthenticated')
  if (isAuthenticated === null) return <Navigate to="/" />;
  return <Outlet />;
}

export default function HealthInsuranceRoutes() {
  const user = useSelector((state) => state?.loginSlice?.data);
  const permissionModule = user?.permission_module || [];

  const credentials = useSelector((state) => state?.loginSlice?.token);
  const filteredRoutes = PrivatePaths.filter((route) =>
    permissionModule.includes(route.moduleName)
  );
  console.log(filteredRoutes, 'filteredRoutes', credentials)

  const alwaysAccessibleRoutes = PrivatePaths.filter((route) =>
    ["History", "Setting"].includes(route?.moduleName)
  );

  // Combine filtered routes and always accessible routes
  const finalRoutes = [...filteredRoutes, ...alwaysAccessibleRoutes];

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute isAuthenticated={credentials} permissionModule={filteredRoutes} />}>
          <Route path="/" element={<PublicLayout />}>
            {PublicPaths?.map((routes, index) => (
              <Route
                key={index}
                path={routes.path}
                element={routes.component}
              />
            ))}
          </Route>
        </Route>

        <Route element={<PrivateRoute isAuthenticated={credentials} />}>
          <Route path="/" element={<PrivateLayout />}>
            {finalRoutes?.map((routes, index) => (
              <Route
                key={index}
                path={routes.path}
                element={routes.component}
              />
            ))}
          </Route>
        </Route>
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
