import React, { useState } from "react";
import { Upload, Image, message } from "antd";
import { CrossIcon } from "../../utils/SVG";

const UploadMedia = () => {
  const [fileList, setFileList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChange = ({ fileList: newFileList }) => {
    const processedFiles = newFileList.map((file) => {
      if (!file.thumbUrl && file.originFileObj) {
        file.thumbUrl = URL.createObjectURL(file.originFileObj);
      }
      return file;
    });

    setFileList(processedFiles);

    if (!selectedImage && processedFiles.length > 0) {
      setSelectedImage(processedFiles[0]?.thumbUrl);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleDelete = (fileUid) => {
    const newFileList = fileList.filter((file) => file.uid !== fileUid);
    setFileList(newFileList);

    if (selectedImage) {
      if (newFileList.length > 0) {
        if (
          selectedImage ===
          fileList.find((file) => file.uid === fileUid)?.thumbUrl
        ) {
          setSelectedImage(newFileList[0]?.thumbUrl || null);
        }
      } else {
        setSelectedImage(null);
      }
    }
  };

  const beforeUpload = (file) => {
    const isImage =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isImage) {
      message.error(
        `${file.name} is not a valid image format (only JPG, JPEG, and PNG are allowed).`
      );
    }
    return isImage || Upload.LIST_IGNORE;
  };

  return (
    <div style={{ display: "flex", gap: "16px" }}>
      <div className="w-[30%] flex flex-col gap-[12px]">
        <p className="text-[24px] font-[500] text-left font-roboto text-black">
          Preview
        </p>
        {selectedImage ? (
          <Image
            src={selectedImage}
            alt="Preview"
            width="100%"
            style={{
              border: "2px solid #688540",
              borderRadius: "8px",
            }}
          />
        ) : (
          <p className="text-[14px] font-[500] text-left font-inter text-black">
            No image selected
          </p>
        )}
      </div>

      <div className="flex flex-col gap-[16px] w-[70%]">
        <div>
          <p className="text-[24px] font-[500] text-left font-roboto text-black">
            Upload Image
          </p>
          <p className="text-[14px] font-[500] text-left font-inter text-black">
            This image will show in user side welcome screen
          </p>
          <p className="text-[18px] font-[700] text-left font-inter text-black">
            Images
          </p>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          {fileList.map((file) => (
            <div
              key={file.uid}
              style={{ position: "relative", display: "inline-block" }}
            >
              <Image
                src={file.thumbUrl}
                alt={file.name}
                width={175}
                height={190}
                preview={false}
                style={{
                  borderRadius: "12px",
                  cursor: "pointer",
                  border:
                    selectedImage === file.thumbUrl
                      ? "2px solid #688540"
                      : "none",
                }}
                onClick={() => handleImageClick(file.thumbUrl)}
              />
              <div
                onClick={() => handleDelete(file.uid)}
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "-10px",
                  zIndex: 10,
                  cursor: "pointer",
                }}
              >
                <CrossIcon />
              </div>
            </div>
          ))}
        </div>

        <Upload
          multiple
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          showUploadList={false}
          prefixCls="setting-upload"
        >
          <p className="text-[12px] font-[400] text-center font-poppins text-black">
            <span className="text-[14px] font-[500] text-center font-poppins text-sky-500">
              Click to Upload
            </span>
            <br />
            or drag and drop <br />
            (Max. File size: 25 MB)
          </p>
        </Upload>
      </div>
    </div>
  );
};

export default UploadMedia;
