import React from 'react'
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/slices/loginSlice';
import { useDispatch } from 'react-redux';

export default function AccessDenied() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleGoToLogin = () => {
        dispatch(logout());
        navigate("/"); // Adjust the path based on your app's routing
    };


    return (
        <div className="bg-[#375112] h-screen flex items-center justify-center px-4">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-8 text-center">
                <h1 className="text-green-900 text-2xl font-bold mb-4">
                    Sorry, You Cannot Access This Portal
                </h1>
                <p className="text-gray-600 mb-6">
                    You don't have the necessary permissions to access this portal. <br />
                    Please contact the admin for further assistance.
                </p>
                <button
                    onClick={handleGoToLogin}
                    className="bg-yellow-500 text-white px-6 py-2 rounded-md font-semibold hover:bg-yellow-600 transition-colors"
                >
                    Go to Login
                </button>
            </div>
        </div>
    )
}
