import React, { useState } from "react";
import { Upload, message } from "antd";
import { UploadDeleteIcon, PdfIcon } from "../utils/SVG";

const { Dragger } = Upload;

const CustomUpload = ({ onFileChange, fileListProp, label }) => {
  const [fileList, setFileList] = useState(
    Array.isArray(fileListProp)
      ? fileListProp.map((file, index) =>
        typeof file === "string"
          ? { uid: index.toString(), url: file, name: `Document ${index + 1}` }
          : file
      )
      : []
  );

  const beforeUpload = (file) => {
    const isAllowedType = ["application/pdf", "image/png", "image/jpeg"].includes(file.type);
    const isLt25M = file.size / 1024 / 1024 < 25;

    if (!isAllowedType) {
      message.error("You can only upload PDF, PNG, or JPG files!");
      return Upload.LIST_IGNORE;
    }

    if (!isLt25M) {
      message.error("File must be smaller than 25 MB!");
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const handleFileChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.response?.url) {
        return { ...file, url: file.response.url };
      }
      return file;
    });

    setFileList(newFileList);
    onFileChange(newFileList);
  };

  const removeFile = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
    onFileChange(updatedFileList);
  };

  return (
    <div>
      <p className="text-[12px] pb-[8px] font-[400] text-left font-roboto text-black">
        {label}
      </p>
      {fileList.length > 0 ? (
        <ul className="border border-[#d9d9d9] rounded-[6px]">
          {fileList.map((file) => (
            <li
              key={file.uid}
              className="flex justify-between px-[8px] items-center mb-[4px]"
            >
              <span className="flex gap-[12px] items-center cursor-pointer"
                onClick={() => file.url && window.open(file.url, '_blank')}
              >
                {file.url || file.type === "application/pdf" ? (
                  <PdfIcon />
                ) : (
                  <img
                    src={file.url || URL.createObjectURL(file.originFileObj)}
                    alt={file.name}
                    className="w-[24px] h-[24px]"
                  />
                )}
                <span>
                  <p className="text-[14px] font-[500] text-left font-roboto text-black">
                    {file.name}
                  </p>
                  <p className="text-[12px] font-[400] text-left font-roboto text-gray-400">
                    {(file.size / 1024).toFixed(2)} KB
                  </p>
                </span>
              </span>
              <span
                className="cursor-pointer"
                onClick={() => removeFile(file)}
              >
                <UploadDeleteIcon />
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <Dragger
          name="file"
          multiple={false}
          beforeUpload={beforeUpload}
          onChange={handleFileChange}
          className="custom-upload-dragger"
        >
          <p className="text-[14px] font-[400] text-center font-poppins text-black">
            <span className="text-[14px] font-[500] text-center font-poppins text-sky-500">
              Click to Upload
            </span>{" "}
            or drag and drop
          </p>
          <p className="text-[12px] font-[400] text-center font-poppins text-black">
            (Max. File size: 25 MB)
          </p>
        </Dragger>
      )}
    </div>
  );
};

export default CustomUpload;
