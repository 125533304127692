import { deleteApi, getApi, postApi, putApi } from "./API_Methods";

// Auth API

const LOGIN = "/auth/login";

export const login = (payload) => {
  return postApi(LOGIN, payload);
};

const CHANGE_PASSWORD = "/admin/change-password";
export function changePassword(payload) {
  return postApi(CHANGE_PASSWORD, payload);
}

const FORGOT_PASSWORD = "/admin/forgot-password";
export function forgotPassword(payload) {
  return postApi(FORGOT_PASSWORD, payload);
}

// Dashboard

const DASHBOARD_STATS = "/admin/dashboard-stats";
export function getDashboardStats() {
  return getApi(DASHBOARD_STATS);
}




// Role and Management

const ADDROLE = "/admin/create-admin";
export function createUserRole(payload) {
  return postApi(ADDROLE, payload);
}

const LISTING_ROLE = "/admin/admin-listing";
export function listingUserRole(params) {
  return getApi(LISTING_ROLE, params);
}

const DELETE_ROLE = "/admin/delete-admin";
export function deleteRole(id) {
  return deleteApi(`${DELETE_ROLE}/${id}`);
}

const EDIT_ROLE = "/admin/edit-admin";
export function editRole(id, payload) {
  return putApi(`${EDIT_ROLE}/${id}`, payload);
}

// Manage client

const CREATECLIENT = "/client/create";
export function createClient(payload) {
  return postApi(CREATECLIENT, payload);
}

const DELETE_CLIENT = "/client/deleteClientById";
export function deleteClientById(clientId) {
  return deleteApi(`${DELETE_CLIENT}?clientId=${clientId}`);
}

const LISTING_ManageClient = "/client/client-listing";
export function listingManageClient(clientId) {
  return getApi(`${LISTING_ManageClient}?${clientId}`);
}

const VIEW_CLIENT_DETAILS = "/client/getClientDetails";
export function getClientDetails(clientId) {
  return getApi(`${VIEW_CLIENT_DETAILS}/${clientId}`);
}

const EDIT_CLIENT_PERSONAL_INFO = "client/edit";
export function editClientPersonalInfo(clientId, payload) {
  return putApi(`${EDIT_CLIENT_PERSONAL_INFO}/${clientId}`, payload);
}

const EDIT_CLIENT_MEDICAL_HISTORY = "client/edit-medicalHistory";
export function editClientMedicalHistory(medicalHistoryId, payload) {
  return putApi(`${EDIT_CLIENT_MEDICAL_HISTORY}/${medicalHistoryId}`, payload);
}

const EDIT_FAMILY_MEMBER = "client/edit-family";
export function editFamilyMember(clientId, familyMemberId, payload) {
  return putApi(`${EDIT_FAMILY_MEMBER}/${clientId}/${familyMemberId}`, payload);
}

const EDIT_NOMINEE_DETAILS = "client/edit-nominee";
export function editNomineeDetails(clientId, nomineeId, payload) {
  return putApi(`${EDIT_NOMINEE_DETAILS}/${clientId}/${nomineeId}`, payload);
}

const ADD_NOMINEE = "/client/add-nominee";
export function addNominee(payload) {
  return postApi(ADD_NOMINEE, payload);
}

const ADD_FAMILY_MEMBER = "/client/add-family-member";
export function addFamilyMember(payload) {
  return postApi(ADD_FAMILY_MEMBER, payload);
}

const EDIT_NOMINEE_MEDICAL_DETAILS = "client/edit-medicalHistory";
export function editNomineeMedicalDetails( medicalHistoryId, payload) {
  return putApi(`${EDIT_NOMINEE_MEDICAL_DETAILS}/${medicalHistoryId}`, payload);
}

const UPDATE_POLICY_STATUS = "/client/updateClientStatus";
export function updateClientStatus(clientId, status) {
  return getApi(
    `${UPDATE_POLICY_STATUS}?clientId=${clientId}&status=${status}`
  );
}

// policy

const CREATEPOLICY = "/policy/create";
export function createPolicy(payload) {
  return postApi(CREATEPOLICY, payload);
}

const GET_POLICY_LIST = "/policy/userPolicyList";
export function getPolicyList(id, payload) {
  return getApi(`${GET_POLICY_LIST}/${id}`, payload);
}

const DELETE_POLICY = "/policy/delete";
export function deletePolicy(policyId) {
  return deleteApi(`${DELETE_POLICY}/${policyId}`);
}

const EDIT_POLICY = "/policy/edit";
export function editPolicy(policyId, payload) {
  return putApi(`${EDIT_POLICY}/${policyId}`, payload);
}

const GET_FAMILY_MEMBER = "/policy/getFamilyMember";
export function getFamilyMember(clientId) {
  return getApi(`${GET_FAMILY_MEMBER}/${clientId}`);
}

const GET_NOMINEE_MEMBER = "/policy/getNomineeMember";
export function getNomineeMember(clientId) {
  return getApi(`${GET_NOMINEE_MEMBER}/${clientId}`);
}

const UPDATE__POLICY_STATUS = "/policy/updatePolicyStatus";
export function updatePolicyStatus(clientId) {
  return getApi(`${UPDATE__POLICY_STATUS}?${clientId}`);
}

// feedback

const GET_All_FEEDBACK_LIST = "/feedback/allListing";
export function getAllFeedbackList(params) {
  return getApi(GET_All_FEEDBACK_LIST, params);
}

const SEARCH_FEEDBACK = "/feedback/search-feedback";
export function searchFeedback(searchTerm) {
  return getApi(`${SEARCH_FEEDBACK}?search=${searchTerm}`);
}

const GET_USER_QUERY_LIST = "/userQuery/list";
export function getUserQueryList(payload) {
  return getApi(GET_USER_QUERY_LIST, payload);
}

const EDIT_USER_QUERY = "/userQuery/update";
export function editUserQuery(queryId, payload) {
  return putApi(`${EDIT_USER_QUERY}/${queryId}`, payload);
}

const GET_AVERAGE_RATING = "/feedback/getAverageRating";
export function getAverageRating() {
  return getApi(GET_AVERAGE_RATING);
}


// Hisotry

const LISTING_HISTORY = "/admin/history-list";
export function listingHistory(params) {
  return getApi(LISTING_HISTORY, params);
}

