import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAverageRating } from "../../service/Collections";

const ClientRating = () => {
  const navigate = useNavigate();
  const [ratingData, setRatingData] = useState({
    averageRating: "",
    totalFeedbacks: 0,
  });
  const fetchAverageRating = async () => {
    try {
      const response = await getAverageRating();
      if (response && response.status === 200) {
        setRatingData({
          averageRating: parseFloat(response.data.averageRating),
          totalFeedbacks: response?.data?.totalFeedbacks,
        });
      }
    } catch (error) {
      console.error("Error fetching average rating:", error);
    }
  };

  useEffect(() => {
    fetchAverageRating();
  }, []);

  return (
    <>
      <div className="w-full max-w-[397px] ms:auto bg-white rounded-lg py-[50px] px-[20px] text-center flex flex-col items-center justify-center">
        <h2 className="text-[24px] font-[700] font-roboto">Clients Rating</h2>
        <div className="flex items-center justify-center mb-[12px]">
          <span className="text-star-yellow-theme text-6xl mr-2">★</span>
          <span className="text-6xl font-bold">
            {ratingData?.averageRating || 0}
          </span>
        </div>
        <p className="text-[12px] font-[400] text-[#696969] font-roboto mb-[24px]">
          {ratingData?.averageRating} out of 5 ({ratingData?.totalFeedbacks}{" "}
          review)
          <br />
          Overall Rating of this platform
        </p>
        <button
          className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark"
          onClick={() => navigate("/feedback")}
        >
          See The Feedback
        </button>
      </div>

    </>
  );
};

export default ClientRating;
