import React from "react";
import styled from "styled-components";
import { Loader } from "./Loader";

export const ButtonComp = ({
    textcard,
    handleAction,
    isLoading,
    color,
    background,
    margin, // Add margin prop here
}) => {
    return handleAction ? (
        <PublicButtonWrapper
            onClick={handleAction}
            color={color}
            background={background}
            margin={margin} // Pass margin prop here
        >
            {isLoading ? (
                <Loader size={24} color={"#688540"} />
            ) : (
                <button
                    className="bg-primary-theme hover:bg-secondary-theme transition-colors"
                    type="submit"
                >
                    {textcard}
                </button>
            )}
        </PublicButtonWrapper>
    ) : (
        <PublicButtonWrapper color={color} background={background} margin={margin}>
            {isLoading ? (
                <Loader size={24} color={"#688540"} />
            ) : (
                <button
                    className="bg-primary-theme hover:bg-secondary-theme transition-colors"
                    type="submit"
                >
                    {textcard}
                </button>
            )}
        </PublicButtonWrapper>
    );
};

const PublicButtonWrapper = styled.div`
  width: 100%;
  margin: ${({ margin }) => margin || '0'}; // Apply margin here
  button {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    background: ${({ background }) => background ? background : "#3d97f2"};
    color: ${({ color }) => (color ? color : "#fff")};
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-style: none;
    letter-spacing: -0.01em;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.16);
    }
  }

  div {
    width: 100%;
    padding: 15px;
    background: transparent;
    border-radius: 8px;
    text-align: center;
    color: #ffff;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-style: none;
    letter-spacing: -0.01em;
    cursor: pointer;
  }
`;


export const FormButton = ({ loading, title }) => {
    return (
        <button
            className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[214px] h-[48px]"
            type={loading ? "button" : "submit"}
        >
            {loading ? "Loading" : title}
        </button>
    )
}