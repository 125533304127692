import React from "react";
import { Table } from "antd";

const CustomTable = ({ columns, data, heading, searchProps, loading = false, onChange, paginationProps, children }) => {
  console.log(paginationProps, 'paginationProps')
  return (
    <div className="flex flex-col gap-[24px]">
      {heading && (
        <div className="flex justify-between items-center">
          <p className="text-[24px] font-[600] leading-[28.8px] text-left font-roboto">
            {heading}
          </p>
          <div className="flex justify-center items-center gap-[10px]">
            {children}
          </div>
        </div>
      )}
      <Table
        columns={columns}
        dataSource={data}
        pagination={{...paginationProps, showSizeChanger: false }}
        onChange={onChange}
        loading={loading}
        prefixCls="custom-table"
        className="border rounded-lg shadow-lg"
        
      // scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default CustomTable;
