import { Col, Divider, Row } from "antd";
import React, { useState } from "react";
import { TableEditIcon } from "../../../../../utils/SVG";
import CustomModal from "../../../../../components/CustomModal";
import EditNomineeDetails from "./EditNomineeDetails";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import AddNominee from "../AddNomineeForm";

const ClientNomineeDetails = ({ fetching }) => {
  const clientData = useSelector((state) => state?.selectedClient?.client);
  const user = useSelector((state) => state?.loginSlice?.data);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const handleEditClick = () => {
    setIsEditModalVisible(true);
  };

  const handleAddClick = () => {
    setIsAddModalVisible(true);
  };

  if (!clientData || Object.keys(clientData)?.length === 0) {
    return <p>No client data available</p>;
  }
  console.log(clientData, "clientDataclientDataclientData");
  return (
    <div>
      <div className={`p-6 bg-white rounded-lg shadow-lg`}>
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-[10px]">Nominee Details</h2>
          <div className="flex gap-[10px]">
            <div
              className="cursor-pointer bg-green-theme w-[24px] h-[25px] rounded-full flex items-center justify-center text-white"
              onClick={handleAddClick}
            >
              +
            </div>
            {user?.permission_client?.includes("Edit Profile") && (
              <div className="cursor-pointer" onClick={handleEditClick}>
                <TableEditIcon />
              </div>
            )}
          </div>
        </div>
        {clientData[0]?.nomineeDetails.map((item, index) => (
          <div className="flex flex-col gap-[10px] p-[20px] border border-gray-400 rounded-[6px] mb-[10px]">
            <p className="text-[16px] font-[500] text-left font-roboto text-black">
              Nominee {index + 1}
            </p>

            <Row gutter={16}>
              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Nominee Name
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {item?.name || ""}
                </p>
              </Col>
              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Nominee Date of Birth
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {dayjs(item?.dateOfBirth).format("DD/MM/YYYY")}
                </p>
              </Col>

              <Col span={8}>
                <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                  Nominee Relation
                </span>
                <p className="text-[16px] font-[500] text-left font-roboto text-black">
                  {item?.relation || ""}
                </p>
              </Col>
            </Row>
            <Divider
              style={{
                margin: "0px",
                borderColor: "#696969",
                opacity: "0.3",
              }}
            />
            {clientData[0]?.nomineeMedicalHistory?.map(
              (medi) =>
                medi?.referenceId === item?._id && (
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                        Medical illness
                      </span>
                      <p className="text-[16px] font-[500] text-left font-roboto text-black">
                        {medi?.illness}
                      </p>
                    </div>
                    <div>
                      <p className="text-[12px] font-[400] text-left font-roboto text-gray-500">
                        Medicine Name
                      </p>
                      <p className="text-[16px] font-[500] text-left font-roboto text-black">
                        {medi?.medicineName}
                      </p>
                    </div>

                    <div>
                      {medi?.document?.trim() && (
                        <a
                          href={medi.document}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[14px] cursor-pointer font-[500] text-center font-inter text-green-theme underline"
                        >
                          View Document
                        </a>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        ))}
      </div>

      <CustomModal
        width={883}
        height={500}
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      >
        <EditNomineeDetails
          onChange={(updatedData) =>
            console.log(
              updatedData,
              "updatedDataupdatedDataupdatedDataupdatedDataupdatedData"
            )
          }
          onClose={() => setIsEditModalVisible(false)}
          fetching={fetching}
        />
      </CustomModal>

      <CustomModal
        width={883}
        height={500}
        isVisible={isAddModalVisible}
        onClose={() => setIsAddModalVisible(false)}
      >
        <AddNominee
          onClose={() => setIsAddModalVisible(false)}
          fetching={fetching}
        />
      </CustomModal>
    </div>
  );
};

export default ClientNomineeDetails;
