import React, { useCallback, useEffect, useState } from "react";
import PolicyQuerys from "./PolicyQuery";
import Search from "antd/es/transfer/search";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import AllFeedback, { defaultPagination } from "./Feedback";
import { debounce } from "lodash";
import { StarTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const RatingStar = ({ count }) => {
  return Array(count ?? 1)
    .fill("")
    .map((_, index) => <StarTwoTone key={index} twoToneColor="#eab308" />);
};

const FeedBack = () => {
  const location = useLocation();
  const initialButton = location.state?.selectedButton || "allFeedback";
  const [selectedButton, setSelectedButton] = useState(initialButton);
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [pagination, setPagination] = useState(defaultPagination);

  const handleClick = (button) => {
    setSelectedButton(button);
    setSelectedFilter(null);
    setSearch("");
    setSearchInput("");
    setPagination(defaultPagination); 
  };

  const handleSearch = debounce((value) => {
    setSearch(value.trim());
    setPagination({ ...pagination, current: 1 }); 
  }, 500);

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    setPagination({ ...pagination, current: 1 }); 
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchInput(value);
      handleSearch(value);
    }, 500),
    []
  );

  useEffect(() => {
    setSelectedButton(initialButton);
  }, [initialButton]);
  
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-[24px] font-[600] leading-[28.8px] text-left font-roboto">
          Feedback
        </p>
        <div className="flex gap-[10px]">
          <Search
            placeholder="Search..."
            allowClear
            size="large"
            prefixCls="custom-search"
            onChange={(e) => debouncedSearch(e.target.value)}
          />
          {selectedButton === "allFeedback" ? (
            <Select
              value={selectedFilter ?? "All"}
              onChange={handleFilterChange}
              className="h-[48px] w-[100px]"
              placeholder="Filter"
            >
              <Option value="All">All</Option>
              {[1, 2, 3, 4, 5].map((rating) => (
                <Option key={rating} value={rating}>
                  {rating} <RatingStar count={rating} />
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              value={selectedFilter}
              onChange={handleFilterChange}
              className="h-[48px] w-[100px]"
              placeholder="Filter"
            >
              <Option value="Resolved">Resolved</Option>
              <Option value="Pending">Pending</Option>
            </Select>
          )}
        </div>
      </div>
      <div className="w-fit flex p-[4px] bg-white rounded-[6px] mb-[24px]">
        <button
          className={`w-[150px] py-2 px-4 ${
            selectedButton === "allFeedback"
              ? "bg-green-theme text-white"
              : "bg-white"
          } rounded`}
          onClick={() => handleClick("allFeedback")}
        >
          All Feedback
        </button>
        <button
          className={`w-[150px] py-2 px-4 ${
            selectedButton === "policyQuery"
              ? "bg-green-theme text-white"
              : "bg-white"
          } rounded`}
          onClick={() => handleClick("policyQuery")}
        >
          Policy Query
        </button>
      </div>
      {selectedButton === "allFeedback" ? (
        <AllFeedback
          search={search}
          selectedFilter={selectedFilter === "All" ? null : selectedFilter}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      ) : (
        <PolicyQuerys
          search={search}
          selectedFilter={selectedFilter === "All" ? null : selectedFilter}
        />
      )}
    </div>
  );
};

export default FeedBack;
