import React, { useState } from "react";
import AuthForm from "../../components/AuthForm";
import { loginData } from "../../utils/FormFieldData";
import { useDispatch } from "react-redux";
import { loginStep } from "../../store/slices/loginSlice";
import { delay } from "../../utils/commonFunction";
import { login } from "../../service/Collections";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils/errorMessage";
import { useNavigate } from "react-router-dom";

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        // Email - RK@gmail.com
        // Password - Ranbir@123
        setLoading(true);
        let res = await login(values);
        if (res?.status === 200) {
            let permissionModule = res?.data?.user?.permission_module;
            if (Array.isArray(permissionModule) && permissionModule?.length === 0) {
                navigate("/access-denied");
                return;
            }
            await delay(1000);
            let actionPayload = {
                data: res?.data?.user,
                token: res?.data?.token,
            };
            dispatch(loginStep(actionPayload));
            toast.success(res?.message);
            setLoading(false);
        } else {
            const message = getErrorMessage(res, "Failed to connection");
            toast.error(message);
            setLoading(false);
        }
    };

    return (
        <div className="w-full h-full flex justify-center items-center">
            <AuthForm loading={loading} onFinish={handleSubmit} data={loginData} />
        </div>
    );
}
