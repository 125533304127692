import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Button,
  Spin,
} from "antd";
import CustomUpload from "../../../components/CustomUpload";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updatePersonalInfo } from "../../../store/slices/AddClientInfoSlice";
import dayjs from "dayjs";
import { natureOfBusiness, qualifications, stateCityData, states } from "../../../utils/stateCityData";
import { disableFutureDates, uploadDoc } from "../../../utils/commonFunction";
import { toast } from "react-toastify";

const { Option } = Select;

const ClientPersonalInfo = ({ onNext }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [useSameAddress, setUseSameAddress] = useState(true);
  const clientInfoData = useSelector((state) => state?.addClientInfoSlice?.personalInfo);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    firstName: clientInfoData?.firstName || "",
    middleName: clientInfoData?.middleName || "",
    lastName: clientInfoData?.lastName || "",
    dob: dayjs(clientInfoData?.dob) || null,
    age: clientInfoData?.age || "",
    gender: clientInfoData?.gender || null,
    email: clientInfoData?.email || "",
    phone: clientInfoData?.phone || "",
    altPhone: clientInfoData?.altPhone || "",
    motherName: clientInfoData?.motherName || "",
    fatherName: clientInfoData?.fatherName || "",
    annualIncome: clientInfoData?.annualIncome || "",
    qualification: clientInfoData?.qualification || null,
    natureOfBusiness: clientInfoData?.natureOfBusiness || null,
    weight: clientInfoData?.weight || "",
    height: clientInfoData?.height || "",
    permanentAddress: {
      streetAddress: clientInfoData?.permanentAddress?.streetAddress || "",
      aptUnitSuite: clientInfoData?.permanentAddress?.aptUnitSuite || "",
      landmark: clientInfoData?.permanentAddress?.landmark || "",
      state: clientInfoData?.permanentAddress?.state || null,
      city: clientInfoData?.permanentAddress?.city || null,
      zip: clientInfoData?.permanentAddress?.zip || "",
    },
    communicationAddress: {
      streetAddress: clientInfoData?.communicationAddress?.streetAddress || "",
      aptUnitSuite: clientInfoData?.communicationAddress?.aptUnitSuite || "",
      landmark: clientInfoData?.communicationAddress?.landmark || "",
      state: clientInfoData?.communicationAddress?.state || null,
      city: clientInfoData?.communicationAddress?.city || null,
      zip: clientInfoData?.communicationAddress?.zip || "",
    },
    medicalHistory: clientInfoData?.medicalHistory || [
      { illness: "", medicineName: "", document: "" },
    ],
    useSameAddress: true,
  };

  const onFinish = async (values) => {
    setLoading(true);
    const permanentAddress = {
      streetAddress: values?.streetAddress,
      aptUnitSuite: values?.aptUnitSuite,
      landmark: values?.landmark,
      state: values?.state,
      city: values?.city,
      zip: values?.zip,
    };

    const communicationAddress = values?.useSameAddress
      ? permanentAddress
      : {
        streetAddress: values?.communicationStreetAddress,
        aptUnitSuite: values?.communicationApartment,
        landmark: values?.communicationLandmark,
        state: values?.communicationState,
        city: values?.communicationCity,
        zip: values?.communicationZip,
      };

    const {
      streetAddress,
      aptUnitSuite,
      landmark,
      state,
      city,
      zip,
      useSameAddress,
      ...filteredValues
    } = values;

    let medicalHistory = await uploadDoc(values?.medicalHistory, 'client');

    if (medicalHistory?.error && medicalHistory?.message === 'Invalid type or empty payload') {
      setLoading(false);
      toast.error(medicalHistory?.message);
      return;
    }

    if (medicalHistory?.error && medicalHistory?.message === 'Failed to upload client documents') {
      setLoading(false);
      toast.error(medicalHistory?.message);
      return;
    }

    const formData = {
      ...filteredValues,
      permanentAddress,
      useSameAddress,
      communicationAddress,
      medicalHistory
    };
    dispatch(updatePersonalInfo(formData));
    setLoading(false);
    onNext();
  };

  const calculateAge = (dob) => {
    const today = dayjs();
    const birthDate = dayjs(dob);
    let age = today.year() - birthDate.year();
    if (today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())) {
      age--;
    }
    return age;
  };


  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6">Client Personal Info</h2>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
          <div>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: "Please enter first name" }]}
                >
                  <Input placeholder="Enter first name" className="h-[48px]" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Middle Name"
                  name="middleName"
                  rules={[
                    { required: false, message: "Please enter middle name" },
                  ]}
                >
                  <Input placeholder="Enter middle name" className="h-[48px]" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: "Please enter last name" }]}
                >
                  <Input placeholder="Enter last name" className="h-[48px]" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Date of Birth"
                  name="dob"
                  rules={[
                    { required: true, message: "Please enter date of birth" },
                  ]}
                >
                  <DatePicker
                    className="h-[48px] w-[100%]"
                    placeholder="DD/MM/YYYY"
                    format='DD/MM/YYYY'
                    onChange={(date) => {
                      const age = calculateAge(date);
                      form.setFieldsValue({ age });
                    }}
                    disabledDate={disableFutureDates}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Age"
                  name="age"
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  rules={[
                    { required: true, message: "Please enter age" },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter number only",
                    },
                  ]}
                >
                  <Input placeholder="Enter Age" className="h-[48px]" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Gender"
                  name="gender"
                  rules={[{ required: true, message: "Please select gender" }]}
                >
                  <Select placeholder="Select Gender" className="h-[48px]">
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Email Address"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email" className="h-[48px]" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Phone No."
                  name="phone"
                  rules={[
                    { required: true, message: "Please enter phone number" },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  ]}
                >
                  <Input placeholder="Enter Phone No." className="h-[48px]" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Alternative Phone No. "
                  name="altPhone"
                  rules={[
                    { required: false, message: "Please enter   phone number" },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit  phone number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Alternative Phone No."
                    className="h-[48px]"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Mother Name"
                  name="motherName"
                  rules={[
                    { required: true, message: "Please enter mother name" },
                  ]}
                >
                  <Input placeholder="Enter mother name" className="h-[48px]" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Father Name"
                  name="fatherName"
                  rules={[
                    { required: true, message: "Please enter father name" },
                  ]}
                >
                  <Input placeholder="Enter father name" className="h-[48px]" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Annual Income"
                  name="annualIncome"
                  rules={[
                    { required: true, message: "Please enter annual income" },
                    {
                      pattern: /^[0-9,]+$/,
                      message:
                        "Please enter a valid number (e.g., 100000 or 100,000)",
                    },
                  ]}
                >
                  <Input placeholder="Enter annual income" className="h-[48px]" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Qualification"
                  name="qualification"
                  rules={[
                    { required: true, message: "Please select qualification" },
                  ]}
                >
                  <Select placeholder="Select Qualification" className="h-[48px]">
                    {qualifications?.map(qualification => (
                      <Option key={qualification} value={qualification}>{qualification}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  label="Nature of business"
                  name="natureOfBusiness"
                  rules={[
                    {
                      required: true,
                      message: "Please select nature of business",
                    },
                  ]}
                >
                  <Select placeholder="Select Nature of Business" className="h-[48px]">
                    {natureOfBusiness?.map(business => (
                      <Option key={business} value={business}>{business}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {
              <>
                <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
                  Permanent Address
                </p>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      label="Street Address"
                      name="streetAddress"
                      initialValue={
                        initialValues?.permanentAddress?.streetAddress
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter street address",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter street address"
                        className="h-[48px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      label="Apt., Unit, Suite (Optional)"
                      name="aptUnitSuite"
                      initialValue={initialValues?.permanentAddress?.aptUnitSuite}
                      rules={[{ required: false }]}
                    >
                      <Input
                        placeholder="Enter Apt., Unit, Suite"
                        className="h-[48px]"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      label="Landmark"
                      name="landmark"
                      initialValue={initialValues?.permanentAddress?.landmark}
                      rules={[
                        {
                          required: true,
                          message: "Please enter landmark",
                        },
                      ]}
                    >
                      <Input placeholder="Enter landmark" className="h-[48px]" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="State"
                      name="state"
                      initialValue={initialValues?.permanentAddress?.state}
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      rules={[{ required: true, message: "Please select state" }]}
                    >
                      <Select placeholder="Select State" className="h-[48px]" showSearch allowClear>
                        {states.map(state => (
                          <Option key={state} value={state}>{state}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="City"
                      name="city"
                      initialValue={initialValues?.permanentAddress?.city}
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      rules={[{ required: true, message: "Please select city" }]}
                    >
                      <Select
                        placeholder="Select City"
                        className="h-[48px]"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {Object.values(stateCityData)
                          .flat()
                          .map((city) => (
                            <Option key={city} value={city}>
                              {city}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="ZIP / postcode"
                      name="zip"
                      initialValue={initialValues?.permanentAddress?.zip}
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      rules={[
                        { required: true, message: "Please enter zip code" },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Please enter a valid zip code",
                        },
                      ]}
                    >
                      <Input placeholder="Pin Code" className="h-[48px]" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }
            <Form.Item
              name="useSameAddress"
              valuePropName="checked"
              className="text-[12px] font-[400] text-left font-roboto text-black"
            >
              <Checkbox
                onChange={(e) => setUseSameAddress(e.target.checked)}
                defaultChecked={true}
              >
                Use same as Communication Address
              </Checkbox>
            </Form.Item>
            {!useSameAddress && (
              <>
                <p className="text-[14px] font-[500] my-[16px] text-left font-roboto text-black">
                  Communication Address
                </p>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Street Address"
                      name="communicationStreetAddress"
                      initialValue={
                        initialValues?.communicationAddress?.streetAddress
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter street address",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter street address"
                        className="h-[48px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Apt., Unit, Suite (Optional)"
                      name="communicationApartment"
                      initialValue={
                        initialValues?.communicationAddress?.aptUnitSuite
                      }
                      rules={[
                        { required: false, message: "Please enter aptUnitSuite" },
                      ]}
                    >
                      <Input
                        placeholder="Enter Apt., Unit, Suite"
                        className="h-[48px]"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      label="Landmark"
                      name="communicationLandmark"
                      initialValue={initialValues?.communicationAddress?.landmark}
                      rules={[
                        {
                          required: true,
                          message: "Please enter landmark",
                        },
                      ]}
                    >
                      <Input placeholder="Enter landmark" className="h-[48px]" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="State"
                      name="communicationState"
                      initialValue={initialValues?.communicationAddress?.state}
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      rules={[{ required: true, message: "Please select state" }]}
                    >
                      <Select placeholder="Select State" className="h-[48px]" showSearch allowClear>
                        {states.map(state => (
                          <Option key={state} value={state}>{state}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="City"
                      name="communicationCity"
                      initialValue={initialValues?.communicationAddress?.city}
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      rules={[{ required: true, message: "Please select city" }]}
                    >
                      <Select
                        placeholder="Select City"
                        className="h-[48px]"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {Object.values(stateCityData)
                          .flat()
                          .map((city) => (
                            <Option key={city} value={city}>
                              {city}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="ZIP / postcode"
                      name="communicationZip"
                      initialValue={initialValues?.communicationAddress?.zip}
                      className="text-[12px] font-[400] text-left font-roboto text-black"
                      rules={[
                        { required: true, message: "Please enter zip code" },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Please enter a valid zip code",
                        },
                      ]}
                    >
                      <Input placeholder="Pin Code" className="h-[48px]" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Weight"
                  name="weight"
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  rules={[
                    { required: true, message: "Please enter weight" },
                    {
                      pattern: /^[0-9,]+$/,
                      message: "Please enter a valid number ",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Weight"
                    className="h-[48px]"
                    suffix="Kg"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Height"
                  name="height"
                  className="text-[12px] font-[400] text-left font-roboto text-black"
                  rules={[
                    { required: true, message: "Please enter height" },
                    {
                      pattern: /^(10|[1-9])('|'[0-9]{1,2})?$/,
                      message: "Please enter a valid height eg. 5'10",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Height"
                    className="h-[48px]"
                    suffix="In"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
                Client Medical History
              </p>
              <Form.List name="medicalHistory" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="bg-gray-100 p-[16px] mb-[10px]">
                        {fields.length > 1 && (
                          <div
                            className="flex justify-end cursor-pointer"
                            onClick={() => remove(name)}
                          >
                            <DeleteOutlined style={{ color: "red" }} />
                          </div>
                        )}
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              label="Medical Illness"
                              name={[name, "illness"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter medical illness",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter medical illness"
                                className="h-12 rounded-md border-gray-300"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              label="Medicine Name"
                              name={[name, "medicineName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter medicine name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter medicine name"
                                className="h-12 rounded-md border-gray-300"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={24}>
                            <CustomUpload
                              fileListProp={form.getFieldValue(["medicalHistory", name, "document"])}
                              onFileChange={(file) => {
                                // Get current form values
                                const currentValues = form.getFieldsValue();
                                // Update only the `uploadDoc` field for the specific medicalHistory item
                                const updatedMedicalHistory = currentValues.medicalHistory.map((item, index) =>
                                  index === name ? { ...item, document: file } : item
                                );
                                // Set the updated values back to the form
                                form.setFieldsValue({
                                  medicalHistory: updatedMedicalHistory,
                                });
                              }}
                              label="Upload Doc"
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Form.Item className="flex justify-end">
                      <Button
                        style={{
                          width: "fit-content",
                          color: "#688540",
                          border: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Medical History
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div>
          <div className="flex justify-end mt-[20px]">
            <button className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
              {loading ? "Loading..." : "Next"}
            </button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
export default ClientPersonalInfo;
