import React, { useState, useEffect, useCallback } from "react";
import CustomTable from "../../components/CustomTable";
import {
  FilterDropdownIcon,
  TableDeleteIcon,
  TableViewIcon,
} from "../../utils/SVG";
import { PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import Search from "antd/es/transfer/search";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/CustomDeleteModal";
import {
  deleteClientById,
  listingManageClient,
} from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { toast } from "react-toastify";
import CustomAvatar from "../../components/CustomAvatar";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { updateClientStatus } from "../../service/Collections";

const { Option } = Select;

let defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const ManageClients = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.loginSlice?.data);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilterValue, setStatusFilterValue] = useState("");

  const getStatusClass = (status) => {
    return status === "active"
      ? "active-status-selector"
      : "inactive-status-selector";
  };

  const handleDeleteClick = (record) => {
    setItemToDelete(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    const res = await deleteClientById(itemToDelete?._id);
    if (res?.status === 200) {
      toast.info(res.message);
      setDeleteModalVisible(false);
      fetchListing({
        ...pagination,
        search: searchValue,
        status: statusFilterValue,
      });
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
  };

  const handleViewClick = async (clientId) => {
    navigate("/manage-clients/edit", { state: { id: clientId } });
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      key: "key",
      render: (text) => <span>{(pagination?.current - 1) * pagination?.pageSize + text}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div className="flex items-center gap-1">
          <CustomAvatar
            firstName={record?.firstName}
            lastName={record?.lastName}
            profile={record?.profile}
          />
          {record?.firstName + " " + record?.lastName}
        </div>
      ),
    },
    {
      title: "Client ID",
      dataIndex: "clientId",
      align: "center",
      key: "_id",
      render: (text) => (
        <span className="uppercase">{text || "N/A"}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Age",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      align: "center",
      render: (dateOfBirth) => (
        <span>{dayjs(dateOfBirth).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "City",
      dataIndex: "permanentAddress",
      key: "permanentAddress",
      render: (permanentAddress) => <span>{permanentAddress?.city}</span>,
    },
    {
      title: "No. of Policy",
      dataIndex: "policiesCount",
      key: "policiesCount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Select
          value={status}
          onChange={(value) => handleStatusChange(record.key, value)}
          style={{ width: 90 }}
          className={getStatusClass(status)}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <span>{dayjs(createdAt).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex space-x-2">
          <div
            className="cursor-pointer"
            onClick={() => handleViewClick(record?._id)}
          >
            <TableViewIcon />
          </div>
          {user?.permission_client?.includes("Delete Profile") && (
            <div
              className="cursor-pointer"
              onClick={() => handleDeleteClick(record)}
            >
              <TableDeleteIcon />
            </div>
          )}
        </div>
      ),
    },
  ];

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setPagination(defaultPagination);
    }, 1000),
    [pagination]
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleStatusChange = async (key, value) => {
    setTableData((prevData) =>
      prevData.map((client) =>
        client?.key === key ? { ...client, status: value } : client
      )
    );
    const clientId = tableData?.find((client) => client?.key === key)?._id;
    if (clientId) {
      const success = await updateClientStatus(clientId, value);
      if (success) {
        toast.info(success?.message);
      }
      if (!success) {
        setTableData((prevData) =>
          prevData.map((client) =>
            client?.key === key ? { ...client, status: "inactive" } : client
          )
        );
      }
    }
  };

  const handleStatusFiterChange = (value) => {
    setPagination(defaultPagination);
    if (value === "All") {
      setStatusFilterValue("");
    } else {
      setStatusFilterValue(value);
    }
  };

  const fetchListing = async () => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let params = new URLSearchParams();
    params.append("limit", pageSize);
    params.append("page", current);
    if (searchValue) params.append("search", searchValue);
    if (statusFilterValue) params.append("status", statusFilterValue);
    let res = await listingManageClient(params);
    if (res?.status === 200) {
      let addingSno = res?.data?.listing?.map((u, idx) => ({
        key: idx + 1,
        ...u,
      }));
      setTableData(addingSno);
      setTotalPage(res?.data?.pagination?.totalRecords);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  useEffect(() => {
    fetchListing();
  }, [searchValue, statusFilterValue, pagination]);

  return (
    <div>
      <CustomTable
        columns={columns}
        data={tableData}
        heading="Manage Clients"
        paginationProps={{ ...pagination, total: totalPage }}
        onChange={handleTableChange}
        loading={loading}
      >
        <Search
          placeholder="Search..."
          allowClear
          onChange={handleSearch}
          size="large"
          prefixCls="custom-search"
        />
        {/* <Select defaultValue="Sort By" className="h-[48px] w-[100px]">
          <Option value="name">Name</Option>
          <Option value="role">Role</Option>
        </Select> */}
        <Select
          defaultValue={"All"}
          placeholder="Filter"
          className="h-[48px] w-[100px]"
          value={statusFilterValue || undefined}
          onChange={handleStatusFiterChange}
          suffixIcon={<FilterDropdownIcon />}
        >
          <Option value="All">All</Option>
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
        <button
          className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]"
          onClick={() => navigate("/manage-clients/add-client")}
        >
          <span>
            <PlusOutlined /> Add Client
          </span>
        </button>
      </CustomTable>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default ManageClients;
