import { Button, Form, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import React, { useEffect } from "react";
import { editUserQuery } from "../../service/Collections";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils/errorMessage";
import { useSelector } from "react-redux";

const EditPolicyQuery = ({
  feedbackData,
  onClose,
  fetchListing,
  pagination,
}) => {
  const [form] = Form.useForm();
  const loginUserDetail = useSelector((state) => state?.loginSlice?.data);
  
  const onFinish = async (values) => {
    const payload = {
      ...values,
      employeeId: loginUserDetail?._id,
    };
    const res = await editUserQuery(feedbackData?._id, payload);
    if (res?.status === 200) {
      toast.info(res.message);
      fetchListing(pagination);
      onClose();
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      status: feedbackData?.status,
      note: feedbackData?.note,
    });
  }, [feedbackData, form]);

  return (
    <div>
      <p className="font-roboto text-2xl font-bold leading-7 text-center">
        Status
      </p>

      <Form form={form} layout="vertical" className="mt-6" onFinish={onFinish}>
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
              message: "Please select status",
            },
          ]}
        >
          <Select className="h-[48px]" placeholder="Select Status">
            <Option value="Resolved">Resolved</Option>
            <Option value="Pending">Pending</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="note"
          label="Note"
          rules={[
            {
              required: true,
              message: "Please enter note",
            },
          ]}
        >
          <TextArea
            autoSize={{ minRows: 5, maxRows: 6 }}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter note"
          />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            className="bg-green-theme text-white h-[48px] w-full rounded-md"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditPolicyQuery;
