import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { TableDeleteIcon, TableEditIcon } from "../../utils/SVG";
import Search from "antd/es/transfer/search";
import DeleteModal from "../../components/CustomDeleteModal";
import CustomModal from "../../components/CustomModal";
import EditUser from "../roleAndManagement/EditUser";
import { deleteRole, listingUserRole } from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { toast } from "react-toastify";
import CustomAvatar from "../../components/CustomAvatar";
import { debounce } from "lodash";
import dayjs from "dayjs";

let defaultPagination = {
  current: 1,
  pageSize: 4,
  total: 0,
};
const EmployeeInfo = () => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchValue, setSearchValue] = useState("");
  const [roleValue, setRoleValue] = useState("");

  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      key: "key",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Technician",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div className="flex items-center gap-1">
          <CustomAvatar
            firstName={record?.firstName}
            lastName={record?.lastName}
          />
          {record?.firstName + " " + record?.lastName}
        </div>
      ),
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD") : "-";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex space-x-2">
          <div
            className="cursor-pointer"
            onClick={() => handleEditClick(record)}
          >
            <TableEditIcon />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => handleDeleteClick(record)}
          >
            <TableDeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  const handleDeleteClick = (record) => {
    setItemToDelete(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    console.log("Deleting item:", itemToDelete);
    setDeleteModalVisible(false);
    const res = await deleteRole(itemToDelete?._id);
    if (res?.status === 200) {
      fetchListing({ ...pagination, search: searchValue, role: roleValue });
      toast.info(res?.message);
      setDeleteModalVisible(false);
    } else {
      toast.error(res?.message);
      console.log(res?.message);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setPagination(defaultPagination);
    }, 1000),
    [roleValue]
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleEditClick = (record) => {
    setEditingUser(record);
    setIsEditModalVisible(true);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const fetchListing = async () => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let params = new URLSearchParams();
    params.append("perPage", pageSize);
    params.append("page", current);

    if (searchValue) params.append("search", searchValue);
    let res = await listingUserRole(params);
    if (res?.status === 200) {
      let addingSno = res?.data?.admins?.map((u, idx) => ({
        key: idx + 1,
        ...u,
      }));
      setTableData(addingSno);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListing();
  }, [pagination, searchValue, roleValue]);

  return (
    <div className="w-full">
      <CustomTable
        columns={columns}
        data={tableData}
        heading="Employee Info"
        paginationProps={false}
        onChange={handleTableChange}
        loading={loading}
      >
        <Search
          placeholder="Search..."
          fetchListing={fetchListing}
          allowClear
          onChange={handleSearch}
          size="large"
          prefixCls="custom-search"
        />
      </CustomTable>

      <DeleteModal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        onDelete={handleDeleteConfirm}
      />

      <CustomModal
        width={883}
        height={500}
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      >
        <EditUser
          user={editingUser}
          fetchListing={fetchListing}
          setSearchValue={setSearchValue}
          setRoleValue={setRoleValue}
          onClose={() => setIsEditModalVisible(false)}
        />
      </CustomModal>
    </div>
  );
};

export default EmployeeInfo;
