import React, { useState } from "react";
import { Form, Input, Row, Col } from "antd";
import CustomUpload from "../../../../../components/CustomUpload";
import { DeleteOutlined } from "@ant-design/icons";
import { editClientMedicalHistory } from "../../../../../service/Collections";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { uploadDoc } from "../../../../../utils/commonFunction";

const EditClientMedicalHistory = ({ onClose, onChange, fetching }) => {
  const [form] = Form.useForm();
  const clientData = useSelector((state) => state?.selectedClient?.client);
  const [medicalHistory, setMedicalHistory] = useState(
    (clientData?.[0]?.medicalHistory || []).map(
      (item, index) => ({
        id: item?._id || null,
        illness: item?.illness || "",
        medicineName: item?.medicineName || "",
        document: item?.document
          ? [{ url: item.document, name: `Document ${index + 1}` }]
          : [],
      })
    )
  );
  const [loading, setLoading] = useState(false);

  // const addMedicalHistory = () => {
  //   setMedicalHistory([
  //     ...medicalHistory,
  //     { id: null, illness: "", medicineName: "", document: [] },
  //   ]);
  // };

  const onDelete = (index) => {
    const updatedHistory = medicalHistory.filter((_, i) => i !== index);
    setMedicalHistory(updatedHistory);
  };

  const onFinish = async () => {
    let updatedMedicalHistory = await uploadDoc(medicalHistory, 'client');
    if (updatedMedicalHistory?.error && updatedMedicalHistory?.message === 'Invalid type or empty payload') {
      setLoading(false);
      toast.error(updatedMedicalHistory?.message);
      return;
    }

    if (updatedMedicalHistory?.error && updatedMedicalHistory?.message === 'Failed to upload client documents') {
      setLoading(false);
      toast.error(updatedMedicalHistory?.message);
      return;
    }
    const formattedData = updatedMedicalHistory.map(
      ({ illness, medicineName, document }) => ({
        illness,
        medicineName,
        document,
      })
    );

    const responses = await Promise.all(
      updatedMedicalHistory.map((item, index) =>
        item?.id
          ? editClientMedicalHistory(item?.id, formattedData[index])
          : null
      )
    );
    console.log(responses, "responses")
    const allSuccessful = responses.every((res) => res?.status === 200);
    if (allSuccessful) {
      const successMessage =
        responses[0]?.message || "Medical history updated successfully!";
      toast.info(successMessage);
      onClose();
      fetching();
    } else {
      const errorMessages = responses
        .filter((res) => res?.status !== 200)
        .map((res) => res?.message)
        .join(", ");
      toast.error(`Failed to update some medical histories: ${errorMessages}`);
    }

    onChange(medicalHistory);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Edit Client Medical History
      </h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div>
          <p className="text-[14px] font-[500] mb-[16px] text-left font-roboto text-black">
            Client medical history
          </p>
          {medicalHistory.map((history, index) => (
            <div key={index} className="bg-gray-100 p-[16px] mb-[10px]">
              {medicalHistory.length > 1 && (
                <div
                  className="flex justify-end cursor-pointer"
                  onClick={() => onDelete(index)}
                >
                  <DeleteOutlined style={{ color: "red" }} />
                </div>
              )}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Medical illness"
                    rules={[
                      {
                        required: true,
                        message: "Please enter medical illness",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter medical illness"
                      className="h-[48px]"
                      value={history.illness}
                      onChange={(e) =>
                        setMedicalHistory((prev) =>
                          prev.map((item, i) =>
                            i === index
                              ? { ...item, illness: e.target.value }
                              : item
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Medicine Name"
                    rules={[
                      { required: true, message: "Please enter medicine name" },
                    ]}
                  >
                    <Input
                      className="h-[48px]"
                      placeholder="Enter medicine name"
                      value={history.medicineName}
                      onChange={(e) =>
                        setMedicalHistory((prev) =>
                          prev.map((item, i) =>
                            i === index
                              ? { ...item, medicineName: e.target.value }
                              : item
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <CustomUpload
                    fileListProp={history.document}
                    onFileChange={(files) => {
                      setMedicalHistory((prev) =>
                        prev.map((item, i) =>
                          i === index ? { ...item, document: files } : item
                        )
                      );
                    }}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </div>
        {/* <div className="flex justify-end mt-4">
          <p
            className="text-green-theme cursor-pointer text-[14px] font-[500]"
            onClick={addMedicalHistory}
          >
            + Add Medical History
          </p>
        </div> */}
        <div className="flex justify-end mt-[20px]">
          <button className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
            Update
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditClientMedicalHistory;
