import React, { useState } from "react";
import ClientPersonalInfo from "./ClientPersonalInfo";
import { CaretLeftOutlined } from "@ant-design/icons";
import { Steps } from "antd";
import { useNavigate } from "react-router-dom";
import OtherDetail from "./OtherDetails";
import NomineeDetails from "./NomineeDetail";

const { Step } = Steps;
const AddClient = () => {
  const [currentStep, setCurrentStep] = useState(0);
  // const [formData, setFormData] = useState({
  //   personalInfo: {},
  //   nomineeDetails: [],
  //   otherDetails: {},
  // });
  
  const [formData, setFormData] = useState({
    personalInfo: {},
    nomineeDetails: [],
    otherDetails: {},
  });
  const navigate = useNavigate();
  const next = () => setCurrentStep(currentStep + 1);
  const prev = () => setCurrentStep(currentStep - 1);

  // const handleFormChange = (data, step) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     [step]: data,
  //   }));
  // };

  return (
    <div className="px-[50px]">
      <div className="mb-[20px]">
        <CaretLeftOutlined
          className="mr-[10px] cursor-pointer"
          onClick={() => navigate("/manage-clients")}
        />
        <span className="text-[14px] font-[500] text-left font-roboto text-black">
          Manage Clients / Add Client
        </span>
      </div>

      <Steps
        current={currentStep}
        className="custom-steps"
        style={{ marginBottom: "30px" }}
      >
        <Step title="Client Personal Info" />
        <Step title="Nominee Details" />
        <Step title="Other Details" />
      </Steps>
      <div>
        {currentStep === 0 && (
          <ClientPersonalInfo
            onNext={next}
          // onChange={(data) => handleFormChange(data, "personalInfo")}
          />
        )}
        {currentStep === 1 && (
          <NomineeDetails
            onNext={next}
            onPrev={prev}
          // onChange={(data) => handleFormChange(data, "nomineeDetails")}
          // initialValues={formData.nomineeDetails}
          />
        )}
        {currentStep === 2 && (
          <OtherDetail
            onPrev={prev}
          // onChange={(data) => handleFormChange(data, "otherDetails")}
          />
        )}
      </div>
    </div>
  );
};

export default AddClient;
