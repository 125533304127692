import React from "react";
import { Form, Input, Select, DatePicker, Row, Col, Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { disableFutureDates } from "../../../../utils/commonFunction";
import { relationshipOptions } from "../../../../utils/stateCityData";
import { addFamilyMember } from "../../../../service/Collections";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddFamilyDetail = ({ onClose, fetching }) => {
  const [form] = Form.useForm();
  const clientData = useSelector((state) => state?.selectedClient?.client?.[0]);

  const onFinish = async (values) => {
    try {
      const familyMember = values.familyMembers[0];
      const transformedFamilyMember = {
        ...familyMember,
        medicalHistory: [
          {
            illness: familyMember.illness,
            medicineName: familyMember.medicineName,
          },
        ],
        illness: familyMember.illness,
        medicineName: familyMember.medicineName,
      };
      const payload = {
        clientId: clientData?._id,
        ...transformedFamilyMember,
      };
      const res = await addFamilyMember(payload);
      if (res?.status === 200) {
        toast.success(res?.message || "Family details added successfully.");
        form.resetFields();
        onClose();
        fetching();
      } else {
        toast.error(res?.message || "Failed to add family details.");
      }
    } catch (error) {
      console.error("Error in onFinish:", error);
      toast.error("An unexpected error occurred.");
    }
  };
  
  
  

  return (
    <div className="bg-whiterounded-[20px]">
      <h2 className="text-2xl font-semibold mb-[16px] text-center">
        Add Family Details
      </h2>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={{ familyMembers: [{}] }}
      >
        <Form.List name="familyMembers" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              <div className="mb-[24px]">
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} className="bg-gray-100 p-[16px] mb-[10px]">
                    {fields.length > 1 && (
                      <div
                        className="flex justify-end cursor-pointer"
                        onClick={() => remove(name)}
                      >
                        <DeleteOutlined style={{ color: "red" }} />
                      </div>
                    )}
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          label="Name"
                          rules={[
                            { required: true, message: "Please enter name" },
                          ]}
                        >
                          <Input
                            placeholder="Enter Name"
                            className="h-12 rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "dateOfBirth"]}
                          label="Date of Birth"
                          rules={[
                            { required: true, message: "Please enter DOB" },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="h-12 w-full rounded-md border-gray-300"
                            disabledDate={disableFutureDates}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "relation"]}
                          label="Relation"
                          rules={[
                            {
                              required: true,
                              message: "Please select relation",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Relation"
                            className="h-[48px]"
                          >
                            {relationshipOptions?.map((relation) => (
                              <Option key={relation} value={relation}>
                                {relation}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "height"]}
                          label="Height"
                          rules={[
                            { required: true, message: "Please enter height" },
                            {
                              pattern: /^(10|[1-9])('|'[0-9]{1,2})?$/,
                              message: "Please enter a valid height eg. 5'10",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Height"
                            suffix="In"
                            className="h-[48px]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "weight"]}
                          label="Weight"
                          rules={[
                            { required: true, message: "Please enter weight" },
                            {
                              pattern: /^[0-9,]+$/,
                              message: "Please enter a valid number ",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Weight"
                            suffix="Kg"
                            className="h-[48px]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "healthStatus"]}
                          label="Health Status"
                          rules={[
                            {
                              required: true,
                              message: "Please select health status",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Health Status"
                            className="h-[48px]"
                          >
                            <Option value="healthy">Healthy</Option>
                            <Option value="minor_issues">Minor Issues</Option>
                            <Option value="major_issues">Major Issues</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, "medicineName"]}
                          label="Medicine Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter medicine name",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Medicine Name"
                            className="h-[48px]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, "illness"]}
                          label="Medical Illness"
                          rules={[
                            {
                              required: true,
                              message: "Please select medical illness",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter medical illness"
                            className="h-12 rounded-md border-gray-300"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          label="Description"
                          rules={[
                            {
                              required: true,
                              message: "Please enter description",
                            },
                          ]}
                        >
                          <TextArea
                            maxLength={100}
                            placeholder="Enter Description"
                            style={{
                              height: 120,
                              resize: "none",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
              {/* <Form.Item className="flex justify-end">
                <Button
                  style={{
                    width: "fit-content",
                    color: "#688540",
                    border: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Family Member
                </Button>
              </Form.Item> */}
            </>
          )}
        </Form.List>
        <div className="flex justify-end mt-[20px]">
          <button className="bg-green-theme text-white px-4 py-2 rounded-md hover:bg-green-theme-dark w-[129px] h-[48px]">
            Add Member
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddFamilyDetail;
